import { Route, Routes, Link } from "react-router-dom";
import React, { useEffect, useRef } from "react";

import Compair from "../icons/Compair";
import QuickViewIco from "../icons/QuickViewIco";
import ThinLove from "../icons/ThinLove";
import PreOrder from "../../../images/preorder.svg";

function decodeHTMLEntities(text) {
  var txt = document.createElement("textarea");
  txt.innerHTML = text;
  return txt.value;
}

export default function ProductCardStyleOne({ datas, styleNumbers }) {
  const imgRef = useRef();
  const containerRef = useRef();

  const decodedDescription = decodeHTMLEntities(datas.shortDescription);

  // Fallback image in case the main image fails to load
  const handleError = () => {
    containerRef.current.style.background =
      "url(https://maevn-images.s3.us-east-2.amazonaws.com/220x220/NoImage.jpg) no-repeat center";
  };

  //Hide swatch images that failed to load
  const handleSwatchError = (event) => {
    event.target.style.display = "none";
  };

  const collectionSlug = datas.productLine.replace(/ /g, "-");

  // Check if styleNumber exists in styleNumbers array
  const isMomentumMens = styleNumbers?.includes(datas?.styleNumber);

  useEffect(() => {
    console.log("colors", datas.colors);
  }, []);

  return (
    <Link
      to={{
        pathname: isMomentumMens
          ? `/MomentumMens/${datas.styleNumber}/${datas.defaultColorCode}/${datas.fits[0]}`
          : `/${collectionSlug}/${datas.styleNumber}/${datas.defaultColorCode}/${datas.fits[0]}`,
      }}
    >
      <div
        className="product-card-one w-full h-full bg-white relative group overflow-hidden"
        style={{
          boxShadow: "0px 15px 64px 0px rgba(0, 0, 0, 0.05)",
        }}
      >
        {/*product list image*/}
        {/*
      <div
        className="product-card-img w-full h-[300px]"
        style={{
          //background: `url(https://maevn-images.s3.us-east-2.amazonaws.com/UniformSolution/220x220/1016hpk.jpg) no-repeat center`,
          background: `url(${datas.imageLinks.list[0].imageURL}) no-repeat center`,
        }}
      ></div>
      */}

        <>
          <img
            ref={imgRef}
            src={datas.listImageUrl}
            onError={handleError}
            alt=""
            style={{ display: "none" }}
          />
          <div
            ref={containerRef}
            className="product-card-img w-full h-[300px] mb-[-25px]"
            style={{
              background: `${
                datas.listImageUrl.length > 0 && datas?.listImageUrl
                  ? `url(${datas.listImageUrl}) no-repeat center`
                  : "url(https://maevn-images.s3.us-east-2.amazonaws.com/220x220/NoImage.jpg) no-repeat center"
              }`,
            }}
          ></div>

          {/* <div class="title mb-2 ml-8 mr-8 text-[15px] font-600 text-qblack leading-[24px] line-clamp-2 hover:text-blue-600">  */}
          <div className="swatches flex flex-wrap ml-8 mr-8">
            {datas.colors.map((color) => {
              const colorCode = color.toLowerCase();
              const imageUrl = `https://maevn-images.s3.us-east-2.amazonaws.com/swatches/${colorCode}.jpg`;

              return (
                <img
                  key={color}
                  src={imageUrl}
                  alt={color}
                  className="swatch-image  mr-1 mb-2"
                  onError={handleSwatchError}
                  style={{ width: "20px", height: "20px" }}
                />
              );
            })}
          </div>
        </>
        {/*
      <div
        className="product-card-img w-full h-[300px]"
        style={{
          background: `${
            datas.listImageUrl.length > 0 && datas?.listImageUrl
              ? `url(${datas.listImageUrl}) no-repeat center`
              : "url(https://b2bwebimagetest.s3.us-east-2.amazonaws.com/NoImageList.png) no-repeat center"
          }`,
        }}
      ></div>
      */}
        {datas.isPreorder === 1 &&
          datas.isNew === 1 &&
          (datas.discountRate || datas.discountRate > 0) && (
            <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
              <img
                src={PreOrder}
                className="w-full h-full object-cover "
                alt="PreOrder"
              />
              <img
                src={PreOrder}
                className="w-full h-full object-cover "
                alt="PreOrder"
              />
              <img
                src={PreOrder}
                className="w-full h-full object-cover "
                alt="PreOrder"
              />
            </div>
          )}
        {datas.isPreorder === 1 &&
          datas.isNew === 1 &&
          (!datas.discountRate || datas.discountRate === 0) && (
            <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
              <img
                src={PreOrder}
                className="w-full h-full object-cover "
                alt="PreOrder"
              />
              <img
                src={PreOrder}
                className="w-full h-full object-cover "
                alt="PreOrder"
              />
              <div className="w-[50px] h-[50px] rounded-full bg-qyellow text-qblack flex justify-center items-center text-l font-medium absolute left-[200px] top-[10px]">
                <span>-50%</span>
              </div>
            </div>
          )}
        {datas.isPreorder === 1 &&
          datas.isNew === 0 &&
          (!datas.discountRate || datas.discountRate === 0) && (
            <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
              <img
                src={PreOrder}
                className="w-full h-full object-cover "
                alt="PreOrder"
              />
            </div>
          )}
        {datas.isPreorder === 1 &&
          datas.isNew === 0 &&
          (datas.discountRate || datas.discountRate > 0) && (
            <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
              <img
                src={PreOrder}
                className="w-full h-full object-cover "
                alt="PreOrder"
              />
            </div>
          )}
        {datas.isPreorder === 0 &&
          datas.isNew === 1 &&
          (!datas.discountRate || datas.discountRate === 0) && (
            <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
              <img
                src={PreOrder}
                className="w-full h-full object-cover "
                alt="PreOrder"
              />
            </div>
          )}

        {datas.isPreorder === 0 &&
          datas.isNew === 1 &&
          (datas.discountRate || datas.discountRate > 0) && (
            <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
              <img
                src={PreOrder}
                className="w-full h-full object-cover "
                alt="PreOrder"
              />
              <img
                src={PreOrder}
                className="w-full h-full object-cover "
                alt="PreOrder"
              />
            </div>
          )}
        {datas.isPreorder === 0 &&
          datas.isNew === 0 &&
          (datas.discountRate || datas.discountRate > 0) && (
            <div className="w-[100px] h-[40px] absolute left-[10px] top-[20px]">
              <img
                src={PreOrder}
                className="w-full h-full object-cover "
                alt="PreOrder"
              />
            </div>
          )}

        <div className="product-card-details px-[30px] pb-[30px] relative">
          {/* add to cart button */}
          {/*<div className="absolute w-full h-10 px-[30px] left-0 top-40 group-hover:top-[70px] transition-all duration-300 ease-in-out">
          <button type="button" className="yellow-btn">
            <div className="flex items-center space-x-3">
              <span>
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current"
                >
                  <path d="M12.5664 4.14176C12.4665 3.87701 12.2378 3.85413 11.1135 3.85413H10.1792V3.43576C10.1792 2.78532 10.089 2.33099 9.86993 1.86359C9.47367 1.01704 8.81003 0.425438 7.94986 0.150881C7.53106 0.0201398 6.90607 -0.0354253 6.52592 0.0234083C5.47246 0.193372 4.57364 0.876496 4.11617 1.85052C3.89389 2.32772 3.80368 2.78532 3.80368 3.43576V3.8574H2.8662C1.74187 3.8574 1.51313 3.88028 1.41326 4.15483C1.36172 4.32807 0.878481 8.05093 0.6723 9.65578C0.491891 11.0547 0.324369 12.3752 0.201948 13.3688C-0.0106763 15.0815 -0.00423318 15.1077 0.00220999 15.1371V15.1404C0.0312043 15.2515 0.317925 15.5424 0.404908 15.6274L0.781834 16H13.1785L13.4588 15.7483C13.5844 15.6339 14 15.245 14 15.0521C14 14.9214 12.5922 4.21694 12.5664 4.14176ZM12.982 14.8037C12.9788 14.8266 12.953 14.8952 12.9079 14.9443L12.8435 15.0162H1.13943L0.971907 14.8331L1.63233 9.82901C1.86429 8.04766 2.07047 6.4951 2.19289 5.56684C2.24766 5.16154 2.27343 4.95563 2.28631 4.8543C2.72123 4.85103 4.62196 4.84776 6.98661 4.84776H11.6901L11.6966 4.88372C11.7481 5.1452 12.9594 14.5128 12.982 14.8037ZM4.77338 3.8574V3.48479C4.77338 3.23311 4.80559 2.88664 4.84103 2.72649C5.03111 1.90935 5.67864 1.24584 6.48726 1.03339C6.82553 0.948403 7.37964 0.97782 7.71791 1.10202H7.72113C8.0755 1.22296 8.36545 1.41907 8.63284 1.71978C9.06453 2.19698 9.2095 2.62516 9.2095 3.41615V3.8574H4.77338Z" />
                </svg>
              </span>
              <span>Add To Cart</span>
            </div>
          </button>
        </div>*/}

          {/*<a href="/single-product"> */}
          {/*<Link
            to={{
              pathname: `/${collectionSlug}/${datas.styleNumber}/${datas.defaultColorCode}/${datas.fits[0]}`,
              //state: { prop: datas },
            }}
          >*/}
          <p className="title mb-2 text-[15px] font-600 text-qblack leading-[24px] line-clamp-2 hover:text-blue-600">
            {/* {datas.shortDescription} */}
            {decodedDescription}
          </p>
          <p className="text-sm text-qgray">Style no.{datas.styleNumber}</p>
          {/* </Link>*/}
          <p className="price">
            {/* <span className="main-price text-qgray line-through font-600 text-[18px]">
             {datas.priceMin}
             </span>  
          <span className="offer-price text-qred font-600 text-[18px] ml-2">
            {datas.priceMax}
          </span>
        */}

            {/* Discount check */}
            {datas.discountRate > 0 ? (
              <>
                <span className="main-price text-qgray font-600 text-[15px] line-through">
                  ${datas.priceMin} - ${datas.priceMax}
                </span>
                <span className="main-price font-600 text-[18px] text-red-500 ml-2">
                  ${datas.discountedPriceMin} - ${datas.discountedPriceMax}
                </span>
              </>
            ) : (
              <span className="main-price font-600 text-[18px] text-red-500">
                ${datas.priceMin} - ${datas.priceMax}
              </span>
            )}
          </p>
        </div>
        {/* quick-access-btns */}
        {/* <div className="quick-access-btns flex flex-col space-y-2 absolute group-hover:right-4 -right-10 top-20  transition-all duration-300 ease-in-out">
        <a href="#">
          <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
            <QuickViewIco />
          </span>
        </a>
        <a href="#">
          <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
            <ThinLove />
          </span>
        </a>
        <a href="#">
          <span className="w-10 h-10 flex justify-center items-center bg-primarygray rounded">
            <Compair />
          </span>
        </a>
      </div> */}
      </div>
    </Link>
  );
}

/*
id: number;
erpProgramId: number;
productLine: string;
styleNumber: number;
colors: string;
fits: string;
size: string;
shortDescription: string;
productUrl: string;
priceMin: number;
priceMax: number;
b2bActiveFlag: string;
    
      "id": "62aefe9ad8b80d5234af625a",
      "image": "product-img-1.jpg",
      "brand": "xioami",
      "review": 3,
      "title": "Xoggle aute et pariatur adipisicing nostrud et excepteur",
      "offer_price": "$18.73",
      "price": "$27.27",
      "campaingn_product": false,
      "cam_product_available": null,
      "cam_product_sale": null,
      "product_type": null
*/
