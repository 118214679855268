import Axios from "axios";

import {
  GET_ROLE_REQUEST,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAIL,
  POST_ROLE_REQUEST,
  POST_ROLE_SUCCESS,
  POST_ROLE_FAIL,
  EDIT_ROLE_REQUEST,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAIL,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_FAIL,
} from "../constants/roleContants";

const apiUrl = process.env.REACT_APP_API_URL;

// eslint-disable-next-line import/prefer-default-export
export const getRoles = () => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }
  console.log("token", token.result.token);

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_ROLE_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(`${apiUrl}/userAuth/customerRoles/${tokenUserId}`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_ROLE_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_ROLE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_ROLE_SUCCESS, payload: data.data }); //payload: data.data.result
  //console.log("payload: ", data.data);
};

export const addRole = (newRole) => async (dispatch) => {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to the login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  console.log("newRole :", newRole);
  dispatch({ type: POST_ROLE_REQUEST, payload: tokenUserId });

  try {
    const response = await Axios.post(
      `${apiUrl}/userAuth/addRole/${tokenUserId}`,
      newRole,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );

    dispatch({ type: POST_ROLE_SUCCESS, payload: response.data });

    return response; // Return the response data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: POST_ROLE_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: POST_ROLE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }

    console.log("error : ", error);
    throw error; // Rethrow the error
  }
};

export const editRole = (roleToEdit) => async (dispatch) => {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to the login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  console.log("roleToEdit :", roleToEdit);

  dispatch({ type: EDIT_ROLE_REQUEST, payload: tokenUserId });

  try {
    const response = await Axios.put(
      `${apiUrl}/userAuth/editRole/${tokenUserId}`,
      roleToEdit,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );

    dispatch({ type: EDIT_ROLE_SUCCESS, payload: response.data });

    return response; // Return the response data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: EDIT_ROLE_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: EDIT_ROLE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }

    console.log("error : ", error);
    throw error; // Rethrow the error
  }
};

export const verifyRoleName = (roleName) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }
  console.log("token", token.result.token);

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_ROLE_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(
      `${apiUrl}/userAuth/verifyRoleName/${tokenUserId}/${roleName}`,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );
  } catch (error) {
    let errorMsg; // for error response
    if (error.response && error.response.status === 401) {
      errorMsg = error.response.data.error;
    } else if (error.response && error.response.status === 400) {
      errorMsg = error.response.data.error;
    } else {
      errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
    }
    dispatch({
      type: GET_ROLE_FAIL,
      payload: errorMsg,
    });
    throw new Error(errorMsg);
  }
};

export const getPermissions = (roleId) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }
  console.log("token", token.result.token);

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_PERMISSIONS_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(
      `${apiUrl}/userAuth/permissions/${tokenUserId}/${roleId}/`,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_PERMISSIONS_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_PERMISSIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_PERMISSIONS_SUCCESS, payload: data.data }); //payload: data.data.result
  console.log("payload: ", data.data);
};
