export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export const GET_ORDERDETAILS_REQUEST = "GET_ORDERDETAILS_REQUEST";
export const GET_ORDERDETAILS_SUCCESS = "GET_ORDERDETAILS_SUCCESS";
export const GET_ORDERDETAILS_FAIL = "GET_ORDERDETAILS_FAIL";

export const GET_CANCELREQUEST_REQUEST = "GET_CANCELREQUEST_REQUEST";
export const GET_CANCELREQUEST_SUCCESS = "GET_CANCELREQUEST_SUCCESS";
export const GET_CANCELREQUEST_FAIL = "GET_CANCELREQUEST_FAIL";
