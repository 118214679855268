import React from "react";
import InputCom from "../../../Helpers/InputCom";

export default function BillInformation({
  paymentInfoTmp,
  setPaymentInfoTmp,
  warnings,
}) {
  const handleInputChange = (field, value) => {
    setPaymentInfoTmp({
      ...paymentInfoTmp,
      [field]: value,
    });
  };

  return (
    <>
      <div
        className="flex space-x-3 items-center "
        style={{
          marginBottom: "20px",
          borderBottom: "1px solid lightgray",
        }}
      >
        <span
          className="text-2xl font-bold payment-subtitle"
          style={{ color: "#000000" }}
        >
          Bill Information
        </span>
      </div>
      <div
        className="input-item mb-4"
        style={{
          marginBottom: "20px",
          border: "1px solid lightgray",
          padding: "20px 20px 15px 20px",
        }}
      >
        <div className="input-item flex space-x-2.5 mb-4">
          <div className="w-1/2 h-full">
            <InputCom
              label="Country"
              name="country"
              placeholder="Country"
              type="text"
              inputClasses="h-[30px]"
              inputHandler={(e) => handleInputChange("country", e.target.value)}
              value={paymentInfoTmp.country}
              warnings={warnings}
            />
          </div>
          <div className="w-1/2 h-full">
            <InputCom
              label="State"
              name="state"
              placeholder="State"
              type="text"
              inputClasses="h-[30px]"
              inputHandler={(e) => handleInputChange("state", e.target.value)}
              value={paymentInfoTmp.state}
              warnings={warnings}
            />
          </div>
        </div>
        <div className="input-item flex space-x-2.5 mb-4">
          <div className="w-1/2 h-full">
            <InputCom
              label="city"
              name="city"
              placeholder="Town / City"
              type="text"
              inputClasses="h-[30px]"
              inputHandler={(e) => handleInputChange("city", e.target.value)}
              value={paymentInfoTmp.city}
              warnings={warnings}
            />
          </div>
          <div className="w-1/2 h-full">
            <InputCom
              label="zipcode"
              name="zipcode"
              placeholder="Postcode / ZIP"
              type="text"
              inputClasses="h-[30px]"
              inputHandler={(e) => handleInputChange("zipcode", e.target.value)}
              value={paymentInfoTmp.zipcode}
              warnings={warnings}
            />
          </div>
        </div>
        <div className="input-item flex space-x-2.5 mb-4">
          <div className="w-full h-full">
            <InputCom
              label="Address 1"
              name="address1"
              placeholder="Address1"
              type="text"
              inputClasses="h-[30px]"
              inputHandler={(e) =>
                handleInputChange("address1", e.target.value)
              }
              value={paymentInfoTmp.address1}
              warnings={warnings}
            />
          </div>
        </div>
        <div className="input-item flex space-x-2.5 mb-4">
          <div className="w-full h-full">
            <InputCom
              label="Address 2 (Optional)"
              //name="address2"
              placeholder="Address2"
              type="text"
              inputClasses="h-[30px]"
              inputHandler={(e) =>
                handleInputChange("address2", e.target.value)
              }
              value={paymentInfoTmp.address2}
            />
          </div>
        </div>
      </div>
    </>
  );
}
