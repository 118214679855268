import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addRole, verifyRoleName } from "../../../actions/roleAction";
import SecondaryButton from "../../Helpers/Buttons/SecondaryButton";
import PrimaryButton from "../../Helpers/Buttons/PrimaryButton";

export default function AddRoleForm({ className }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [roleName, setRoleName] = useState(""); // State for role name
  const [roleNameNullError, setRoleNameNullError] = useState("");
  const [isRoleNameVerified, setIsRoleNameVerified] = useState(false);

  const [permissions, setPermissions] = useState([
    { permissionId: 1, permissionName: "ShoppingCart", hasPermission: 0 },
    { permissionId: 2, permissionName: "WishList", hasPermission: 0 },
    { permissionId: 3, permissionName: "PlaceOrder", hasPermission: 0 },
    { permissionId: 4, permissionName: "Orders", hasPermission: 0 },
    { permissionId: 5, permissionName: "Invoice", hasPermission: 0 },
    { permissionId: 6, permissionName: "Statement", hasPermission: 0 },
    { permissionId: 7, permissionName: "Payment", hasPermission: 0 },
    { permissionId: 8, permissionName: "MapPrice", hasPermission: 0 },
    { permissionId: 9, permissionName: "ProductMaster", hasPermission: 0 },
    { permissionId: 10, permissionName: "UserRoles", hasPermission: 0 },
    { permissionId: 11, permissionName: "SubUsers", hasPermission: 0 },
  ]);

  const handleRoleNamerChange = (event) => {
    const value = event.target.value;
    setRoleName(value);

    // Reset the verification status when the user changes the Role name
    setIsRoleNameVerified(false);
  };

  const handleVerifyRoleName = async (rolename) => {
    // Check if rolename is Null or not
    if (!rolename) {
      setRoleNameNullError("Please verify your Role Number");
      return;
    }

    try {
      // Call validation API for rolename and wait for the response
      const response = await dispatch(verifyRoleName(rolename));

      console.log("response : ", response);
      // Check this role name is verified or not
      setRoleName(rolename);
      setIsRoleNameVerified(true);

      alert("Your Role name is verified.");
    } catch (error) {
      console.log("error : ", error);
      // Rolename is duplicated
      alert("This Role name is duplicated. Please enter other Role name.");
      setRoleName("");
    }
  };

  const handlePermissionChange = (index) => () => {
    const newPermissions = [...permissions];
    newPermissions[index].hasPermission =
      newPermissions[index].hasPermission === 1 ? 0 : 1;
    setPermissions(newPermissions);
  };

  const handleConfirmClick = async (event, rolename, perms) => {
    event.preventDefault();

    // Check if po number is entered
    if (rolename === null || rolename === undefined || rolename === "") {
      return alert("Please enter a role name");
    }

    // Check if poNumber validation is complete or not
    if (!isRoleNameVerified) {
      console.log("isRoleNameVerified : ", isRoleNameVerified);
      return alert("Please verify your role name before proceeding");
    }

    // Check if permissions (perms) is null
    if (perms.every((permission) => permission.hasPermission === 0)) {
      return alert("Please check permissions for processing");
    }

    // Create a newRole object to be sent as form data
    const newRole = {
      roleName: rolename,
      rolePermissions: perms.map((perm) => ({
        permissionId: perm.permissionId,
        hasPermission: perm.hasPermission,
      })),
    };
    console.log("newRole", newRole);

    // Call Post role API
    try {
      const response = await dispatch(addRole(newRole));

      if (response.error) {
        throw new Error(response.error);
      }

      navigate(`/customerroles`);
    } catch (error) {
      // Handle the error
      alert(error.message);
    }
  };

  const handleCancelClick = () => {
    navigate("/customerroles");
  };

  return (
    <div className={`w-full ${className || ""} mt-12 max-w-xl mx-auto`}>
      <div className="w-full max-w-md max-w-xl mx-auto">
        <form onSubmit={handleConfirmClick} className="formStyles">
          {/* Form Title */}
          <h2 className="formTitle">Add Role</h2>
          <div>
            <label className="subuser-input-label" htmlFor="username">
              Role Name
            </label>

            <div className="flex">
              <input
                id="usernameForSubuser"
                type="usernameForSubuser"
                className={`py-2 px-3 subuser-input-box ${
                  roleNameNullError ? "warning" : ""
                }`}
                value={roleName}
                onChange={handleRoleNamerChange}
                placeholder={
                  roleNameNullError
                    ? "Please enter a role name"
                    : "Please enter a role name"
                }
                required
              />
            </div>

            <div>
              <button
                className="check-duplicate-button"
                onClick={(event) => {
                  event.preventDefault(); // Add this line
                  handleVerifyRoleName(roleName);
                }}
              >
                Verify role
              </button>
            </div>
          </div>

          {/* Order */}
          <div className="mb-4">
            <p className="block text-gray-700 text-sm font-bold mb-2">Order</p>
            <div className="flex flex-wrap">
              <label className="role_checkbox_label">
                <input
                  type="checkbox"
                  className="mr-3"
                  checked={permissions[0].hasPermission === 1}
                  onChange={handlePermissionChange(0)}
                />
                Add To Cart
              </label>
            </div>
            {/* Wish List */}
            {/*
            <div className="flex flex-wrap">
              <label className="mr-4 mb-2">
                <input
                  type="checkbox"
                  className="mr-3"
                  checked={permissions[1].hasPermission === 1}
                  onChange={handlePermissionChange(1)}
                />
                Wish list
              </label>
              </div> */}
            <div className="flex flex-wrap">
              <label className="role_checkbox_label">
                <input
                  type="checkbox"
                  className="mr-3"
                  checked={permissions[2].hasPermission === 1}
                  onChange={handlePermissionChange(2)}
                />
                Place Order
              </label>
            </div>

            <div className="flex flex-wrap">
              <label className="role_checkbox_label">
                <input
                  type="checkbox"
                  className="mr-3"
                  checked={permissions[3].hasPermission === 1}
                  onChange={handlePermissionChange(3)}
                />
                View Order History
              </label>
            </div>
          </div>

          {/* Accountings */}
          <div className="mb-4">
            <p className="block text-gray-700 text-sm font-bold mb-2">
              Payment/ Invoice
            </p>
            <div className="flex flex-wrap">
              <label className="role_checkbox_label">
                <input
                  type="checkbox"
                  className="mr-3"
                  checked={permissions[4].hasPermission === 1}
                  onChange={handlePermissionChange(4)}
                />
                View Invoice
              </label>
            </div>
            <div className="flex flex-wrap">
              <label className="role_checkbox_label">
                <input
                  type="checkbox"
                  className="mr-3"
                  checked={permissions[5].hasPermission === 1}
                  onChange={handlePermissionChange(5)}
                />
                Request Statement
              </label>
            </div>
            <div className="flex flex-wrap">
              <label className="role_checkbox_label">
                <input
                  type="checkbox"
                  className="mr-3"
                  checked={permissions[6].hasPermission === 1}
                  onChange={handlePermissionChange(6)}
                />
                Pay Invoice
              </label>
            </div>
          </div>

          {/* Sales */}
          {/*  Map price */}
          {/* 
          <div className="mb-4">
            <p className="block text-gray-700 text-sm font-bold mb-2">Sales</p>
            <div className="flex flex-wrap">
              <label className="role_checkbox_label">
                <input
                  type="checkbox"
                  className="mr-3"
                  checked={permissions[7].hasPermission === 1}
                  onChange={handlePermissionChange(7)}
                />
                Map price
              </label>
            </div>
            */}
          {/* Product Master */}
          {/* <div className="flex flex-wrap">
              <label className="role_checkbox_label">
                <input
                  type="checkbox"
                  className="mr-3"
                  checked={permissions[8].hasPermission === 1}
                  onChange={handlePermissionChange(8)}
                />
                Product Master
              </label>
            </div> */}

          {/* Admin */}
          {/* <div className="mb-4">
              <p className="block text-gray-700 text-sm font-bold mb-2">
                Admin
              </p>
              <div className="flex flex-wrap">
                <label className="mr-4 mb-2">
                  <input
                    type="checkbox"
                    className="mr-3"
                    checked={permissions[9].hasPermission === 1}
                    onChange={handlePermissionChange(9)}
                  />
                  Custom Role
                </label>
              </div>

              <div className="flex flex-wrap">
                <label className="mr-4 mb-2">
                  <input
                    type="checkbox"
                    className="mr-3"
                    checked={permissions[10].hasPermission === 1}
                    onChange={handlePermissionChange(10)}
                  />
                  Custom Sub-Users
                </label>
              </div>
            </div>
          </div>
          */}

          <div className="flex justify-center">
            <SecondaryButton
              width="185px"
              height="40px"
              fontSize="18px"
              onClick={() => handleCancelClick()}
              style={{ marginBottom: "10px" }}
            >
              Cancel
            </SecondaryButton>
            <div style={{ width: "15px" }} />
            <PrimaryButton
              width="185px"
              height="40px"
              fontSize="18px"
              onClick={(event) => {
                event.preventDefault();
                handleConfirmClick(event, roleName, permissions);
              }}
              style={{ marginBottom: "10px" }}
            >
              Add
            </PrimaryButton>
          </div>
        </form>
      </div>

      {/*
      <div className="relative w-full overflow-x-auto border border-[#EDEDED]">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
      */}
      {/* table heading */}
      {/*     
      <tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
        <td className="py-4 whitespace-nowrap text-center">Username</td>
        <td className="py-4 whitespace-nowrap text-center">Email</td>
        <td className="py-4 whitespace-nowrap text-center">Phone</td>
        <td className="py-4 whitespace-nowrap text-center">Status</td>
        <td className="py-4 whitespace-nowrap  text-center"> </td>
      </tr>
      */}
      {/* table heading end */}
      {/*
            <tr className="bg-white border-b hover:bg-gray-50">
              <td className="pl-10  py-4  w-[380px]">
                <div className="flex space-x-6 items-center">
                  <div className="flex-1 flex flex-col">
                    <p className="font-medium text-[15px] text-qblack">
                      iPhone 12 Pro Max 128GB
                    </p>
                  </div>
                </div>
              </td>
              <td className="text-center py-4 px-2">
                <div className=" flex justify-center items-center">
                  <span className="w-[20px] h-[20px] bg-[#E4BC87] block rounded-full"></span>
                </div>
              </td>
              <td className="text-center py-4 px-2">
                <div className="flex space-x-1 items-center justify-center">
                  <span className="text-[15px] font-normal">Small</span>
                </div>
              </td>
              <td className="text-center py-4 px-2">
                <div className="flex space-x-1 items-center justify-center">
                  <span className="text-[15px] font-normal">$38</span>
                </div>
              </td>

              <td className="py-4">
                <div className="flex justify-center items-center">
                  <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">
                    Edit
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> 
    */}
    </div>
  );
}
