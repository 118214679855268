export const GET_COLLECTIONS_REQUEST = "GET_COLLECTIONS_REQUEST";
export const GET_COLLECTIONS_SUCCESS = "GET_COLLECTIONS_SUCCESS";
export const GET_COLLECTIONS_FAIL = "GET_COLLECTIONS_FAIL";

export const GET_STYLES_REQUEST = "GET_STYLES_REQUEST";
export const GET_STYLES_SUCCESS = "GET_STYLES_SUCCESS";
export const GET_STYLES_FAIL = "GET_STYLES_FAIL";

export const GET_QUICKORDER_REQUEST = "GET_QUICKORDER_REQUEST";
export const GET_QUICKORDER_SUCCESS = "GET_QUICKORDER_SUCCESS";
export const GET_QUICKORDER_FAIL = "GET_QUICKORDER_FAIL";
export const GET_QUICKORDER_RESET = "GET_QUICKORDER_RESET";

export const GET_FILTEREDINVENTORY_REQUEST = "GET_FILTEREDINVENTORY_REQUEST";
export const GET_FILTEREDINVENTORY_SUCCESS = "GET_FILTEREDINVENTORY_SUCCESS";
export const GET_FILTEREDINVENTORY_FAIL = "GET_FILTEREDINVENTORY_FAIL";
