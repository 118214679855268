import Axios from "axios";
import {
  POST_CONTACTUS_REQUEST,
  POST_CONTACTUS_SUCCESS,
  POST_CONTACTUS_FAIL,
} from "../constants/companyConstants";

const apiUrl = process.env.REACT_APP_API_URL;

// eslint-disable-next-line import/prefer-default-export
export const postContactus = (formData) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: POST_CONTACTUS_REQUEST, payload: formData });
  console.log("POST_CONTACTUS_REQUEST:", formData);

  try {
    const response = await Axios.post(
      `${apiUrl}/company/contactUs/${tokenUserId}`,
      formData,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );

    dispatch({ type: POST_CONTACTUS_SUCCESS, payload: response.data });
    console.log("MAEVN contactus Response:", response);

    return response; // Return the response data
  } catch (error) {
    if (error.response) {
      dispatch({
        type: POST_CONTACTUS_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: POST_CONTACTUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    console.log("contactus error : ", error);
    throw error; // Rethrow the error
  }
};
