import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOrders } from "../../../actions/orderAction";
import { useEffect } from "react";
import "../Dashboard.css";

export default function OrderTab() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // order information
  const OrderHistory = useSelector((state) => state.getOrders);
  const { loading, error, orders } = OrderHistory;
  console.log("orders", orders);

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  if (loading) {
    // Add loading state or return null while the data is being fetched
    return <div>Loading...</div>;
  }

  if (orders === undefined || orders === null || orders.length === 0) {
    return <div>You haven't placed any orders yet.</div>;
  }

  // convert special characters to alphabet
  function decodeHTMLEntities(text) {
    var txt = document.createElement("textarea");
    txt.innerHTML = text;
    return txt.value;
  }

  // move to order details page
  function handleViewOrderDetailsClick(orderId) {
    navigate(`/orderdetails/${orderId}`);
  }

  // for placed order date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  // order status
  function getStatusDescription(statusCode) {
    switch (statusCode) {
      case 0:
        return "Open";
      case 10:
        return "issued";
      case 30:
        return "Processing";
      case 70:
        return "Shipped";
      case 80:
        return "Shipped partially";
      case 90:
        return "Canceled";
      case 95:
        return "Cancellation request";
      default:
        return "Unknown Status";
    }
  }

  return (
    <div className="relative w-full overflow-x-auto sm:rounded-lg order-table">
      <div className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        {orders.map((order, index) => (
          <div key={index} className="bg-white border-b hover:bg-gray-50">
            <div className="order-header flex justify-between py-2 ">
              <div style={{ fontWeight: "bold" }}>
                Order Placed : {formatDate(order.createdDate)}
              </div>

              <button
                type="button"
                className="w-[110px] h-[46px] bg-qyellow text-qblack font-bold"
                onClick={() => handleViewOrderDetailsClick(order.orderId)}
              >
                View Details
              </button>
            </div>
            <div className="order-item flex py-4">
              <div className="order-item-details flex-grow">
                <span className="text-lg font-bold text-black">
                  {getStatusDescription(order.orderStatus)}
                </span>
                <div className="py-1">
                  Purchasing Order No. {order.customerPo}
                </div>
                <div className="py-1">Ship To: {order.shipToName}</div>
                <div
                  style={{ fontSize: "0.875rem", color: "#606F7B" }}
                  className="py-1"
                >
                  Total Price: ${order.totalPrice}
                </div>
              </div>
              <div className="order-item-username flex items-center justify-end flex-grow">
                <div>Ordered by: {order.loginId}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
