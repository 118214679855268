import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import Checkbox from "../Helpers/Checkbox";
import { Scrollbars } from "react-custom-scrollbars";

export default function ProductsFilter({
  filters,
  checkboxHandler,
  volume,
  volumeHandler,
  priceHandler,
  storage,
  filterstorage,
  className,
  filterToggle,
  filterToggleHandler,
}) {
  return (
    <>
      <div
        className={`filter-widget w-full fixed lg:relative left-0 top-0 h-screen z-10 lg:h-auto overflow-y-scroll lg:overflow-y-auto bg-white px-[30px] pt-[40px] ${
          className || ""
        } ${filterToggle ? "block" : "hidden lg:block"}`}
      >
        {/*Category */}
        {/*<div className="filter-subject-item pb-10 border-b border-qgray-border">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Category</h1>
          </div>
          <div className="filter-items">
            <ul>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="Jacket"
                      name="Jacket"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.Jacket}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Jacket"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Jacket
                    </label>
                  </div>
                </div>
                <div>
                  <span className="cursor-pointer">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="4" width="10" height="2" fill="#C4C4C4" />
                      <rect
                        x="6"
                        width="10"
                        height="2"
                        transform="rotate(90 6 0)"
                        fill="#C4C4C4"
                      />
                    </svg>
                  </span>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="Pants"
                      name="Pants"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.Pants}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Pants"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Pants
                    </label>
                  </div>
                </div>
                <div>
                  <span className="cursor-pointer">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="4" width="10" height="2" fill="#C4C4C4" />
                      <rect
                        x="6"
                        width="10"
                        height="2"
                        transform="rotate(90 6 0)"
                        fill="#C4C4C4"
                      />
                    </svg>
                  </span>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="Top"
                      name="Top"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.Top}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Top"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Top
                    </label>
                  </div>
                </div>
                <div>
                  <span className="cursor-pointer">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect y="4" width="10" height="2" fill="#C4C4C4" />
                      <rect
                        x="6"
                        width="10"
                        height="2"
                        transform="rotate(90 6 0)"
                        fill="#C4C4C4"
                      />
                    </svg>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>*/}

        {/* Price */}
        <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Price Range</h1>
          </div>
          <div className="price-range mb-5">
            <InputRange
              draggableTrack
              maxValue={1000}
              minValue={0}
              value={volume}
              onChange={volumeHandler}
              onChangeComplete={priceHandler}
            />
          </div>
          <p className="text-xs text-qblack font-400">
            Price: ${volume.min} - ${volume.max}
          </p>
        </div>

        {/* Fits */}
        <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Fit</h1>
          </div>
          <div className="filter-items">
            <ul>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="Petite"
                      name="Petite"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.apple}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Petite"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Petite
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="Regular"
                      name="Regular"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.Regular}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Regular"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Regular
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="Short"
                      name="Short"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.Short}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Short"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Short
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="Tall"
                      name="Tall"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.Tall}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="Tall"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Tall
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* Size */}
        <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Size</h1>
          </div>
          <Scrollbars className="scroll_filter">
            <div className="filter-items">
              <ul>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="twoXL"
                        name="twoXL"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.twoXL}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="twoXL"
                        className="text-xs font-black font-400 capitalize"
                      >
                        2XL
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="twoXLP"
                        name="twoXLP"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.twoXLP}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="twoXLP"
                        className="text-xs font-black font-400 capitalize"
                      >
                        2XLP
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="twoXLT"
                        name="twoXLT"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.twoXLT}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="twoXLT"
                        className="text-xs font-black font-400 capitalize"
                      >
                        2XLT
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="threeXL"
                        name="threeXL"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.threeXL}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="threeXL"
                        className="text-xs font-black font-400 capitalize"
                      >
                        3XL
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="threeXLP"
                        name="threeXLP"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.threeXLP}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="threeXLP"
                        className="text-xs font-black font-400 capitalize"
                      >
                        3XLP
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="threeXLT"
                        name="threeXLT"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.threeXLT}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="threeXLT"
                        className="text-xs font-black font-400 capitalize"
                      >
                        3XLT
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="fourXL"
                        name="fourXL"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.fourXL}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="fourXL"
                        className="text-xs font-black font-400 capitalize"
                      >
                        4XL
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="fiveXL"
                        name="fiveXL"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.fiveXL}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="fiveXL"
                        className="text-xs font-black font-400 capitalize"
                      >
                        5XL
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="LG"
                        name="LG"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.LG}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="LG"
                        className="text-xs font-black font-400 capitalize"
                      >
                        LG
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="LGP"
                        name="LGP"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.LGP}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="LGP"
                        className="text-xs font-black font-400 capitalize"
                      >
                        LGP
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="LGT"
                        name="LGT"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.LGT}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="LGT"
                        className="text-xs font-black font-400 capitalize"
                      >
                        LGT
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="MD"
                        name="MD"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.MD}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="MD"
                        className="text-xs font-black font-400 capitalize"
                      >
                        MD
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="MDP"
                        name="MDP"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.MDP}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="MDP"
                        className="text-xs font-black font-400 capitalize"
                      >
                        MDP
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="MDT"
                        name="MDT"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.MDT}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="MDT"
                        className="text-xs font-black font-400 capitalize"
                      >
                        MDT
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="SM"
                        name="SM"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.SM}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="SM"
                        className="text-xs font-black font-400 capitalize"
                      >
                        SM
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="SMP"
                        name="SMP"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.SMP}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="SMP"
                        className="text-xs font-black font-400 capitalize"
                      >
                        SMP
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="SMT"
                        name="SMT"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.SMT}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="SMT"
                        className="text-xs font-black font-400 capitalize"
                      >
                        SMT
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="XL"
                        name="XL"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.XL}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="XL"
                        className="text-xs font-black font-400 capitalize"
                      >
                        XL
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="XLP"
                        name="XLP"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.XLP}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="XLP"
                        className="text-xs font-black font-400 capitalize"
                      >
                        XLP
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="XLT"
                        name="XLT"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.XLT}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="XLT"
                        className="text-xs font-black font-400 capitalize"
                      >
                        XLT
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="XS"
                        name="XS"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.XS}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="XS"
                        className="text-xs font-black font-400 capitalize"
                      >
                        XS
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="XSP"
                        name="XSP"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.XSP}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="XSP"
                        className="text-xs font-black font-400 capitalize"
                      >
                        XSP
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="XST"
                        name="XST"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.XST}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="XST"
                        className="text-xs font-black font-400 capitalize"
                      >
                        XST
                      </label>
                    </div>
                  </div>
                </li>
                <li className="item flex justify-between items-center mb-5">
                  <div className="flex space-x-[14px] items-center">
                    <div>
                      <Checkbox
                        id="XXS"
                        name="XXS"
                        handleChange={(e) => checkboxHandler(e)}
                        checked={filters.XXS}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="XXS"
                        className="text-xs font-black font-400 capitalize"
                      >
                        XXS
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </Scrollbars>
        </div>

        {/*Inseam */}
        <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Inseam</h1>
          </div>
          <div className="filter-items">
            <ul>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="twofive"
                      name="twofive"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.twofive}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="twofive"
                      className="text-xs font-black font-400 capitalize"
                    >
                      25
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="twosix"
                      name="twosix"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.twosix}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="twosix"
                      className="text-xs font-black font-400 capitalize"
                    >
                      26
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="twoseven"
                      name="twoseven"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.twoseven}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="twoseven"
                      className="text-xs font-black font-400 capitalize"
                    >
                      27
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="twoeight"
                      name="twoeight"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.twoeight}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="twoeight"
                      className="text-xs font-black font-400 capitalize"
                    >
                      28
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="twoninedotfive"
                      name="twoninedotfive"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.twoninedotfive}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="twoninedotfive"
                      className="text-xs font-black font-400 capitalize"
                    >
                      29.5
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="threeone"
                      name="threeone"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.threeone}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="threeone"
                      className="text-xs font-black font-400 capitalize"
                    >
                      31
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="threethree"
                      name="threethree"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.threethree}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="threethree"
                      className="text-xs font-black font-400 capitalize"
                    >
                      33
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {/* Color */}
        {/*  <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Color</h1>
          </div>
          <div className="filter-items">
            <div className="flex space-x-[5px] flex-wrap">
              <span
                onClick={() => filterstorage("BKM")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "BKM"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                BKM
              </span>
              <span
                onClick={() => filterstorage("BLK")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "BLK"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                BLK
              </span>
              <span
                onClick={() => filterstorage("CBL")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "CBL"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                CBL
              </span>
              <span
                onClick={() => filterstorage("CHC")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "CHC"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                CHC
              </span>
              <span
                onClick={() => filterstorage("HPK")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "HPK"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                HPK
              </span>
              <span
                onClick={() => filterstorage("HTR")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "HTR"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                HTR
              </span>
              <span
                onClick={() => filterstorage("KHI")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "KHI"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                KHI
              </span>
              <span
                onClick={() => filterstorage("MBL")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "MBL"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                MBL
              </span>
              <span
                onClick={() => filterstorage("NVY")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "NVY"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                NVY
              </span>

              <span
                onClick={() => filterstorage("NYM")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "NYM"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                NYM
              </span>
              <span
                onClick={() => filterstorage("PEW")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "PEW"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                PEW
              </span>
              <span
                onClick={() => filterstorage("PPL")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "PPL"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                PPL
              </span>
              <span
                onClick={() => filterstorage("REDs")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "RED"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                RED
              </span>
              <span
                onClick={() => filterstorage("RYL")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "RYL"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                RYL
              </span>
              <span
                onClick={() => filterstorage("TEL")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "TEL"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                TEL
              </span>
              <span
                onClick={() => filterstorage("WHT")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "WHT"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                WHT
              </span>
              <span
                onClick={() => filterstorage("WIN")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${
                  storage === "WIN"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                }`}
              >
                WIN
              </span>
            </div>
          </div>
        </div>*/}

        <button
          onClick={filterToggleHandler}
          type="button"
          className="w-10 h-10 fixed top-5 right-5 z-50 rounded lg:hidden flex justify-center items-center border border-qred text-qred"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </>
  );
}
