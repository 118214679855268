import Axios from "axios";

import {
  GET_STATEMENTHEADER_REQUEST,
  GET_STATEMENTHEADER_SUCCESS,
  GET_STATEMENTHEADER_FAIL,
  POST_REQUESTNUM_REQUEST,
  POST_REQUESTNUM_SUCCESS,
  POST_REQUESTNUM_FAIL,
  GET_STATEMENTLINE_REQUEST,
  GET_STATEMENTLINE_SUCCESS,
  GET_STATEMENTLINE_FAIL,
} from "../constants/statementConstants";

// eslint-disable-next-line import/prefer-default-export
export const getStatementHeaderReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STATEMENTHEADER_REQUEST:
      return { loading: true };
    case GET_STATEMENTHEADER_SUCCESS:
      return { loading: false, statementHeader: action.payload };
    case GET_STATEMENTHEADER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const postStatementRequestNumReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_REQUESTNUM_REQUEST:
      return { loading: true };
    case POST_REQUESTNUM_SUCCESS:
      return { loading: false, requestedNum: action.payload };
    case POST_REQUESTNUM_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getStatementLineReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STATEMENTLINE_REQUEST:
      return { loading: true };
    case GET_STATEMENTLINE_SUCCESS:
      return { loading: false, statement: action.payload };
    case GET_STATEMENTLINE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
