import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import PageTitle from "../../../src/components/Helpers/PageTitle";
import Layout from "../../../src/components/Partials/Layout";
import { getShippingInfo } from "../../actions/checkoutAction";
import { Spinner } from "../../../src/components/Helpers/Loaders/Spinner";
import CheckoutPage from "../../components/OrderProcess/Checkout/Sections/CheckoutPage";
import { getCart } from "../../actions/cartAction";
import BulkOrderCheckoutPage from "./BulkOrderCheckoutPage";

export default function Bulkorder({ cart = true }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("userId");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderInfo = location?.state?.orderInfo; // Access the passed orderInfo
  console.log("orderInfo in Bulkorder ", orderInfo);

  // Validate user
  const { search } = useLocation(); // search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login";

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  // Get shipping information (customer information for order)
  const shippingInfo = useSelector((state) => state.getShippingInfo);
  const { loading, error, order: shipInfo } = shippingInfo;

  const orderList = shipInfo?.data.result.orderList;
  const shipToAddressList = shipInfo?.data.result.shipToAddress;
  const billToAddressList = shipInfo?.data.result.billToAddress;
  const userAccountInfo = shipInfo?.data.result.userAccount;

  // Get cart
  const cartItems = useSelector((state) => state.getCart);
  const { loadingItem, errorItem, cartItems: items } = cartItems;
  const [localItems, setLocalItems] = useState([]);

  // Backlog alert
  const [isModalOpen, setModalOpen] = useState(false);
  const [backorderItems, setBackorderItems] = useState([]);

  console.log("localItems in checkout index- ", localItems);

  // Update items in cart
  const updateLocalItems = (updatedItems) => {
    setLocalItems(updatedItems);
  };

  useEffect(() => {
    setLocalItems(items?.data.result);
  }, [items]);

  // Delete item
  const handleItemRemoved = (itemProductId) => {
    setLocalItems((prevState) =>
      prevState.filter((item) => item.pid !== itemProductId)
    );
  };

  // User validation and Call API
  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");
      navigate(redirect);
    } else {
      console.log("You are already logined ");
    }
    dispatch(getShippingInfo());
    dispatch(getCart(userId));
  }, [dispatch, navigate, redirect, userInfo]);

  const defaultCarrierService = shipInfo?.data.result.defaultCarrierService;

  // Spinner while checkout loading
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const toggleCheckoutLoading = (isLoading) => {
    setLoadingCheckout(isLoading);
  };

  return (
    <Layout childrenClasses={`${cart ? "pt-0 pb-0" : ""} relative`}>
      {/* This line will render the spinner when loadingCheckout is true */}
      {loadingCheckout && <Spinner />}

      <div className="cart-page-wrapper w-full pb-60px white-background">
        <div className="w-full mb-[30px]">
          {/* Page history */}
          <PageTitle
            title="Import Order "
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Import Order", path: "/bulkorder" },
            ]}
          />
        </div>
        {/* product list */}
        <div className="w-full mt-[23px]">
          <div className="container-x mx-auto">
            <BulkOrderCheckoutPage
              isLoading={loading}
              userAccountInfo={userAccountInfo}
              // cartItems={orderList}
              cartItems={localItems}
              onItemRemoved={handleItemRemoved}
              updateLocalItems={updateLocalItems}
              shipToAddresses={shipToAddressList}
              deliveryOption={defaultCarrierService}
              billToAddresses={billToAddressList}
              toggleCheckoutLoading={toggleCheckoutLoading}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
