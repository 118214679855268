export const SET_CREDIT_BALANCE = "SET_CREDIT_BALANCE";

export const SET_SELECTEDINVOICE_REQUEST = "SET_SELECTEDINVOICE_REQUEST";
export const SET_SELECTEDINVOICE_SUCCESS = "SET_SELECTEDINVOICE_SUCCESS";
export const SET_SELECTEDINVOICE_FAIL = "SET_SELECTEDINVOICE_FAIL";

export const POST_PAYMENT_REQUEST = "POST_PAYMENT_REQUEST";
export const POST_PAYMENT_SUCCESS = "POST_PAYMENT_SUCCESS";
export const POST_PAYMENT_FAIL = "POST_PAYMENT_FAIL";

export const POST_MAEVNPAYMENT_REQUEST = "POST_MAEVNPAYMENT_REQUEST";
export const POST_MAEVNPAYMENT_SUCCESS = "POST_MAEVNPAYMENT_SUCCESS";
export const POST_MAEVNPAYMENT_FAIL = "POST_MAEVNPAYMENT_FAIL";

export const GET_PAYMENTCONFIRM_REQUEST = "GET_PAYMENTCONFIRM_REQUEST";
export const GET_PAYMENTCONFIRM_SUCCESS = "GET_PAYMENTCONFIRM_SUCCESS";
export const GET_PAYMENTCONFIRM_FAIL = "GET_PAYMENTCONFIRM_FAIL";
