import IcoEmail from "../icons/IcoEmail";
import IcoPerson from "../icons/IcoPerson";
import IcoPhone from "../icons/IcoPhone";
import "../Dashboard.css";

export default function Header({ myProfile, myAccounting }) {
  return (
    <div className="w-full bg-white px-10 py-9 mt-4">
      <div className="title-area">
        <h1>Your Accounting Information</h1>
      </div>
      <div className="info-container">
        {myAccounting && (
          <>
            <div className="accounting-item">
              <div
                className="accounting-header"
                style={{ color: "var(--Warning-Color---Red, #F44)" }}
              >
                Past Due Amount
              </div>
              <div
                className="accounting-data"
                style={{ color: "var(--Warning-Color---Red, #F44)" }}
              >
                ${myAccounting.pastDueAmount.toFixed(2)}
              </div>
            </div>
            <div className="accounting-item">
              <div
                className="accounting-header"
                style={{ color: "var(--Base-White, #FFF)" }}
              >
                Credit Limit
              </div>
              <div className="accounting-data">${myAccounting.creditLimit}</div>
            </div>
            <div className="accounting-item">
              <div
                className="accounting-header"
                style={{ color: "var(--Base-White, #FFF)" }}
              >
                Current Amount
              </div>
              <div className="accounting-data">
                ${myAccounting.currentAmount.toFixed(2)}
              </div>
            </div>
            <div className="accounting-item">
              <div
                className="accounting-header"
                style={{ color: "var(--Base-White, #FFF)" }}
              >
                Total Invoiced Amount
              </div>
              <div className="accounting-data">
                ${myAccounting.totalAmount.toFixed(2)}
              </div>
            </div>
            <div className="accounting-item">
              <div
                className="accounting-header"
                style={{ color: "var(--Base-White, #FFF)" }}
              >
                Available Credit Amount
              </div>
              <div className="accounting-data">
                ${myAccounting.creditBalance.toFixed(2)}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
