import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDERDETAILS_REQUEST,
  GET_ORDERDETAILS_SUCCESS,
  GET_ORDERDETAILS_FAIL,
  GET_CANCELREQUEST_REQUEST,
  GET_CANCELREQUEST_SUCCESS,
  GET_CANCELREQUEST_FAIL,
} from "../constants/orderConstants";

// eslint-disable-next-line import/prefer-default-export
export const getOrdersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORDERS_REQUEST:
      return { loading: true };
    case GET_ORDERS_SUCCESS:
      return { loading: false, orders: action.payload };
    case GET_ORDERS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getOrderDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORDERDETAILS_REQUEST:
      return { loading: true };
    case GET_ORDERDETAILS_SUCCESS:
      return { loading: false, orderDetails: action.payload };
    case GET_ORDERDETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getOrderCancellationRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CANCELREQUEST_REQUEST:
      return { loading: true };
    case GET_CANCELREQUEST_SUCCESS:
      return { loading: false, requestResult: action.payload };
    case GET_CANCELREQUEST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
