import Axios from "axios";
import {
  SET_CREDIT_BALANCE,
  SET_SELECTEDINVOICE_REQUEST,
  SET_SELECTEDINVOICE_SUCCESS,
  POST_PAYMENT_REQUEST,
  POST_PAYMENT_SUCCESS,
  POST_PAYMENT_FAIL,
  POST_MAEVNPAYMENT_REQUEST,
  POST_MAEVNPAYMENT_SUCCESS,
  POST_MAEVNPAYMENT_FAIL,
  GET_PAYMENTCONFIRM_REQUEST,
  GET_PAYMENTCONFIRM_SUCCESS,
} from "../constants/paymentConstants";

const apiUrl = process.env.REACT_APP_API_URL;

//const AUTH_API_ENDPOINT = "https://apitest.authorize.net/xml/v1/request.api"; // sandbox
const AUTH_API_ENDPOINT = "https://api.authorize.net/xml/v1/request.api"; // production
const MAEVN_API_ENDPOINT = `${apiUrl}/payment`;

export const setSelectedInvoicesToRedux =
  (selectedInvoices) => async (dispatch) => {
    dispatch({
      type: SET_SELECTEDINVOICE_REQUEST,
      payload: selectedInvoices,
    });

    dispatch({
      type: SET_SELECTEDINVOICE_SUCCESS,
      payload: selectedInvoices,
    });
  };

export const setCreditBalanceToRedux = (balance) => {
  return {
    type: SET_CREDIT_BALANCE,
    payload: balance,
  };
};

export const postAuthCreditCardPayment =
  (authorizeAPIRequestData) => async (dispatch) => {
    dispatch({ type: POST_PAYMENT_REQUEST, payload: authorizeAPIRequestData });
    console.log("Authoirze.net parameter : ", authorizeAPIRequestData);

    try {
      const response = await Axios.post(
        AUTH_API_ENDPOINT,
        authorizeAPIRequestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({ type: POST_PAYMENT_SUCCESS, payload: response.data });
      console.log("Auth Payment Response:", response);

      return response; // Return the response data
    } catch (error) {
      if (error.response) {
        dispatch({
          type: POST_PAYMENT_FAIL,
          payload: error.response.data.error,
        });
      } else {
        dispatch({
          type: POST_PAYMENT_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
      console.log("Payment error : ", error);
      throw error; // Rethrow the error
    }
  };

export const postMaevnPayment = (maevnAPIRequestData) => async (dispatch) => {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  dispatch({ type: POST_MAEVNPAYMENT_REQUEST, payload: maevnAPIRequestData });
  console.log("MAEVN Payment request:", maevnAPIRequestData);

  try {
    const response = await Axios.post(MAEVN_API_ENDPOINT, maevnAPIRequestData, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });

    dispatch({ type: POST_MAEVNPAYMENT_SUCCESS, payload: response.data });
    console.log("MAEVN Payment Response:", response);

    return response; // Return the response data
  } catch (error) {
    if (error.response) {
      dispatch({
        type: POST_MAEVNPAYMENT_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: POST_MAEVNPAYMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    console.log("Payment error : ", error);
    throw error; // Rethrow the error
  }
};

export const postPaymentConfirmRedux =
  (maevnAPIResponseData) => async (dispatch) => {
    dispatch({
      type: GET_PAYMENTCONFIRM_REQUEST,
      payload: maevnAPIResponseData,
    });

    dispatch({
      type: GET_PAYMENTCONFIRM_SUCCESS,
      payload: maevnAPIResponseData,
    });
  };
