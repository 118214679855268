import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function TermsCondition() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Terms and conditions", path: "/terms-conditions" },
            ]}
            title="Terms and Conditions"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-2">
                YOUR USE OF THIS WEBSITE IS GOVERNED BY THESE TERMS AND
                CONDITIONS
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                Your use of this Maevn website constitutes your agreement to
                follow these rules and to be bound by them. If you do not agree
                with any of these Terms and Conditions, do not use the Maevn
                Corp website.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack  mb-2">
                THESE TERMS AND CONDITIONS MAY CHANGE
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7 mb-10">
                Maevn Corp reserves the right to update or modify these Terms
                and Conditions at any time without prior notice. Your use of
                this website following any such change constitutes your
                agreement to follow and be bound by the Terms and Conditions as
                changed. For this reason, we encourage you to review these Terms
                and Conditions whenever you use this website.
              </p>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack  mb-2">
                COPYRIGHT NOTICE
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                All of the Content you see and hear on the Maevn website,
                including, for example, all of the page headers, images,
                illustrations, graphics, and text, are subject to trademark,
                service mark, trade dress, copyright and/or other intellectual
                property rights or licenses held by Maevn® Corp. “Maevn” is a
                trademark of Maevn Corp. Use of such trademarks herein and the
                use of any internet domain name that includes the word mark
                “Maevn® ” is undertaken pursuant to Maevn Corp.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-2">
                DOWNLOADS
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                The Downloads contains downloadable images and files available
                to our retail and internet partners to use on their websites. No
                right, title or interest in any downloaded materials is
                transferred to you as a result of any such downloading. Maevn
                Corp reserves complete title and full intellectual property
                rights in any Content you download from this website. Use of
                Maevn Corp to represent non-Maevn product is strictly
                prohibited.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-2">
                LINKS TO OTHER WEBSITES AND/OR SERVICES
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                To the extent that this Site contains links to outside services
                and resources, the availability and content of which Maevn Corp
                does not control, any concerns regarding any such service or
                resource, or any link thereto, should be directed to the
                particular outside service or resource.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-2">
                CORRECTION OF ERRORS AND INACCURACIES; LIMITATIONS ON QUANTITY;
                RETURNS; RISK OF LOSS
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7">
                The information on this website may contain typographical errors
                or inaccuracies and may not be complete or current. We therefore
                reserve the right to correct any errors, inaccuracies or
                omissions and to change or update information at any time
                without prior notice (including after you have submitted your
                order). Please note that such errors, inaccuracies or omissions
                may relate to product description and availability.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
