import "./style.css";
import Arrow from "../icons/Arrow";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { signout } from "../../../actions/userAuthAction";

export default function Selectbox({ className, action, children, userInfo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 1. Initialize state variables
  const [item, setItem] = useState(null);
  const [toggle, setToggle] = useState(false);

  const [isTouchDevice, setIsTouchDevice] = useState(false); // track if the device is a touch device

  const defaultItemsUrls = [
    {
      key: "My Account",
      url: "/myaccount#dashboard",
      displayName: "Dashboard",
    },
    { key: "Profile", url: "/myaccount#profile", displayName: "Profile" },
    { key: "Adresses", url: "/myaccount#address", displayName: "Addresses" },
    { key: "Contact us", url: "/contact", displayName: "Contact us" },
  ];
  const conditionalItems = [
    { key: "Orders", url: "/myaccount#order", displayName: "Order History " },
    // { key: "WishList", url: "/myaccount#wishlist", displayName: "Wish List" },
    { key: "Invoice", url: "/myaccount#invoice", displayName: "Invoice" },
    { key: "Statement", url: "/myaccount#statement", displayName: "Statement" },

    { key: "UserRoles", url: "/customerroles", displayName: "User Roles" },
    { key: "SubUser", url: "/customerusers", displayName: "Sub-User" },
  ];

  const filteredItems = conditionalItems.filter(
    (item) => userInfo?.result?.user?.permissions[item.key] === 1
  );

  // Add Logout button at the end
  const allItems = defaultItemsUrls.concat(filteredItems, {
    key: "Logout",
    url: "#", // No need to navigate, will handle via onClick
    displayName: "Logout",
  });

  const datas = allItems.map((item) => item.displayName);
  const urls = allItems.map((item) => item.url);

  const signOutHandler = () => {
    dispatch(signout());
    navigate("/Login"); // Redirect to login page after sign out
  };

  // datas의 첫 번째 항목을 기본값으로 설정
  useEffect(() => {
    setItem(datas[0]);
  }, [datas]);

  const handler = (e, value, index) => {
    if (action) {
      action(value);
    }
    setItem(value);
    setToggle(false);
    if (datas[index] === "Logout") {
      signOutHandler();
    } else if (urls[index]) {
      navigate(urls[index]);
    }
  };

  // 2. Detect if the device supports touch
  useEffect(() => {
    const detectTouch = () => {
      setIsTouchDevice(true); // Set isTouchDevice to true if touch is detected
      window.removeEventListener("touchstart", detectTouch); // Remove the event listener
    };

    window.addEventListener("touchstart", detectTouch, { once: true }); // Add event listener for touchstart

    return () => {
      window.removeEventListener("touchstart", detectTouch); // Clean up the event listener
    };
  }, []);

  // 3. Function to handle dropdown toggle for touch devices
  const toggleDropdown = () => {
    if (isTouchDevice) {
      setToggle(!toggle); // Toggle the dropdown if it's a touch device
    }
  };

  // 4. Function to navigate to the dashboard
  const goToDashboard = () => {
    navigate("/myaccount");
  };

  // 5. Function to handle the "My Account" button click
  const handleClick = () => {
    if (isTouchDevice) {
      toggleDropdown(); // On touch devices, toggle the dropdown
    } else {
      goToDashboard(); // On non-touch devices (like desktop), navigate to the dashboard
    }
  };

  // 6. Close dropdown when clicking outside on touch devices
  useEffect(() => {
    const closeDropdown = (e) => {
      if (isTouchDevice && toggle && !e.target.closest(".my-select-box")) {
        setToggle(false);
      }
    };

    if (isTouchDevice && toggle) {
      document.addEventListener("click", closeDropdown);
    }

    return () => {
      if (isTouchDevice) {
        document.removeEventListener("click", closeDropdown);
      }
    };
  }, [isTouchDevice, toggle]);

  return (
    <>
      {datas.length > 0 && (
        <div
          className={`my-select-box whitespace-nowrap ${className || ""}`}
          onMouseEnter={() => !isTouchDevice && setToggle(true)}
          onMouseLeave={() => !isTouchDevice && setToggle(false)}
        >
          <button
            type="button"
            className="my-select-box-btn "
            onClick={handleClick} // Use this combined handler
          >
            {/* {children ? children({ item }) : <span>{item}</span>} */}
            {children ? children({ item }) : <span>My Account</span>}
          </button>
          <div className={`my-select-box-section ${toggle ? "open" : ""}`}>
            <ul className="list">
              {datas.map((value, index) => (
                <li
                  className={item === value ? "selected" : ""}
                  key={Math.random() + value}
                  onClick={(e) => handler(e, value, index)}
                >
                  {value.displayName || value}
                  {/* displayName이 있으면 그것을 사용, 그렇지 않으면 기존 값 사용 */}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
