import React from "react";
import InputCom from "../../../Helpers/InputCom";

export default function InvoiceInformation({
  selectedInvoices,
  paymentInfoTmp,
  setPaymentInfoTmp,
  warnings,
}) {
  const handleInputChange = (field, value) => {
    setPaymentInfoTmp({
      ...paymentInfoTmp,
      [field]: value,
    });
  };

  return (
    <>
      <div
        className="flex space-x-3 items-center "
        style={{
          marginBottom: "20px",
          borderBottom: "1px solid lightgray",
        }}
      >
        <span
          className="text-2xl font-bold payment-subtitle"
          style={{ color: "#000000" }}
        >
          Invoice Information
        </span>
      </div>
      <div
        className="input-item mb-8"
        style={{
          marginBottom: "20px",
          border: "1px solid lightgray",
        }}
      >
        <table className="min-w-full bg-white text-xs">
          <thead>
            <tr style={{ backgroundColor: "#f0f0f0" }}>
              <th className="py-2 px-4 border w-1/3">Invoice#</th>
              <th className="py-2 px-4 border w-1/3">Due Amount</th>
              <th className="py-2 px-4 border w-1/3">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {selectedInvoices.map((row, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border">{row.num}</td>
                <td className="py-2 px-4 border">{row.balanceDue}</td>
                <td className="py-2 px-4 border">{row.totalAmount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
