import {
  GET_SHIPINFO_REQUEST,
  GET_SHIPINFO_SUCCESS,
  GET_SHIPINFO_FAIL,
  PATCH_DEFAULTSHIP_REQUEST,
  PATCH_DEFAULTSHIP_SUCCESS,
  PATCH_DEFAULTSHIP_FAIL,
  PATCH_DEFAULTSERVICE_SUCCESS,
  PATCH_DEFAULTSERVICE_FAIL,
  GET_CHECKPO_REQUEST,
  GET_CHECKPO_FAIL,
  GET_CHECKPO_SUCCESS,
  GET_CHECKOUT_REQUEST,
  GET_CHECKOUT_FAIL,
  GET_CHECKOUT_SUCCESS,
  PATCH_DEFAULTSERVICE_REQUEST,
  GET_CONFIRMATION_REQUEST,
  GET_CONFIRMATION_SUCCESS,
  GET_CONFIRMATION_FAIL,
} from "../constants/checkoutConstants";

// eslint-disable-next-line import/prefer-default-export
export const getShippingInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SHIPINFO_REQUEST:
      return { loading: true };
    case GET_SHIPINFO_SUCCESS:
      return { loading: false, order: action.payload };
    case GET_SHIPINFO_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateDefaultShipToAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case PATCH_DEFAULTSHIP_REQUEST:
      return { loading: true };
    case PATCH_DEFAULTSHIP_SUCCESS:
      return { loading: false };
    case PATCH_DEFAULTSHIP_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateDefaultCarrierServiceReducer = (state = {}, action) => {
  switch (action.type) {
    case PATCH_DEFAULTSERVICE_REQUEST:
      return { loading: true };
    case PATCH_DEFAULTSERVICE_SUCCESS:
      return { loading: false };
    case PATCH_DEFAULTSERVICE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const checkDuplicatedPoNumberReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CHECKPO_REQUEST:
      return { loading: true };
    case GET_CHECKPO_SUCCESS:
      return { loading: false };
    case GET_CHECKPO_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const checkoutReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CHECKOUT_REQUEST:
      return { loading: true };
    case GET_CHECKOUT_SUCCESS:
      return { loading: false };
    case GET_CHECKOUT_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getOrderConfirmationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CONFIRMATION_REQUEST:
      return { loading: true };
    case GET_CONFIRMATION_SUCCESS:
      return { loading: false, orderInfo: action.payload };
    case GET_CONFIRMATION_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
