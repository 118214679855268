export const GET_MYACCOUNT_REQUEST = "GET_MYACCOUNT_REQUEST";
export const GET_MYACCOUNT_SUCCESS = "GET_MYACCOUNT_SUCCESS";
export const GET_MYACCOUNT_FAIL = "GET_MYACCOUNT_FAIL";

export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";
export const USER_SIGNOUT = "USER_SIGNOUT";

export const TOKEN_REQUEST = "TOKEN_REQUEST";
export const TOKEN_FAIL = "TOKEN_FAIL";

export const TOKEN_REQUEST2 = "TOKEN_REQUEST2";
export const TOKEN_FAIL2 = "TOKEN_FAIL2";

export const PUT_PASSWORDCHANGE_REQUEST = "GET_MYACCOUNT_REQUEST";
export const PUT_PASSWORDCHANGE_SUCCESS = "GET_MYACCOUNT_SUCCESS";
export const PUT_PASSWORDCHANGE_FAIL = "GET_MYACCOUNT_FAIL";
