export const GET_ROLE_REQUEST = "GET_ROLE_REQUEST";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAIL = "GET_ROLE_FAIL";

export const POST_ROLE_REQUEST = "POST_ROLE_REQUEST";
export const POST_ROLE_SUCCESS = "POST_ROLE_SUCCESS";
export const POST_ROLE_FAIL = "POST_ROLE_FAIL";

export const EDIT_ROLE_REQUEST = "EDIT_ROLE_REQUEST";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const EDIT_ROLE_FAIL = "EDIT_ROLE_FAIL";

export const GET_CHECKROLENAME_REQUEST = "GET_CHECKROLENAME_REQUEST";
export const GET_CHECKROLENAME_SUCCESS = "GET_CHECKROLENAME_SUCCESS";
export const GET_CHECKROLENAME_FAIL = "GET_CHECKROLENAME_FAIL";

export const GET_PERMISSIONS_REQUEST = "GET_PERMISSIONS_REQUEST";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAIL = "GET_PERMISSIONS_FAIL";
