import AOS from "aos";
import "aos/dist/aos.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import App from "./App";
import "./index.css";
import store from "./store";

AOS.init();

const root = document.getElementById("root");
ReactDOM.createRoot(root).render(
  <Provider store={store}>
    <BrowserRouter>
      <Helmet>
        <title>Maevn Uniforms</title>
        <link rel="icon" href="../public/MaevnUniforms.ico" />
      </Helmet>
      <App />
    </BrowserRouter>
  </Provider>
);

// import AOS from "aos";
// import "aos/dist/aos.css";
// import ReactDOM from "react-dom/client";
// import { BrowserRouter } from "react-router-dom";
// import { Provider } from "react-redux";
// import App from "./App";
// import "./index.css";
// import store from "./store";

// AOS.init();

// const root = document.getElementById("root");
// ReactDOM.createRoot(root).render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </Provider>
// );
