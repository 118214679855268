import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import styled from "@emotion/styled";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import InputQuantityCom from "../../../Helpers/InputQuantityCom";
import {
  getCart,
  removeItem,
  updateCart,
} from "../../../../actions/cartAction";

const ItemSection = ({
  setSectionRef,
  updateLocalItems,
  cartItemsArray,
  setCartItemsArray,
  totalNumberOfItems,
  setTotalNumberOfItems,
  // setTotalPrice,
  setDiscountedTotalPrice,
  setSavedTotal,
  onItemRemoved,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [quantity, setQuantity] = useState({});

  // alert for backorder
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  // calculating price
  const [subTotal, setSubTotal] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [subSavedMoney, setSubSavedMoney] = useState({});
  const [totalSavedMoney, setTotalSavedMoney] = useState(0);

  const [userId, setUserId] = useState();
  const [Pid, setPid] = useState();
  const [parentId, setParentId] = useState();

  // Qty input box
  const handleQuantityChange = (itemId, newQuantity) => {
    setQuantity((prevState) => ({
      ...prevState,
      [itemId]: newQuantity,
    }));
  };

  // SubTotal Price with discount
  const calculateSubTotal = (unitPrice, discountRate, quantity) => {
    const discountPrice = unitPrice * (1 + discountRate / 100);
    const roundedDiscountPrice = parseFloat(discountPrice);
    const subTotal = roundedDiscountPrice * quantity;
    return parseFloat(subTotal.toFixed(2));
  };

  // Saved Money
  const calculateSavedMoneyPerItem = (
    calculatedSubTotal, //with discounted rate
    unitPrice,
    currentQty
  ) => {
    const unitSubTotal = unitPrice * currentQty;
    const unitSavedMoney = unitSubTotal - calculatedSubTotal;

    return parseFloat(unitSavedMoney.toFixed(2));
  };

  // Total Price
  const calculateTotalPrice = () => {
    const totalPrice = cartItemsArray.reduce((accumulator, item) => {
      return accumulator + (subTotal[item.id] || item.discountedSubTotal);
    }, 0);

    const totalSavedPrice = cartItemsArray.reduce((accumulator, item) => {
      const priceDifference = item.subTotal - item.discountedSubTotal;

      return accumulator + (subSavedMoney[item.id] || priceDifference);
    }, 0);

    setTotalSavedMoney(totalSavedPrice);
    setTotalPrice(totalPrice);
  };

  // Calculate Total Price whenever artItemsArray or subTotal happen event
  useEffect(() => {
    calculateTotalPrice();
  }, [cartItemsArray, subTotal]);

  // Update quantity
  const handleUpdateQty = async (
    userId,
    productId,
    itemId,
    selectedQty,
    availableQuantity,
    //inputQuantity,
    unitPrice,
    discountedItemPrice,
    parentId,
    discountRate
  ) => {
    // Avoid updating if quantity is not set
    const updatedQuantity = quantity[itemId];
    if (!updatedQuantity) return;

    // Print parameters
    console.log("selectedQty : ", selectedQty);
    console.log("availableQuantity : ", availableQuantity);
    console.log("updatedQuantity : ", updatedQuantity);

    // Backorder modal
    const inputQuantity = parseInt(updatedQuantity, 10);
    if (inputQuantity > availableQuantity || selectedQty > availableQuantity) {
      setDialogMessage(
        "You can place backorders for any remaining quantities that exceed the current orderable quantity. If you prefer not to place a backorder, kindly adjust the quantity of the item in your order to match the currently available stock."
      );
      setDialogOpen(true);
    }

    // States for prices
    // setQuantity(inputQuantity);
    // setUserId(userId);
    // setPid(productId);
    // setParentId(parentId);

    // Calculate the new subtotal
    const calculatedSubTotal = calculateSubTotal(
      unitPrice,
      discountRate,
      inputQuantity
    );

    // Calculate saved money per item
    const calculatedSavedMoneyPerItem = calculateSavedMoneyPerItem(
      calculatedSubTotal,
      unitPrice,
      inputQuantity
    );

    // Update new saved money
    setSubSavedMoney((prevSubSavedMoney) => ({
      ...prevSubSavedMoney,
      [itemId]: calculatedSavedMoneyPerItem,
    }));

    // Update subtotal price with discunted rate
    setSubTotal((prevSubTotal) => ({
      ...prevSubTotal,
      [itemId]: calculatedSubTotal,
    }));

    // Perform the cart update operation
    try {
      await dispatch(
        updateCart({
          UserId: userId,
          Pid: productId,
          Quantity: inputQuantity,
          ParentId: parentId,
        })
      );
      const updatedCartResponse = await dispatch(getCart(userId));
      const updatedCartItems = updatedCartResponse.payload.data.result;
      updateLocalItems(updatedCartItems);
    } catch (error) {
      console.error("Failed to update cart item quantity:", error);
    }
  };

  // Backorder alert when change qty
  const StyledDialogContent = styled(DialogContent)`
    text-align: center;
    padding: 30px;
  `;

  const StyledDialogActions = styled(DialogActions)`
    display: flex;
    justify-content: space-evenly;
    padding: 15px;
  `;

  // Delete item
  const handleRemoveItem = async (userId, productId, parentId) => {
    try {
      await dispatch(
        removeItem({ UserId: userId, ProductId: productId, ParentId: parentId })
      );

      const updatedCartResponse = await dispatch(getCart(userId));
      const updatedCartItems = updatedCartResponse.payload.data.result;
      updateLocalItems(updatedCartItems);

      onItemRemoved(productId);
    } catch (error) {
      console.error("Failed to remove cart item:", error);
    }
  };

  // Move to other page
  const goBackProductPage = (collection, styleNumber, selectedColor, fit) => {
    // Convert selectedColor to uppercase
    const uppercaseColor = selectedColor.toUpperCase();

    // Use the uppercaseColor in the navigate function
    navigate(`/${collection}/${styleNumber}/${uppercaseColor}/${fit}`);
  };

  return (
    <div ref={setSectionRef(3)} className="section">
      <h2 className="section-title">
        4. Items in Order ({totalNumberOfItems})
      </h2>
      <div className=" ">
        {/* only for table */}
        <div className="relative w-full h-[400px] sm:h-[600px] md:h-[800px] overflow-x-auto overflow-y-auto border border-[#EDEDED]">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
            <tbody>
              {/* table heading */}
              <tr className="text-[12px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
                <td className="py-1 px-9 whitespace-nowrap text-center">
                  Product
                </td>
                <td className="py-1 px-4 whitespace-nowrap text-center">
                  Price
                </td>
                <td className="py-1 px-8 whitespace-nowrap  text-center">
                  Qty
                </td>
                <td className="py-1 px-4 whitespace-nowrap  text-center">
                  Sub Total
                </td>
                <td className="py-1 px-4 whitespace-nowrap  text-center">
                  Username
                </td>
                <td className="py-1 px-4 whitespace-nowrap  text-center">
                  Admin
                </td>
                <td className="py-1 px-4 whitespace-nowrap  text-center">
                  Remove
                </td>
              </tr>

              {/* table heading end */}
              {cartItemsArray &&
                cartItemsArray.map((item) => (
                  <tr
                    key={item.id}
                    className="bg-white border-b hover:bg-gray-50"
                  >
                    <td className="pl-10 py-2 w-[380px]">
                      <div className="flex space-x-6 items-center">
                        <div className="w-[50px] h-[50px] overflow-hidden flex justify-center items-center border border-[#EDEDED]">
                          <img
                            src={`https://maevn-images.s3.us-east-2.amazonaws.com/110x110/${item.productLine.replace(
                              /\s+/g,
                              ""
                            )}/${item.styleNumber}${item.colorCode}.jpg`}
                            alt="product"
                            className="w-full h-full object-contain"
                            onError={(event) => {
                              event.target.onerror = null; // prevent infinite error loop
                              event.target.src = `https://maevn-images.s3.us-east-2.amazonaws.com/swatches/NoImage.jpg`; // set fallback image
                            }}
                          />
                        </div>
                        <div
                          className="flex-1 flex flex-col"
                          onClick={() =>
                            goBackProductPage(
                              item.productLine,
                              item.styleNumber,
                              item.colorCode,
                              item.fit
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <p className="text-[13px] text-qblack">
                            Style No. {item.styleNumber}
                          </p>
                          <span className="text-[13px] text-qblack">
                            Color: {item.color}, Size: {item.size}, Fit:
                            {item.fit} &nbsp;&nbsp;
                            {item.isPreorder ? (
                              <span className="text-qred">Pre-Order!</span>
                            ) : null}
                          </span>
                        </div>
                      </div>
                    </td>

                    {/* Item price with discount */}
                    <td className="text-center py-2 px-2">
                      <div className="flex space-x-1 items-center justify-center">
                        {item.discountRate < 0 ? (
                          <>
                            <span className="text-[15px] font-normal line-through text-gray-500 mr-2">
                              ${item.itemPrice}
                            </span>
                            <span className="text-[15px] font-normal text-blue-600">
                              ${item.discountedItemPrice}
                            </span>
                          </>
                        ) : (
                          <span className="text-[15px] font-normal">
                            ${item.itemPrice}
                          </span>
                        )}
                      </div>
                    </td>

                    {/* Change item Qty */}
                    <td className="text-center py-2 px-2">
                      <div className="flex flex-col justify-center items-center">
                        <InputQuantityCom
                          key={item.id}
                          itemId={item.id}
                          quantity={item.qty}
                          availableQuantity={item.availableQty}
                          onQuantityChange={handleQuantityChange} // This updates `New quantity` state in parent component
                        />

                        <button
                          type="button"
                          className="text-xs font-semibold text-blue-600 mt-2 "
                          onClick={() =>
                            handleUpdateQty(
                              item.userId,
                              item.pid,
                              item.id,
                              item.qty,
                              item.availableQty, // Inventory
                              // quantity, // This now refers to the potentially updated quantity
                              item.itemPrice,
                              item.discountedItemPrice,
                              item.parentAccountId,
                              item.discountRate
                            )
                          }
                        >
                          Update Qty
                        </button>
                      </div>
                    </td>

                    <td className="text-center py-2 px-2">
                      <div className="flex space-x-1 items-center justify-center">
                        <span className="text-[13px] font-normal">
                          {/*} ${item.subTotal} */}$
                          {subTotal[item.id] || item.discountedSubTotal}
                        </span>
                      </div>
                    </td>

                    <td className="text-center py-2 px-2">
                      <div className="flex space-x-1 items-center justify-center">
                        <span className="text-[13px] font-normal text-red-500">
                          {item.userName}
                        </span>
                      </div>
                    </td>

                    <td className="text-right py-2 px-2">
                      <div className="flex space-x-1 items-center justify-center">
                        <span className="text-[13px] font-normal">
                          {item.parentAccountLoginId}
                        </span>
                      </div>
                    </td>

                    {/*Item remove button */}
                    <td className="text-right py-2 px-2">
                      <div className="flex space-x-1 items-center justify-center">
                        <button
                          type="button"
                          onClick={() =>
                            handleRemoveItem(
                              item.userId,
                              item.pid,
                              item.parentAccountId
                            )
                          }
                        >
                          <svg
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                              fill="#AAAAAA"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>

            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="xs" // Limit the width of the dialog
              fullWidth
              style={{
                display: "inline-flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: "30px",
                gap: "10px",
                borderRadius: "10px",
                background: "var(--Base-White, #FFF)",
                margin: "30px", // 모달창의 바깥 간격
              }}
            >
              <StyledDialogContent
                style={{ paddingTop: "0", paddingBottom: "5" }}
              >
                <DialogContentText id="alert-dialog-description">
                  {dialogMessage}
                </DialogContentText>
              </StyledDialogContent>
              <StyledDialogActions
                style={{ marginTop: "30", justifyContent: "flex-end" }}
              >
                <button
                  type="button"
                  className="black-btn text-sm font-semibold w-1/4 h-10"
                  onClick={() => setDialogOpen(false)}
                >
                  Confirm
                </button>
              </StyledDialogActions>
            </Dialog>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ItemSection;
