import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { signout } from "../../../../actions/userAuthAction";
import Arrow from "../../../Helpers/icons/Arrow";
//import Selectbox from "../../../Helpers/Selectbox";
import SelectboxHeader from "../../../Helpers/SelectboxHeader";

export default function TopBar({ className }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [screenSize, setScreenSize] = useState(0);
  const isMyAccountPage = location.pathname.includes("/myaccount");

  /*
  delete cookie after click logout botton
  if user is logined, logout button need to be shown
  if user is not logined, login button need to be shown
  */
  const signOutHandler = () => {
    dispatch(signout());
  };

  //validate user
  const { search } = useLocation(); //search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login"; // check if redirect url exist

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;

  //username
  const username = userInfo?.result?.user?.loginId;

  // shoppingCart permission
  const shoppingCartPermission =
    userInfo?.result?.user?.permissions?.ShoppingCart;

  const defaultItems = ["My Account", "Profile", "Adresses", "Contact us"];
  const conditionalItems = [
    { key: "Order History", url: "/myaccount#order" },
    // { key: "WishList", url: "/myaccount#wishlist" },
    { key: "Invoice", url: "/myaccount#invoice" },
    { key: "Statement", url: "/myaccount#statement" },
    { key: "Customer Roles", url: "/customerroles" },
    { key: "Customer User", url: "/customerusers" },
  ];

  const dropdownItems = defaultItems.concat(
    conditionalItems
      .filter((item) => userInfo?.result?.user?.permissions[item.key] === 1)
      .map((item) => item.key)
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Set initial size
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  /*
  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");

      //redirect to login page after delete cookie
      navigate(redirect);
    } else {
      console.log(
        "userInfo.result.user.permissions.UserRoles : ",
        userInfo.result.user.permissions.UserRoles
      );
    }
  }, [dispatch, navigate, redirect, userInfo]);
*/
  return (
    <>
      <div
        className={`w-full bg-white h-10 border-b border-qgray-border ${
          className || ""
        }`}
      >
        <div className="container-x mx-auto h-full">
          <div className="flex justify-between items-center h-full">
            <div className="topbar-nav">
              {/* 
              <ul className="flex space-x-6">
              <li> 
                  <Link to="/">
                    <span className="text-xs leading-6 text-qblack font-500">
                      Account
                    </span>
                  </Link> 
                </li>*/}
              {/*  
                <li> 
                  <Link to="/tracking-order">
                    <span className="text-xs leading-6 text-qblack font-500">
                      Track Order
                    </span>
                  </Link>
                </li> 
                */}
              {/*<li> 
                  <Link to="/faq">
                    <span className="text-xs leading-6 text-qblack font-500">
                      Support
                    </span>
                    </Link>
                </li>  
              </ul> 
                 */}
            </div>
            {/*   <div className="topbar-dropdowns sm:block hidden">*/}
            {/* <div className="topbar-dropdowns sm:block "> */}
            <div className="topbar-dropdowns flex justify-end sm:justify-end w-full">
              <div className="flex leading-tight space-x-3 lg:space-x-6">
                <div className="logout-select flex space-x-1 items-center">
                  <div>
                    <Link to="/">
                      <span className="text-xs leading-6 text-qblack font-500">
                        Home
                      </span>
                    </Link>
                  </div>
                </div>

                {/* Import Order on the header */}
                {/* <div className="logout-select flex space-x-1 items-center">
                  <div>
                    <Link to="/bulkorder">
                      <span className="text-xs leading-6 text-qblack font-500">
                        Import Order
                      </span>
                    </Link>
                  </div>
                </div>
                */}

                {/* if user is logined, button need to be shown as logout */}
                {/*
                <div className="logout-select flex space-x-1 items-center">
                  {userInfo ? (
                    <div>
                      <Link to="/Login" onClick={signOutHandler}>
                        <span className="text-xs leading-6 text-qblack font-500">
                          Logout
                        </span>
                      </Link>
                    </div>
                  ) : (
                    <div>
                      <Link to="/Login" onClick={signOutHandler}>
                        <span className="text-xs leading-6 text-qblack font-500">
                          Login
                        </span>
                      </Link>
                    </div>
                  )}
                </div>
                */}
                {/* {screenSize > 768 && */}
                {/*{shoppingCartPermission === 1 && (
                  <div className="logout-select flex space-x-1 items-center ">
                    <div>
                      <Link to="/quickorder">
                        <span className="text-xs leading-6 text-qblack font-500 whitespace-nowrap">
                          Quick Order
                        </span>
                      </Link>
                    </div>
                  </div>
                )}*/}
                <div className="country-select flex space-x-1 items-center ">
                  <SelectboxHeader
                    className="w-fit"
                    datas={dropdownItems}
                    userInfo={userInfo}
                  />
                  <Arrow className="fill-current qblack" />
                </div>

                <div className="logout-select flex space-x-1 items-center">
                  <div>
                    <span className="text-xs leading-tight text-qblack font-500">
                      Welcome, {username}
                    </span>
                  </div>
                </div>

                {/*
                <div className="country-select flex space-x-1 items-center">
                  <Selectbox
                    className="w-fit"
                    datas={["United States", "Bangladesh", "India"]}
                  />
                  <Arrow className="fill-current qblack" />
                </div>
                */}
                {/*
                <div className="currency-select flex space-x-1 items-center">
                  <Selectbox className="w-fit" datas={["USD", "BDT"]} />
                  <Arrow className="fill-current qblack" />
                </div>
               */}
                {/*
                <div className="language-select flex space-x-1 items-center">
                  <Selectbox className="w-fit" datas={["Bangla", "english"]} />
                  <Arrow className="fill-current qblack" />
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
