import Axios from "axios";
import {
  GET_INVOICEHEADER_REQUEST,
  GET_INVOICEHEADER_SUCCESS,
  GET_INVOICEHEADER_FAIL,
  GET_INVOICELINE_REQUEST,
  GET_INVOICELINE_SUCCESS,
  GET_INVOICELINE_FAIL,
  GET_INVOICEPDF_REQUEST,
  GET_INVOICEPDF_SUCCESS,
  GET_INVOICEPDF_FAIL,
} from "../constants/invoiceConstants";

// eslint-disable-next-line import/prefer-default-export
export const getInvoiceHeaderReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_INVOICEHEADER_REQUEST:
      return { loading: true };
    case GET_INVOICEHEADER_SUCCESS:
      return { loading: false, invoiceHeader: action.payload };
    case GET_INVOICEHEADER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getInvoiceLineReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_INVOICELINE_REQUEST:
      return { loading: true };
    case GET_INVOICELINE_SUCCESS:
      return { loading: false, invoice: action.payload };
    case GET_INVOICELINE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const exportInvoicePdfReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_INVOICEPDF_REQUEST:
      return { loading: true };
    case GET_INVOICEPDF_SUCCESS:
      return { loading: false, invoicePdfInfo: action.payload };
    case GET_INVOICEPDF_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
