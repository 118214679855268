import {
  POST_EMAILREQUEST_FAIL,
  POST_EMAILREQUEST_REQUEST,
  POST_EMAILREQUEST_SUCCESS,
  POST_PASSWORDREQUEST_FAIL,
  POST_PASSWORDREQUEST_REQUEST,
  POST_PASSWORDREQUEST_SUCCESS,
} from "../constants/passwordResetConstants";

// eslint-disable-next-line import/prefer-default-export
export const postemailrequestReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_EMAILREQUEST_REQUEST:
      return { loading: true, error: null };
    case POST_EMAILREQUEST_SUCCESS:
      return {
        loading: false,
        result: action.payload,
      };
    case POST_EMAILREQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const confirmResetpasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_PASSWORDREQUEST_REQUEST:
      return { loading: true, error: null };
    case POST_PASSWORDREQUEST_SUCCESS:
      return {
        loading: false,
        result: action.payload,
      };
    case POST_PASSWORDREQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
