import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCart,
  updateCart,
  removeItem,
  clearCart,
} from "../../actions/cartAction";
import InputQuantityCom from "../Helpers/InputQuantityCom";
import QuickOrderButton from "./QuickOrderButton";
import QuickOrderForm from "./QuickOrderForm";
import "./QuickOrder.css";
import TertiaryButton2 from "../Helpers/Buttons/TertiaryButton2";
import PrimaryButton from "../Helpers/Buttons/PrimaryButton";
import SecondaryButton from "../Helpers/Buttons/SecondaryButton";

export default function ProductsTable({
  className,
  cartItems,
  onItemRemoved,
  placeOrderPermission,
  updateLocalItems,
}) {
  const [quantity, setQuantity] = useState();
  const [userId, setUserId] = useState();
  const [Pid, setPid] = useState();
  const [parentId, setParentId] = useState();
  const [subTotal, setSubTotal] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [subSavedMoney, setSubSavedMoney] = useState({});
  const [totalSavedMoney, setTotalSavedMoney] = useState(0);

  const cartItemsArray = cartItems || [];
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(0);

  // Total number of items in cart
  useEffect(() => {
    const sumQty = cartItemsArray.reduce(
      (acc, currItem) => acc + currItem.qty,
      0
    );
    setTotalNumberOfItems(sumQty);
  }, [cartItemsArray]);

  // Get Basket
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Calculate sub total
  const calculateSubTotal = (unitPrice, discountRate, quantity) => {
    const discountPrice = unitPrice * (1 + discountRate / 100);
    const roundedDiscountPrice = parseFloat(discountPrice);
    const subTotal = roundedDiscountPrice * quantity;
    return parseFloat(subTotal.toFixed(2));
  };

  // Calculate saved money
  const calculateSavedMoneyPerItem = (
    calculatedSubTotal, //with discounted rate
    unitPrice,
    currentQty
  ) => {
    const unitSubTotal = unitPrice * currentQty;
    const unitSavedMoney = unitSubTotal - calculatedSubTotal;

    console.log("unitSavedMoney : ", unitSavedMoney);
    return parseFloat(unitSavedMoney.toFixed(2));
  };

  // Calculate total money
  useEffect(() => {
    calculateTotalPrice();
  }, [cartItemsArray, subTotal]);

  const calculateTotalPrice = () => {
    let totalPrice = cartItemsArray.reduce((accumulator, item) => {
      return accumulator + (subTotal[item.id] || item.discountedSubTotal);
    }, 0);

    let totalSavedPrice = cartItemsArray.reduce((accumulator, item) => {
      const priceDifference = item.subTotal - item.discountedSubTotal;
      return accumulator + (subSavedMoney[item.id] || priceDifference);
      //return accumulator + (priceDifference > 0 ? priceDifference : 0);
    }, 0);

    setTotalSavedMoney(totalSavedPrice);
    setTotalPrice(totalPrice);
  };

  // Update quantity
  const handleQuantityChange = (itemId, newQuantity) => {
    console.log("newQuantity", newQuantity);
    setQuantity(newQuantity);
  };

  const handleUpdateQty = (
    userid,
    productid,
    itemid,
    currentQty,
    unitPrice,
    discountedItemPrice,
    parentid,
    discountRate
  ) => {
    setUserId(userid);
    setPid(productid);
    setParentId(parentid);

    const calculatedSubTotal = calculateSubTotal(
      unitPrice,
      discountRate,
      currentQty
    );

    const calculatedSavedMoneyPerItem = calculateSavedMoneyPerItem(
      calculatedSubTotal,
      unitPrice,
      currentQty
    );

    setSubSavedMoney((prevSubSavedMoney) => ({
      ...prevSubSavedMoney,
      [itemid]: calculatedSavedMoneyPerItem,
    }));

    setSubTotal((prevSubTotal) => ({
      //with discunted rate
      ...prevSubTotal,
      [itemid]: calculatedSubTotal,
    }));

    console.log("cartItemsArray", cartItemsArray);

    if ((userid && productid && quantity && parentid) || parentid === 0) {
      dispatch(
        updateCart({
          UserId: userid,
          Pid: productid,
          Quantity: currentQty,
          ParentId: parentid,
        })
      ).then(() => {
        dispatch(getCart(userid))
          .then((updatedCartResponse) => {
            // And assuming `updateLocalItems` is a prop function passed down to update parent's state
            const updatedCartItems = updatedCartResponse.data.result; // Adjust based on actual response structure
            updateLocalItems(updatedCartItems);
          })
          .catch((error) => {
            console.error("Failed to update cart item quantity:", error);
          });
      });
    }
  };

  // Delete item
  const handleRemoveItem = async (userid, productid, parentid) => {
    try {
      await dispatch(
        removeItem({ UserId: userid, ProductId: productid, ParentId: parentid })
      );

      const updatedCartResponse = await dispatch(getCart(userid));
      const updatedCartItems = updatedCartResponse.payload.data.result;
      updateLocalItems(updatedCartItems);

      onItemRemoved(productid);
    } catch (error) {
      console.error("Failed to remove cart item:", error);
    }

    /*setUserId(userid);
    setPid(productid);
    setParentId(parentid);
    dispatch(
      removeItem({ UserId: userid, ProductId: productid, ParentId: parentid })
    );

    onItemRemoved(productid);*/
  };

  // Move to product detail page
  const goBackProductPage = (collection, styleNumber, selectedColor, fit) => {
    navigate(`/${collection}/${styleNumber}/${selectedColor}/${fit}`);
  };

  // Continue shopping
  const goToShopping = () => {
    // navigate(-1);
    navigate("/");
  };

  // Go to checkout
  const goToCheckout = () => {
    navigate("/checkout");
  };

  // Clear cart
  const handleClearCart = () => {
    dispatch(clearCart());
  };

  // Quick order form
  const [modalShow, setModalShow] = useState(false);

  const handleQuickOrderFormClick = () => {
    setModalShow(true);
  };

  const handleCloseQuickOrderFormClick = (shouldUpdate) => {
    setModalShow(false);

    // call cart again
    if (shouldUpdate) {
      dispatch(getCart(userId));
    }
  };

  return (
    <div className={`relative w-full ${className || ""}`}>
      {/* Quick order form button */}
      <QuickOrderButton onClick={handleQuickOrderFormClick} />
      <QuickOrderForm
        userId={userId}
        show={modalShow}
        onClose={handleCloseQuickOrderFormClick}
      />

      {/* Shopping cart */}
      <div className="w-full h-[100px] py-10">
        <div className="container-x mx-auto">
          <div className="flex items-center">
            <h1 className="shopping-cart-title">
              Shopping Cart - {totalNumberOfItems} item(s)
            </h1>
          </div>
        </div>
      </div>
      <div className="relative w-full overflow-x-auto border border-[#EDEDED]">
        <table className="w-full text-left text-gray-500 dark:text-gray-400 ">
          <tbody>
            {/* table heading */}
            <tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
              <td
                className="py-4 pl-10 block whitespace-nowrap min-w-[450px] "
                style={{ verticalAlign: "middle" }}
              >
                Product
              </td>
              {/* <td className="py-4 whitespace-nowrap text-center">Color</td> */}
              {/* <td className="py-4 whitespace-nowrap text-center">Size</td> */}
              <td className="py-4 whitespace-nowrap text-center w-[120px]">
                Price
              </td>
              <td className="py-4 whitespace-nowrap  text-center w-[120px]">
                Quantity
              </td>
              <td className="py-4 whitespace-nowrap  text-center w-[120px]">
                Sub Total
              </td>
              <td className="py-4 whitespace-nowrap  text-center w-[120px]">
                Username
              </td>
              <td className="py-4 whitespace-nowrap  text-center w-[120px]">
                Admin
              </td>
              <td className="py-4 whitespace-nowrap text-right w-[80px]"></td>
            </tr>
            {/* table heading end */}
            {cartItemsArray &&
              cartItemsArray.map((item) => (
                <tr
                  key={item.id}
                  className="bg-white border-b hover:bg-gray-50 Table-content-font"
                >
                  <td className="pl-10 py-4 w-[380px]">
                    <div className="flex space-x-6 items-center">
                      <div className="w-[80px] h-[80px] overflow-hidden flex justify-center items-center border border-[#EDEDED]">
                        <img
                          src={`https://maevn-images.s3.us-east-2.amazonaws.com/110x110/${item.productLine.replace(
                            /\s+/g,
                            ""
                          )}/${item.styleNumber}${item.colorCode}.jpg`}
                          alt="product"
                          className="w-full h-full object-contain"
                          onError={(event) => {
                            event.target.onerror = null; // prevent infinite error loop
                            event.target.src = `https://maevn-images.s3.us-east-2.amazonaws.com/swatches/NoImage.jpg`; // set fallback image
                          }}
                        />
                      </div>
                      <div
                        className="flex-1 flex flex-col"
                        onClick={() =>
                          goBackProductPage(
                            item.productLine,
                            item.styleNumber,
                            item.colorCode,
                            item.fit
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <p className="font-medium text-[15px] text-qblack">
                          Collection: {item.productLine}, Style Number:{" "}
                          {item.styleNumber}
                        </p>
                        <span className="font-medium text-[15px] text-qblack">
                          Fit : {item.fit} &nbsp;&nbsp;
                          {item.isPreorder ? (
                            <span className="text-qred">Pre-Order!</span>
                          ) : null}
                        </span>
                        <span className="text-[15px] font-normal">
                          Color: {item.color} &nbsp;|&nbsp;Size: {item.size}
                        </span>
                      </div>
                    </div>
                  </td>
                  {/* <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal">
                        {item.color}
                      </span>
                    </div>
                          </td> 
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal">
                        {item.size}
                      </span>
                    </div>
                  </td>
                  {/*Item price with discount*/}
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      {item.discountRate < 0 ? (
                        <>
                          <span className="text-[15px] font-normal line-through text-gray-500 mr-2">
                            ${item.itemPrice}
                          </span>
                          <span className="text-[20px] font-normal text-blue-600">
                            ${item.discountedItemPrice}
                          </span>
                        </>
                      ) : (
                        <span className="text-[15px] font-normal">
                          ${item.itemPrice}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="py-4">
                    <div className="flex flex-col justify-center items-center">
                      <InputQuantityCom
                        key={item.id} // Add a key prop based on the item's id
                        itemId={item.id} // Pass the item's id as a separate prop
                        quantity={item.qty}
                        onQuantityChange={handleQuantityChange}
                      />

                      <button
                        type="button"
                        className="text-xs font-semibold text-blue-600 mt-2"
                        onClick={() =>
                          handleUpdateQty(
                            item.userId,
                            item.pid,
                            item.id,
                            quantity,
                            item.itemPrice,
                            item.discountedItemPrice,
                            item.parentAccountId,
                            item.discountRate
                          )
                        }
                      >
                        Update Qty
                      </button>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal">
                        ${subTotal[item.id] || item.discountedSubTotal}
                        {/* pid 대신 유니크키로 바꿀것 */}
                      </span>
                      {/*<span className="text-[15px] font-normal">
                        {subTotal[item.pid] ||
                          (item.discountRate > 0
                            ? parseFloat(
                                (
                                  item.itemPrice *
                                  (1 - item.discountRate / 100) *
                                  item.qty
                                ).toFixed(2)
                              )
                            : item.subTotal)}
                      </span>*/}
                    </div>
                  </td>

                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal text-red-500">
                        {item.userName}
                      </span>
                    </div>
                  </td>
                  <td className="text-right py-4">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal">
                        {item.parentAccountLoginId}
                      </span>
                    </div>
                  </td>

                  {/*Item remove button */}
                  <td className="text-right py-4">
                    <div className="flex space-x-1 items-center justify-center">
                      <button
                        type="button"
                        onClick={() =>
                          handleRemoveItem(
                            item.userId,
                            item.pid,
                            item.parentAccountId
                          )
                        }
                      >
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                            fill="#AAAAAA"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="w-full mt-[23px]">
        {/* indentation between layout*/}
        <div className="w-full sm:flex justify-between">
          {/*  <div className="discount-code sm:w-[270px] w-full mb-5 sm:mb-0 h-[50px] flex">
             <div className="flex-1 h-full">
              <InputCom type="text" placeholder="Discount Code" />
            </div>
            <button type="button" className="w-[90px] h-[50px] black-btn">
              <span className="text-sm font-semibold">Apply</span>
            </button>
          </div> */}

          <div className="flex space-x-2.5 items-center"></div>
          <a href="/quickorder" onClick={() => handleClearCart()}>
            <div className="tertiaryButton2">
              <span className="text-sm font-semibold ">Clear All</span>
            </div>
          </a>
        </div>

        <div className="w-full mt-[30px] flex sm:justify-end">
          {placeOrderPermission === 1 && (
            <div className="sm:w-[370px] w-full border border-[#EDEDED] px-[30px] py-[26px] ">
              {/*
            <div className="sub-total mb-6">
              <div className=" flex justify-between mb-6">
                <p className="text-[15px] font-medium text-qblack">Subtotal</p>
                <p className="text-[15px] font-medium text-qred">$365</p>
              </div>
              <div className="w-full h-[1px] bg-[#EDEDED]"></div>
            </div>
            */}
              <div className="total mb-6">
                <div className=" flex justify-between">
                  <p className="text-[14px] font-medium text-qblack">
                    You saved
                  </p>
                  <p className="text-[14px] font-medium text-qred">
                    ${totalSavedMoney.toFixed(2)}
                  </p>
                </div>
                <div className=" flex justify-between">
                  <p className="text-[18px] font-medium text-qblack">Total</p>
                  <p className="text-[18px] font-medium text-qred">
                    ${totalPrice.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center">
                <PrimaryButton
                  width="300px"
                  height="50px"
                  fontSize="18px"
                  onClick={goToCheckout}
                  style={{ marginBottom: "10px" }}
                >
                  Go To Checkout
                </PrimaryButton>

                {/* <a
                href="/checkout"
                className="w-full h-[50px] black-btn flex justify-center items-center"
              >
                <span className="text-sm font-semibold">Secure Checkout</span>
              </a> */}

                <SecondaryButton
                  width="300px"
                  height="50px"
                  fontSize="18px"
                  onClick={goToShopping}
                >
                  Continue Shopping
                </SecondaryButton>

                {/* <div
                className="w-[220px] h-[50px] bg-[#F6F6F6] flex justify-center items-center cursor-pointer"
                onClick={goToShopping}
              >
                <span className="text-sm font-semibold">Continue Shopping</span>
            </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
