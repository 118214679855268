import React from "react";
import "font-awesome/css/font-awesome.min.css";

export default function MessageBox(props) {
  return (
    <div
      className={`alert alert-${props.variant || "info"} text-danger`}
      style={{
        color: "var(--Warning-Color---Red, #F44)",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        marginBottom: "10px", // Increase bottom margin to create more space
      }}
    >
      {props.children}
    </div>
  );
}
