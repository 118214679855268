import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InputCom from "../../Helpers/InputCom";
import MoneyToPay from "./Payment/MoneyToPay";
import BillInformation from "./Payment/BillInformation";
import CardInformation from "./Payment/CardInformation";
import InvoiceInformation from "./Payment/InvoiceInformation";
import UserInformation from "./Payment/UserInformation";
import {
  setSelectedInvoicesToRedux,
  setCreditBalanceToRedux,
  postAuthCreditCardPayment,
  postMaevnPayment,
  postPaymentConfirmRedux,
} from "../../../actions/paymentAction";
import { getMyAccount } from "../../../actions/userAuthAction";
import PrimaryButton from "../../Helpers/Buttons/PrimaryButton";
import SecondaryButton from "../../Helpers/Buttons/SecondaryButton";

export default function PaymentTab() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get selectedInvoices, creditBalance from redux
  const selectedInvoices = useSelector(
    (state) => state.setSelectedInvoicesToRedux.selectedInvoices
  );

  const creditBalance = useSelector(
    (state) => state.setCreditBalanceToRedux.creditBalance
  );

  // get companyName, contactEmail from redux
  const myAccountInfo = useSelector((state) => state.getMyAccount);
  const { loading, error, myAccount } = myAccountInfo;
  if (loading) {
    return <div>Loading...</div>;
  }

  // get payment result from redux
  const paymentProcessing = useSelector(
    (state) => state.postCreditCardPayment.processing
  );

  const paymentResult = useSelector(
    (state) => state.postCreditCardPayment.result
  );
  const errorMessage = useSelector(
    (state) => state.postCreditCardPayment.errorMessage
  );

  const companyName = myAccount?.result?.result?.profile?.companyName;
  const userName = myAccount?.result?.result?.profile?.loginId;
  const userId = myAccount?.result?.result?.profile?.userId;
  const contactEmail = myAccount?.result?.result?.profile?.contactEmail;

  const [paymentInfoTmp, setPaymentInfoTmp] = useState({
    userId: userId,
    balance: 0, // Credit Used From Your Balance (USD)
    subTotal: 0, // Subtotal Amount On Invoices (USD) Sum of Balance and Actual amount of paying with credit card
    total: 0, // Total Amount Paying (USD) == Actual amount of paying with credit card
    country: "",
    state: "",
    city: "",
    zipcode: "",
    address1: "",
    address2: "",
    cardHolderName: "",
    cardNumber: "",
    cardExpirationMonth: "",
    cardExpirationYear: "",
    cvv: "",
    selectedInvoice: selectedInvoices,
  });

  // Calculate the sum of subtotal, total for all selected invoices
  const [subtotalPlaceholder, setSubtotalPlaceholder] = useState(0);
  const [totalPlaceholder, setTotalPlaceholder] = useState(0);
  const [creditBalancePlaceHolder, setBalancePlaceHolder] = useState(0);

  // Calculate the sum of balance due for all selected invoices
  useEffect(() => {
    //If even one invoice is not selected, block access to the payment page
    if (selectedInvoices.length === 0) {
      alert("Please select the invoice you want to pay.");

      navigate("/myaccount#invoice");
    }

    // sum of due amount of money for selected invoices
    if (selectedInvoices && Array.isArray(selectedInvoices)) {
      const subtotal = selectedInvoices.reduce(
        (sum, invoice) => sum + (invoice.balanceDue || 0),
        0
      );
      setSubtotalPlaceholder(subtotal.toFixed(2));

      // Total amount of money to pay
      setTotalPlaceholder((prevTotal) => {
        const newTotal =
          parseFloat(prevTotal) + parseFloat(subtotal.toFixed(2));
        return newTotal;
      });

      // Credit balance
      setBalancePlaceHolder(creditBalance);
    }
  }, [selectedInvoices, creditBalance, navigate]);

  // Notes for selected invoices
  const invoicePlaceHolder =
    paymentInfoTmp.selectedInvoice && paymentInfoTmp.selectedInvoice.length > 0
      ? paymentInfoTmp.selectedInvoice.map((invoice) => invoice.num).join(", ")
      : "";

  const [warnings, setWarnings] = useState({});

  // Show warning message for empty required field
  const checkNullAndSetWarnings = (info) => {
    const newWarnings = {};
    for (const [key, value] of Object.entries(info)) {
      if (key === "address2") continue;
      if (key === "balance") continue;

      newWarnings[key] = !value ? "*This field is required." : "";
    }
    setWarnings(newWarnings);

    // Verify that all alerts are empty strings
    if (Object.values(newWarnings).every((warning) => !warning)) {
      return true; // all required fields are filled
    }
    return false; // Missing field
  };

  // Transfer expiration date type
  const createExpirationDate = (paymentInfoTmp) => {
    return `${paymentInfoTmp.cardExpirationYear}-${paymentInfoTmp.cardExpirationMonth}`;
  };

  console.log("userName:", userName);
  console.log("paymentInfoTmp.username:", paymentInfoTmp.username);

  // Store final bill information
  const transformPaymentInfo = (paymentInfoTmp, expirationDate) => {
    return {
      billAddress: {
        ...paymentInfoTmp,
      },
      creditCard: {
        cardHolderName: paymentInfoTmp.cardHolderName,
        cardNumber: paymentInfoTmp.cardNumber,
        expirationDate: expirationDate,
        cvv: paymentInfoTmp.cvv,
      },
      moneyToPay: paymentInfoTmp,
      selectedInvoice: paymentInfoTmp.selectedInvoice,
      username: paymentInfoTmp.username, // Add username here
    };
  };

  // Map updatedPaymentInfo to Authorize.net API call format
  const createAuthorizeAPIRequest = (
    updatedPaymentInfo,
    invoicePlaceHolder
  ) => {
    console.log("updatedPaymentInfo", updatedPaymentInfo);

    console.log("invoicePlaceHolder", invoicePlaceHolder);
    return {
      createTransactionRequest: {
        merchantAuthentication: {
          name: "7e7YQMmrr7L", // Actual merchant name
          transactionKey: "832LqS3pS3T2dDsE", // Actual transaction key

          //name: "5KP3u95bQpv", // Sandbox merchant name
          //transactionKey: "346HZ32z3fP4hTG2", // Sandbox transaction key
        },
        transactionRequest: {
          transactionType: "authCaptureTransaction",
          amount: updatedPaymentInfo.moneyToPay.total, // get total price from updatedPaymentInfo
          payment: {
            creditCard: {
              cardNumber: updatedPaymentInfo.creditCard.cardNumber,
              expirationDate: updatedPaymentInfo.creditCard.expirationDate,
              cardCode: updatedPaymentInfo.creditCard.cvv,
            },
          },
          customer: {
            id: updatedPaymentInfo.billAddress.userId,
          },
          billTo: {
            firstName: updatedPaymentInfo.billAddress.cardHolderName,
            lastName: "",
            address: updatedPaymentInfo.billAddress.address1,
            city: updatedPaymentInfo.billAddress.city,
            state: updatedPaymentInfo.billAddress.state,
            country: updatedPaymentInfo.billAddress.country,
          },

          userFields: {
            userField: [
              {
                name: "invoiceNumber",
                value: invoicePlaceHolder, // get selected invoice number from updatedPaymentInfo
              },
              {
                name: "creditAmount",
                value: updatedPaymentInfo.moneyToPay.balance, // get total price from updatedPaymentInfo
              },
              {
                name: "account#",
                value: userName, // get total price from updatedPaymentInfo
              },
            ],
          },
        },
      },
    };
  };

  // Map updatedPaymentInfo to Maevn API call format
  const createMaevnAPIRequest = (
    updatedPaymentInfo,
    invoicePlaceHolderTmp,
    payResult,
    transId,
    errorMsg
  ) => {
    return {
      userId: updatedPaymentInfo.billAddress.userId,
      creditUsed: updatedPaymentInfo.moneyToPay.balance,
      subTotal: updatedPaymentInfo.moneyToPay.subTotal,
      total: updatedPaymentInfo.moneyToPay.total,
      invoices: invoicePlaceHolderTmp,
      creditCard: updatedPaymentInfo.creditCard.cardNumber,
      cardHolderName: updatedPaymentInfo.billAddress.cardHolderName,
      transactionId: transId,
      // Credit card billing address
      country: updatedPaymentInfo.billAddress.country,
      state: updatedPaymentInfo.billAddress.state,
      city: updatedPaymentInfo.billAddress.city,
      zipcode: updatedPaymentInfo.billAddress.zipcode,
      address1: updatedPaymentInfo.billAddress.address1,
      address2: updatedPaymentInfo.billAddress.address2,
      paymentResult: payResult,
      errorMessage: errorMsg,
    };
  };

  const handleSubmitButtonClick = async () => {
    // For calling payment confirmation email depending on the Auth. transaction result
    let paymentResult = 0;

    // Ensure empty string values are treated as 0
    const paymentInfoWithDefaults = {
      ...paymentInfoTmp,
      balance: paymentInfoTmp.balance === "" ? 0 : paymentInfoTmp.balance,
      subTotal: paymentInfoTmp.subTotal === "" ? 0 : paymentInfoTmp.subTotal,
      total: paymentInfoTmp.total === "" ? 0 : paymentInfoTmp.total,
    };

    // Check whether required information is provided
    const allFieldsFilled = checkNullAndSetWarnings(paymentInfoWithDefaults);

    if (!allFieldsFilled) {
      console.log("Some fields are missing");
      return;
    }

    // Create the cardExpirationDate by concatenating cardExpirationYear and cardExpirationMonth
    const expirationDateTmp = createExpirationDate(paymentInfoWithDefaults);

    // Create data format to send APIs
    const updatedPaymentInfo = transformPaymentInfo(
      paymentInfoWithDefaults,
      expirationDateTmp
    );

    const authorizeAPIRequestData = createAuthorizeAPIRequest(
      updatedPaymentInfo,
      invoicePlaceHolder
    );

    // Call Authorize.net API
    const response = await dispatch(
      postAuthCreditCardPayment(authorizeAPIRequestData)
    );

    // Alert
    let transactionResultCode;
    let transactionResultMessage;

    const ResponseFromAuth = response.data;

    // Check the response from Authorize.net If transaction is approved

    if (
      ResponseFromAuth?.transactionResponse?.responseCode === "1" &&
      ResponseFromAuth?.transactionResponse?.messages &&
      ResponseFromAuth?.transactionResponse?.messages[0]?.code === "1" &&
      ResponseFromAuth?.messages?.resultCode === "Ok"
    ) {
      transactionResultCode =
        ResponseFromAuth?.transactionResponse?.messages[0]?.code;
      transactionResultMessage =
        ResponseFromAuth?.transactionResponse?.messages[0].description;

      // Always show the success alert if paymentResult is 1
      paymentResult = 1;

      // Get a TransactionId
      const transId = ResponseFromAuth?.transactionResponse?.transId;
      console.log("transId :", transId);

      // Transaction success alert
      alert(
        "ResultCode :" + transactionResultCode + "\n" + transactionResultMessage
      );

      // Collect data for payment confirmation email
      const maevnAPIRequestData = createMaevnAPIRequest(
        updatedPaymentInfo,
        invoicePlaceHolder,
        paymentResult,
        transId,
        transactionResultMessage
      );

      const maevnResponse = await dispatch(
        postMaevnPayment(maevnAPIRequestData)
      );

      // store payment details to redux
      dispatch(postPaymentConfirmRedux(maevnResponse));

      // move to payment confirmation page
      navigate("/paymentConfirm");
    } else if (
      // Payment failed
      ResponseFromAuth?.transactionResponse?.responseCode !== "1" ||
      (ResponseFromAuth?.transactionResponse?.errors &&
        ResponseFromAuth?.transactionResponse?.errors[0]?.errorCode) ||
      ResponseFromAuth?.messages?.resultCode === "Error"
    ) {
      transactionResultCode =
        ResponseFromAuth?.transactionResponse?.errors?.[0]?.errorCode;
      transactionResultMessage =
        ResponseFromAuth?.transactionResponse?.errors?.[0]?.errorText;

      // Always show the failure alert if paymentResult is 0
      paymentResult = 0;

      // Transaction failure alert
      alert(
        (transactionResultCode
          ? "ErrorCode: " + transactionResultCode
          : "Warning!") +
          "\n" +
          (transactionResultMessage
            ? transactionResultMessage
            : "The transaction has not been successful. Please review your billing information.")
      );
    } else {
      alert("An unexpected error occurred. Please try again.");
    }
  };

  const handleCancelButtonClick = () => {
    navigate("/myaccount#invoice");
  };

  useEffect(() => {
    console.log("paymentInfoTmp in paymentTab : ", paymentInfoTmp);
  }, [paymentInfoTmp]);

  return (
    <>
      <div className="flex space-x-8">
        <div className="w-full ">
          {/* Pay Your Invoice */}
          <div
            className="flex justify-between items-center "
            style={{
              marginBottom: "20px",
              borderBottom: "1px solid lightgray",
            }}
          >
            <div className="flex space-x-3 items-center">
              <span>
                <svg
                  width="30"
                  height="25"
                  viewBox="0 0 17 12"
                  className="fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#000000"
                    d="M12.7405 0.0107422H3.61054C1.81171 0.0136959 0.352563 1.46989 0.349609 3.27167H15.9985C15.9985 1.47284 14.5394 0.0136959 12.7405 0.0107422Z"
                  />
                  <path
                    fill="#000000"
                    d="M0.349609 8.4879C0.352563 10.2867 1.80876 11.7459 3.61054 11.7488H12.7405C14.5394 11.7459 15.9985 10.2897 16.0015 8.4879V4.57715H0.349609V8.4879ZM4.91314 8.16299C4.91314 8.70352 4.47598 9.14068 3.93545 9.14068C3.39492 9.14068 2.95776 8.70352 2.95776 8.16299C2.95776 7.62245 3.39492 7.1853 3.93545 7.1853C4.47598 7.1853 4.91314 7.62245 4.91314 8.16299Z"
                  />
                </svg>
              </span>
              <span
                className="text-2xl font-bold payment-subtitle"
                style={{ color: "#000000" }}
              >
                Pay Your Invoice
              </span>
            </div>
            {/* move to back button */}
            <div className="w-[100px] h-[40px] mt-4 mb-4">
              <button
                type="button"
                className="yellow-btn"
                onClick={handleCancelButtonClick}
              >
                <div className="w-full text-sm font-semibold">{"< BACK"}</div>
              </button>
            </div>
          </div>
          <div
            className="flex space-x-3 items-center "
            style={{ marginBottom: "40px" }}
          >
            <span className="text-sm font-semibold">
              Please provide the following information to pay your invoice. When
              you are finished, click "Submit Payment" at the bottom of the page
              to proceed
            </span>
          </div>
          {/* User Information */}
          <UserInformation
            companyName={companyName}
            userName={userName}
            contactEmail={contactEmail}
            paymentInfoTmp={paymentInfoTmp}
            setPaymentInfoTmp={setPaymentInfoTmp}
            warnings={warnings}
          />

          {/* Invoice Information */}
          <InvoiceInformation
            selectedInvoices={selectedInvoices}
            paymentInfoTmp={paymentInfoTmp}
            setPaymentInfoTmp={setPaymentInfoTmp}
            warnings={warnings}
          />

          {/* Input amount of money */}
          <MoneyToPay
            creditBalancePlaceHolder={creditBalancePlaceHolder}
            invoicePlaceHolder={invoicePlaceHolder}
            subtotalPlaceholder={subtotalPlaceholder}
            totalPlaceholder={totalPlaceholder}
            paymentInfoTmp={paymentInfoTmp}
            setPaymentInfoTmp={setPaymentInfoTmp}
            warnings={warnings}
          />

          {/* Bill Information */}
          <BillInformation
            paymentInfoTmp={paymentInfoTmp}
            setPaymentInfoTmp={setPaymentInfoTmp}
            warnings={warnings}
          />

          {/* Card Information */}
          <CardInformation
            paymentInfoTmp={paymentInfoTmp}
            setPaymentInfoTmp={setPaymentInfoTmp}
            warnings={warnings}
          />
        </div>
      </div>

      {/* Buttons */}
      <div className="action-area flex space-x-4 items-center">
        {/* <button
          type="button"
          className="text-sm text-qred font-semibold"
          onClick={handleCancelButtonClick}
        >
          Cancel
          </button> */}
        <SecondaryButton
          width="218px"
          height="40px"
          fontSize="18px"
          onClick={handleCancelButtonClick}
          // disabled={disabled}
        >
          Cancel{" "}
        </SecondaryButton>

        {/* <button
          type="button"
          className="w-[164px] h-[50px] bg-qblack text-white text-sm"
          onClick={handleSubmitButtonClick}
        >
          Submit Payment
          </button> */}

        <PrimaryButton
          width="218px"
          height="40px"
          fontSize="18px"
          onClick={handleSubmitButtonClick}
          // disabled={disabled}
        >
          Submit Payment
        </PrimaryButton>
      </div>
    </>
  );
}
