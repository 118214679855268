import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import PageTitle from "../../Helpers/PageTitle";
import Layout from "../../Partials/Layout";
import Orders from "./Orders";
import { getOrders } from "../../../actions/orderAction";

export default function Index({ cart = true }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // validate user
  const { search } = useLocation(); // search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login";

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  // order information
  const OrderHistory = useSelector((state) => state.getOrders);
  const { loading, error, orders } = OrderHistory;

  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");
      navigate(redirect);
    } else {
      console.log("You are already logged in ");
      dispatch(getOrders());
    }
  }, [dispatch, navigate, redirect, userInfo]);

  return (
    <Layout childrenClasses={cart ? "pt-0 pb-0" : ""}>
      <div className="cart-page-wrapper w-full bg-white pb-[60px]">
        <div className="w-full h-25">
          <PageTitle
            title="Your Orders"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Order History", path: "/orders" },
            ]}
          />
        </div>

        <div className="w-full mt-[23px]">
          <div className="container-x mx-auto">
            <Orders isLoading={loading} orders={orders} />
            <div className="w-full mt-[30px] flex sm:justify-end"></div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
