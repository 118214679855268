import Axios from "axios";

import {
  ADD_BASKET_REQUEST,
  ADD_BASKET_SUCCESS,
  ADD_BASKET_FAIL,
  GET_BASKET_REQUEST,
  GET_BASKET_SUCCESS,
  GET_BASKET_FAIL,
  UPDATE_BASKET_REQUEST,
  UPDATE_BASKET_SUCCESS,
  UPDATE_BASKET_FAIL,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAIL,
  CLEAR_BASKET_REQUEST,
  CLEAR_BASKET_SUCCESS,
  CLEAR_BASKET_FAIL,
  UPDATE_QUICKORDER_REQUEST,
  UPDATE_QUICKORDER_SUCCESS,
  UPDATE_QUICKORDER_FAIL,
} from "../constants/cartConstants";

const apiUrl = process.env.REACT_APP_API_URL;

// access cart using UserId in token
export const getCart = () => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }
  console.log("token", token.result.token);

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_BASKET_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(`${apiUrl}/shoppingCart/${tokenUserId}`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_BASKET_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_BASKET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_BASKET_SUCCESS, payload: data });
};

// eslint-disable-next-line import/prefer-default-export
export const addToCart = (quantities) => async (dispatch) => {
  dispatch({ type: ADD_BASKET_REQUEST, payload: { quantities } });

  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let userId = null;
  if (token) {
    userId = JSON.parse(token.result.user.userId);
  }

  if (!token) {
    // redirect to login page and return
    console.log("no token", token);
    return;
  }

  // if there is token, verificate the token
  const newQuantities = quantities.map((item) => ({
    userId: userId,
    pid: item.pid,
    qty: item.quantity,
  }));

  let { data } = "";
  try {
    data = await Axios.post(`${apiUrl}/shoppingCart`, newQuantities, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: ADD_BASKET_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: ADD_BASKET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: ADD_BASKET_SUCCESS, payload: data });
};

export const updateCart =
  ({ Pid, Quantity, ParentId }) =>
  async (dispatch) => {
    // if there is no token, go to login page
    const token = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;

    let tokenUserId = null;
    if (token) {
      tokenUserId = JSON.parse(token.result.user.userId);
    }

    // redirect to login page and return
    if (!token) {
      console.log("no token", token);
      return;
    }

    dispatch({ type: UPDATE_BASKET_REQUEST });

    let { data } = "";
    try {
      data = await Axios.put(
        `${apiUrl}/shoppingCart?UserId=${tokenUserId}&Pid=${Pid}&Quantity=${Quantity}&ParentId=${ParentId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token.result.token}` },
        }
      );
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({
          type: UPDATE_BASKET_FAIL,
          payload: error.response.data.error,
        });
      } else {
        dispatch({
          type: UPDATE_BASKET_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
      return;
    }
    dispatch({ type: UPDATE_BASKET_SUCCESS, payload: data });
  };

export const removeItem =
  ({ UserId, ProductId, ParentId }) =>
  async (dispatch) => {
    // if there is no token, go to login page
    const token = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;

    let tokenUserId = null;
    if (token) {
      tokenUserId = JSON.parse(token.result.user.userId);
    }

    // redirect to login page and return
    if (!token) {
      console.log("no token", token);
      return;
    }

    dispatch({ type: DELETE_ITEM_REQUEST });

    let { data } = "";
    try {
      data = await Axios.delete(
        `${apiUrl}/shoppingCart?UserId=${tokenUserId}&ProductId=${ProductId}&ParentId=${ParentId}`,
        { headers: { Authorization: `Bearer ${token.result.token}` } }
      );
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({
          type: DELETE_ITEM_FAIL,
          payload: error.response.data.error,
        });
      } else {
        dispatch({
          type: DELETE_ITEM_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
      return;
    }
    dispatch({ type: DELETE_ITEM_SUCCESS, payload: data });
  };

// access cart using UserId in token
export const clearCart = () => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }
  console.log("token", token.result.token);

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: CLEAR_BASKET_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.delete(`${apiUrl}/shoppingCart/clear/${tokenUserId}`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: CLEAR_BASKET_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: CLEAR_BASKET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: CLEAR_BASKET_SUCCESS, payload: data });
};

export const updateCartQuickOrder = (orderItems) => async (dispatch) => {
  //orderItems: [{userId, pid, qty}]
  dispatch({ type: UPDATE_QUICKORDER_REQUEST, payload: { orderItems } });

  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let userId = null;
  if (token) {
    userId = JSON.parse(token.result.user.userId);
  }

  if (!token) {
    // redirect to login page and return
    console.log("no token", token);
    return;
  }

  // if there is token, verificate the token
  let { data } = "";
  try {
    data = await Axios.put(`${apiUrl}/shoppingCart/quickorder`, orderItems, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: UPDATE_QUICKORDER_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: UPDATE_QUICKORDER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: UPDATE_QUICKORDER_SUCCESS, payload: data });
};
