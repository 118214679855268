import styles from "./Button.css";

export default function SecondaryButton({
  width,
  height,
  fontSize,
  color,
  style,
  children, // contents of button
  onClick, // clickHandler
  type = "button",
  disabled,
}) {
  // Combine external styles with internal styles
  const combinedStyles = {
    width,
    height,
    fontSize,
    color,
    ...style,
  };

  return (
    <button
      className="secondaryButton"
      style={combinedStyles} // Apply width and height from props
      type={type} // Set button type (button, submit, reset)
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
