import Axios from "axios";

import {
  POST_EMAILREQUEST_REQUEST,
  POST_EMAILREQUEST_FAIL,
  POST_EMAILREQUEST_SUCCESS,
  POST_PASSWORDREQUEST_FAIL,
  POST_PASSWORDREQUEST_REQUEST,
  POST_PASSWORDREQUEST_SUCCESS,
} from "../constants/passwordResetConstants";

const apiUrl = process.env.REACT_APP_API_URL;

// eslint-disable-next-line import/prefer-default-export
export const postemailrequest = (username, email) => async (dispatch) => {
  dispatch({
    type: POST_EMAILREQUEST_REQUEST,
    payload: { username, email },
  });

  try {
    const { data } = await Axios.post(
      `${apiUrl}/userAuth/requetResetpassword/${username}/${email}`,
      {},
      {
        headers: {},
      }
    );

    dispatch({ type: POST_EMAILREQUEST_SUCCESS, payload: data });
    console.log("password reset request ", data);
    return data;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: POST_EMAILREQUEST_FAIL,
        payload: error.response,
      });
    } else {
      dispatch({
        type: POST_EMAILREQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response
            : error.message,
      });
    }
    console.log("password reset error : ", error);
    throw error; // Rethrow the error
  }
};

export const confirmResetpassword =
  (confirmRequestPasswordReset) => async (dispatch) => {
    dispatch({
      type: POST_PASSWORDREQUEST_REQUEST,
      payload: confirmRequestPasswordReset,
    });

    try {
      const { data } = await Axios.put(
        `${apiUrl}/userAuth/confirmResetpassword`,
        confirmRequestPasswordReset,
        {
          headers: {},
        }
      );

      dispatch({ type: POST_PASSWORDREQUEST_SUCCESS, payload: data });
      console.log("confirmResetpassword error : ", data);
      return data;
    } catch (error) {
      if (error.response) {
        dispatch({
          type: POST_PASSWORDREQUEST_FAIL,
          payload: error.response,
        });
      } else {
        dispatch({
          type: POST_PASSWORDREQUEST_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response
              : error.message,
        });
      }
      console.log("confirmResetpassword error : ", error);
      throw error; // Rethrow the error
    }
  };
