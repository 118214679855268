import Axios from "axios";

import {
  GET_ADDRESSLIST_REQUEST,
  GET_ADDRESSLIST_SUCCESS,
  GET_ADDRESSLIST_FAIL,
} from "../constants/userConstants";
const apiUrl = process.env.REACT_APP_API_URL;

// eslint-disable-next-line import/prefer-default-export
export const getAddressList = () => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_ADDRESSLIST_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(`${apiUrl}/user/address/${tokenUserId}`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_ADDRESSLIST_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_ADDRESSLIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_ADDRESSLIST_SUCCESS, payload: data.data });
  console.log("address in action : ", data.data.result);
};
