import InputQuantityCom from "../../Helpers/InputQuantityCom";
import { useEffect } from "react";
import "../../SubAccount/subAccount.css";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../Helpers/Buttons/PrimaryButton";

export default function RoleTable({
  className,
  isLoading,
  adminUserId,
  userId,
  roleList,
  loginId,
  userLevel,
  userEmail,
}) {
  console.log("roleList", roleList);

  const navigate = useNavigate();

  function handleEditRoleClick(roldId, roleName, adminId) {
    navigate(
      `/editrole?id=${roldId}&roleName=${roleName}&adminUserId=${adminId}`
    );
  }

  /*function handleEditRoleClick() {
    navigate(`/editrole`);
  }*/

  function handleAddRoleClick() {
    navigate(`/addrole`);
  }

  // if no data or loading, not rendering
  if (!roleList) {
    return null;
  }

  return (
    <div className={`table-container ${className || ""}`}>
      <div className="administrator-text">Administrator</div>

      {/* Admin Banner */}
      <div className="members-container">
        <div className="members-inner-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="88"
            height="88"
            viewBox="0 0 88 88"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M44.0003 0.000488281C19.6993 0.000488281 0.000610352 19.6992 0.000610352 44.0002C0.000610352 68.3012 19.6993 87.9999 44.0003 87.9999C68.3014 87.9999 88 68.3012 88 44.0002C88 19.6992 68.3014 0.000488281 44.0003 0.000488281ZM28.6004 33.0003C28.6004 30.9779 28.9988 28.9754 29.7727 27.107C30.5466 25.2386 31.6809 23.5409 33.111 22.1109C34.541 20.6809 36.2386 19.5465 38.107 18.7726C39.9754 17.9987 41.978 17.6004 44.0003 17.6004C46.0227 17.6004 48.0252 17.9987 49.8936 18.7726C51.762 19.5465 53.4597 20.6809 54.8897 22.1109C56.3197 23.5409 57.4541 25.2386 58.228 27.107C59.0019 28.9754 59.4002 30.9779 59.4002 33.0003C59.4002 37.0846 57.7777 41.0016 54.8897 43.8896C52.0017 46.7777 48.0846 48.4002 44.0003 48.4002C39.916 48.4002 35.999 46.7777 33.111 43.8896C30.2229 41.0016 28.6004 37.0846 28.6004 33.0003ZM71.5353 65.9297C68.2417 70.0728 64.0545 73.4182 59.2866 75.7161C54.5186 78.014 49.2931 79.2049 44.0003 79.2C38.7075 79.2049 33.482 78.014 28.7141 75.7161C23.9461 73.4182 19.759 70.0728 16.4653 65.9297C23.5977 60.8125 33.3304 57.2001 44.0003 57.2001C54.6703 57.2001 64.403 60.8125 71.5353 65.9297Z"
              fill="#363636"
            />
          </svg>
        </div>
        <div className="inner-text ">
          {userLevel === 0 ? <div>Admin</div> : <div>Sub-User</div>}
          <div className="name-text">{loginId}</div>
          <div className="email-text">{userEmail}</div>
        </div>
      </div>

      <div className="role-permission-text">Roles and permissions</div>
      <div className="w-full sm:flex justify-between">
        <div className="flex space-x-2.5 items-center">
          <PrimaryButton
            onClick={handleAddRoleClick}
            style={{ marginBottom: "20px" }}
          >
            Add Role
          </PrimaryButton>
        </div>
      </div>
      {/* Role Table */}
      <div className="table-wrapper">
        <div className="table-header">
          <div className="header-item">Role</div>
          <div className="header-item">Permission</div>
          <div className="header-item">Edit</div>
        </div>
        {roleList &&
          roleList.map(
            (role, index) =>
              role.id !== 0 && ( // role.id : 0 is admin
                <div key={index} className="table-row">
                  <div className="row-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                      style={{ marginRight: "5px" }}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.2511 0C3.66115 0 0.751099 2.91005 0.751099 6.5C0.751099 10.0899 3.66115 13 7.2511 13C10.841 13 13.7511 10.0899 13.7511 6.5C13.7511 2.91005 10.841 0 7.2511 0ZM4.9761 4.875C4.9761 4.57624 5.03494 4.28041 5.14927 4.0044C5.2636 3.72838 5.43118 3.47759 5.64243 3.26633C5.85368 3.05508 6.10448 2.8875 6.38049 2.77317C6.65651 2.65884 6.95234 2.6 7.2511 2.6C7.54986 2.6 7.84569 2.65884 8.1217 2.77317C8.39772 2.8875 8.64851 3.05508 8.85977 3.26633C9.07102 3.47759 9.2386 3.72838 9.35292 4.0044C9.46725 4.28041 9.5261 4.57624 9.5261 4.875C9.5261 5.47837 9.28641 6.05702 8.85977 6.48367C8.43312 6.91031 7.85447 7.15 7.2511 7.15C6.64773 7.15 6.06908 6.91031 5.64243 6.48367C5.21579 6.05702 4.9761 5.47837 4.9761 4.875ZM11.3188 9.7396C10.8322 10.3517 10.2137 10.8459 9.50931 11.1853C8.80495 11.5248 8.03299 11.7007 7.2511 11.7C6.4692 11.7007 5.69725 11.5248 4.99289 11.1853C4.28853 10.8459 3.66996 10.3517 3.1834 9.7396C4.23705 8.98365 5.67485 8.45 7.2511 8.45C8.82735 8.45 10.2651 8.98365 11.3188 9.7396Z"
                        fill="#0B0B0B"
                      />
                    </svg>
                    {role.roleName}
                  </div>
                  <div className="row-item">Limited</div>
                  <div className="row-item actions">
                    <button
                      type="button"
                      className="edit-button"
                      onClick={() =>
                        handleEditRoleClick(
                          role.id,
                          role.roleName,
                          role.adminUserId
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                        fill="none"
                      >
                        <path
                          d="M1.81916 8.95695L9.33913 1.43657C9.63622 1.15227 10.0328 0.995601 10.444 1.00009C10.8552 1.00459 11.2483 1.16989 11.5391 1.46061C11.8299 1.75133 11.9953 2.14435 11.9999 2.55552C12.0045 2.96669 11.8479 3.36332 11.5637 3.66048L4.04252 11.1809C3.87095 11.3524 3.65242 11.4694 3.41448 11.517L1 12L1.48302 9.58497C1.53061 9.34704 1.64757 9.12852 1.81916 8.95695Z"
                          stroke="#474747"
                          stroke-width="1.46667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.06714 3.08984L9.91072 4.93337"
                          stroke="#474747"
                          stroke-width="1.46667"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )
          )}
      </div>
    </div>
  );
}
