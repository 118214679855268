import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import InputCom from "../InputCom";
import "../OrderReview.css";
import DatePicker from "react-datepicker";
import StickyElement from "./StickyElement";

import {
  updateDefaultShipToAddress,
  updateDefaultCarrierService,
  checkDuplicatedPoNumber,
} from "../../../../actions/checkoutAction";
import DropshipModal from "../DropshipModal";
import ShippingSection from "./ShippingSection";
import DeliverySection from "./DeliverySection";
import BillingSection from "./BillingSection";
import carrierServices from "../../../../data/carrierService.json";
import ItemSection from "./ItemSection";

export default function CheckoutPage({
  className,
  isLoading,
  userAccountInfo,
  cartItems = [],
  updateLocalItems,
  shipToAddresses = [],
  deliveryOption = {},
  billToAddresses = [],
  onItemRemoved,
  totalNumberOfItems: initialTotalNumberOfItems, // Rename prop to avoid conflict
  toggleCheckoutLoading,
}) {
  // Hook
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //-----------------------------------------------------------

  // const cartItemsArray = cartItems || [];
  //--------------------------localItems------------------------------------
  // State
  const sectionRefs = useRef([]);
  const [scrollableHeight, setScrollableHeight] = useState(0);
  const containerRef = useRef(null);

  //  const cartItemsArray = cartItems || []
  const [showAddressList, setShowAddressList] = useState(false);
  const [showDeliveryOptions, setShowDeliveryOptions] = useState(false);
  const [showBillToAddressList, setShowBillToAddressList] = useState(false);

  const [dropShip, setDropShip] = useState(0);

  const shipToAddressArray = shipToAddresses || [];
  const [shippingInfo, setShippingInfo] = useState({
    ...shipToAddresses[0],
    companyName: "",
    countryName: "",
    stateName: "",
    city: "",
    address1: "",
    address2: "",
    zipCode: "",
    countryId: 0,
    stateId: 0,
    defaultTypeId: false,
    erpAddressId: 0,
  });

  const defaultDeliveryOption = deliveryOption || {};
  const [defaultCarrierService, setDefaultCarrierService] = useState(false);
  const [services, setServices] = useState([]);
  const [tmpServices, setTmpServices] = useState([]);
  const [deliveryInfo, setDeliveryInfo] = useState({
    ...defaultDeliveryOption,
    carrierName: "",
    erpCarrierId: "",
    serviceName: "",
    erpServiceId: 0,
    signatureService: 0,
    requestedDate: null,
    memo: "",
  });
  const [tempDeliveryInfo, setTempDeliveryInfo] = useState(deliveryInfo);

  const billToAddressArray = billToAddresses || [];
  const [isPoNumberVerified, setIsPoNumberVerified] = useState(false);
  const [poNullError, setPoNullError] = useState("");
  const [billingInfo, setBillingInfo] = useState({
    ...billToAddresses[0],
    poNumber: "",
    companyName: "",
    // email: "",
    // phone: "",
    countryName: "",
    stateName: "",
    city: "",
    address1: "",
    address2: "",
    zipCode: "",
    countryId: 0,
    stateId: 0,
    defaultTypeId: false,
    erpAddressId: 0,
  });
  const [tempBillingInfo, setTempBillingInfo] = useState(billingInfo);

  const [isPreorder, setIsPreorder] = useState(0);

  const [cartItemsArray, setCartItemsArray] = useState(cartItems || []);

  const [totalPrice, setTotalPrice] = useState(0);
  const [discountedTotalPrice, setDiscountedTotalPrice] = useState(0);
  const [savedTotal, setSavedTotal] = useState(0);
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(
    initialTotalNumberOfItems
  ); // Use state to handle total number of items

  const [isInitialLoadingComplete, setIsInitialLoadingComplete] =
    useState(false);

  // Initial loading effect
  useEffect(() => {
    if (!isLoading && cartItems) {
      setCartItemsArray(cartItems || []);
      setTotalNumberOfItems(
        cartItems.reduce((total, item) => total + item.qty, 0)
      ); // Set total number of items based on initial cart items
      setIsInitialLoadingComplete(true);
    }
  }, [isLoading, cartItems]);

  // Navigation logic
  useEffect(() => {
    if (
      isInitialLoadingComplete &&
      cartItemsArray.length === 0 &&
      !isLoading &&
      !cartItemsArray
    ) {
      alert("Your cart is empty. Please add items.");
      navigate("/cart");
    }
  }, [isInitialLoadingComplete, cartItemsArray, isLoading, navigate]);

  // Handlers
  const setSectionRef = (index) => (el) => {
    sectionRefs.current[index] = el;
  };

  const selectAddress = (index) => {
    setShippingInfo({
      ...shipToAddressArray[index],
    });
    setDropShip(0);
    setShowAddressList(false);

    // callback carrier service because dropship is canceled
    setTempDeliveryInfo({
      ...tempDeliveryInfo,
      carrierName: defaultDeliveryOption.carrierName, // or your defined default value
      serviceName: defaultDeliveryOption.serviceName, // or your defined default value
      erpCarrierId: defaultDeliveryOption.erpCarrierId,
      erpServiceId: defaultDeliveryOption.erpServiceId,
    });

    setDeliveryInfo({
      ...deliveryInfo,
      carrierName: defaultDeliveryOption.carrierName,
      serviceName: defaultDeliveryOption.serviceName,
      erpCarrierId: defaultDeliveryOption.erpCarrierId,
      erpServiceId: defaultDeliveryOption.erpServiceId,
    });
  };

  const handleCarrierChange = (event) => {
    const selectedCarrier = event.target.value;

    const foundCarrier = carrierServices.find(
      (carrier) => carrier.name === selectedCarrier
    );
    const updatedServices = foundCarrier ? foundCarrier.services : [];

    setTempDeliveryInfo({
      ...tempDeliveryInfo,
      carrierName: foundCarrier.name,
      erpCarrierId: foundCarrier.erpCarrierId,
      serviceName: "", // Reset service when carrier changes
      erpServiceId: "", // Reset erpServiceId when carrier changes
    });
    setTmpServices(updatedServices);
  };

  const handleServiceChange = (event) => {
    const selectedService = event.target.value;

    const foundService = tmpServices.find(
      (service) => service.serviceName === selectedService
    );

    const updatedServiceId = foundService ? foundService.erpServiceId : null;

    setTempDeliveryInfo({
      ...tempDeliveryInfo,
      serviceName: selectedService,
      erpServiceId: updatedServiceId, // Update erpServiceId based on the selected service
    });
  };

  // Default carrier service  change handlers
  const handleDefaultCarrierService = (event, erpCarrierId, erpServiceId) => {
    if (defaultCarrierService) {
      setDefaultCarrierService(false);
    } else {
      // if serviceId is null
      if (erpServiceId === null) {
        alert("Select Carrier Service.");
      } else {
        setDefaultCarrierService(true);
        defaultDeliveryOption.erpServiceId = erpServiceId;

        // change default carrierId, serviceId API
        dispatch(updateDefaultCarrierService(erpCarrierId, erpServiceId));

        alert("Default Carrier Service is changed.");
      }
    }
  };

  const handleConfirmDeliveryOptions = () => {
    // When the user confirms the delivery options, update the deliveryInfo state
    setServices(tmpServices);
    setDeliveryInfo(tempDeliveryInfo);
    setShowDeliveryOptions(false);
  };

  const handleSignatureServiceChange = (event) => {
    const value = event.target.checked ? 1 : 0;
    setTempDeliveryInfo({
      ...tempDeliveryInfo,
      signatureService: value,
    });

    // Show toast notification when checkbox is checked
    if (value) {
      toast.info("3rd party's Signature service will be charged more.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleMemoChange = (event) => {
    setTempDeliveryInfo({
      ...tempDeliveryInfo,
      memo: event.target.value,
    });
  };

  const selectBillToAddress = (index) => {
    setBillingInfo({
      ...billToAddressArray[index],
    });
    setShowBillToAddressList(false);
  };

  const handlePoNumberChange = (event) => {
    const value = event.target.value;
    setBillingInfo((prevBillingInfo) => ({
      ...prevBillingInfo,
      poNumber: value,
    }));

    // Reset the verification status when the user changes the PO number
    setIsPoNumberVerified(false);
  };

  const verifyPoNumber = async (po, erpCustomerId) => {
    //Check ponumber is Null or not
    if (!po) {
      setPoNullError("Please verify your PO Number");
      return;
    }

    try {
      // Call validation API for po number and wait for the response
      const response = await dispatch(
        checkDuplicatedPoNumber(po, erpCustomerId)
      );
      setBillingInfo({ ...billingInfo, poNumber: po });

      // po number verification flag
      alert("Your PO Number is verified.");
      setIsPoNumberVerified(true);
    } catch (error) {
      // If verification for po number is fail
      alert("This PO Number is duplicated. Please enter other PO Number.");
      setBillingInfo({ ...billingInfo, poNumber: "" });
    }

    // Check if there is a preorder in order items
    const hasPreorderItem = await new Promise((resolve) => {
      const result = cartItems.some((item) => item.isPreorder === 1);
      resolve(result);
    });

    if (hasPreorderItem) {
      console.log("There is at least one preorder item");
      setIsPreorder(1);
    } else {
      console.log("There are no preorder items");
    }
  };

  // Change carriderService as the selected carrier company
  const [selectedCarrierServices, setSelectedCarrierServices] = useState([]);

  const handleCarrierSelectionChange = (event) => {
    const selectedCarrierName = event.target.value;
    const selectedCarrier = carrierServices.find(
      (carrier) => carrier.name === selectedCarrierName
    );

    if (selectedCarrier) {
      const initialService = selectedCarrier.services[0];

      setSelectedCarrierServices(selectedCarrier.services);
      setTempDeliveryInfo({
        ...tempDeliveryInfo,
        carrierName: selectedCarrier.name,
        erpCarrierId: selectedCarrier.erpCarrierId,
        serviceName: initialService ? initialService.serviceName : "",
        erpServiceId: initialService ? initialService.erpServiceId : "",
      });

      setDeliveryInfo({
        ...deliveryInfo,
        carrierName: selectedCarrier.name,
        erpCarrierId: selectedCarrier.erpCarrierId,
        serviceName: initialService ? initialService.serviceName : "",
        erpServiceId: initialService ? initialService.erpServiceId : "",
      });
    }
  };

  // Default carrier service change handlers for default-delivery container
  const handleDefaultServiceChange = (event) => {
    const selectedService = event.target.value;
    const foundService = selectedCarrierServices.find(
      (service) => service.serviceName === selectedService
    );
    const updatedServiceId = foundService ? foundService.erpServiceId : null;

    setTempDeliveryInfo({
      ...tempDeliveryInfo,
      serviceName: selectedService,
      erpServiceId: updatedServiceId, // Update erpServiceId based on the selected service
    });

    setDeliveryInfo({
      ...deliveryInfo,
      serviceName: selectedService,
      erpServiceId: updatedServiceId,
    });
  };

  // Function for date formatting unaffected by timezones
  const formatDate = (date) => {
    const d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    return [year, month.padStart(2, "0"), day.padStart(2, "0")].join("-");
  };

  // Directly convert the date to the "yyyy-MM-dd" format
  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    setTempDeliveryInfo({
      ...tempDeliveryInfo,
      requestedDate: formattedDate,
    });
  };

  // Create a Date object from a string in the "yyyy-MM-dd" format
  const parseDateString = (dateString) => {
    const [year, month, day] = dateString
      .split("-")
      .map((val) => parseInt(val, 10));
    // Create a Date object based on the local timezone
    return new Date(year, month - 1, day);
  };

  const getUTCDate = (dateString) => {
    const [year, month, day] = dateString
      .split("-")
      .map((num) => parseInt(num, 10));
    return new Date(Date.UTC(year, month - 1, day));
  };

  // Effects
  // Expandable layout
  useEffect(() => {
    // Scrollable height
    const totalHeight = sectionRefs.current.reduce(
      (sum, section) => sum + (section ? section.clientHeight : 0),
      0
    );

    if (showAddressList || showDeliveryOptions || showBillToAddressList) {
      setScrollableHeight(totalHeight);
    } else {
      const shippingInfoSectionHeight =
        sectionRefs.current[0]?.clientHeight || 0;
      setScrollableHeight(shippingInfoSectionHeight);
    }

    // Enlarger layout-left
    if (containerRef.current) {
      containerRef.current.style.height = `${scrollableHeight}px`;
    }
  }, [
    showAddressList,
    showDeliveryOptions,
    showBillToAddressList,
    scrollableHeight,
  ]);

  // Set default shipping address
  useEffect(() => {
    if (shipToAddressArray.length > 0) {
      setShippingInfo((prevDeliveryInfo) => ({
        ...prevDeliveryInfo,
        ...shipToAddressArray[0],
      }));
    }
  }, [shipToAddressArray]);

  // Set default billing address
  useEffect(() => {
    if (billToAddressArray.length > 0) {
      setBillingInfo((prevDeliveryInfo) => ({
        ...prevDeliveryInfo,
        ...billToAddressArray[0],
      }));
    }
  }, [billToAddressArray]);

  // Set default carrierService and default delivery options
  useEffect(() => {
    if (defaultDeliveryOption) {
      setDeliveryInfo((prevShippingInfo) => ({
        ...prevShippingInfo,
        ...defaultDeliveryOption,
      }));

      setTempDeliveryInfo((prevTempDeliveryInfo) => ({
        ...prevTempDeliveryInfo,
        ...defaultDeliveryOption,
      }));
    }

    if (defaultDeliveryOption && defaultDeliveryOption.carrierName) {
      const initialCarrier = carrierServices.find(
        (carrier) => carrier.name === defaultDeliveryOption.carrierName
      );
      if (initialCarrier) {
        setServices(initialCarrier.services);
        setTmpServices(initialCarrier.services);
      }
    }
  }, [defaultDeliveryOption]);

  // Toggle defaultCarrierService based on selected erpServiceId
  useEffect(() => {
    if (defaultDeliveryOption.erpServiceId === deliveryInfo.erpServiceId) {
      setDefaultCarrierService(true);
    } else {
      setDefaultCarrierService(false);
    }
  }, [deliveryInfo]);

  // Check items in cart && Calculating Total Price
  useEffect(() => {
    if (cartItemsArray.length > 0) {
      const calculateTotalPrice = () => {
        const totalPrice = cartItemsArray.reduce((accumulator, item) => {
          return accumulator + item.subTotal;
        }, 0);
        const discountedTotalPrice = cartItemsArray.reduce(
          (accumulator, item) => {
            return accumulator + item.discountedSubTotal;
          },
          0
        );
        const totalSavedPrice = cartItemsArray.reduce((accumulator, item) => {
          const priceDifference = item.subTotal - item.discountedSubTotal;
          return accumulator + (priceDifference > 0 ? priceDifference : 0);
        }, 0);

        setTotalPrice(totalPrice);
        setDiscountedTotalPrice(discountedTotalPrice);
        setSavedTotal(totalSavedPrice);
      };

      calculateTotalPrice();
    }
  }, [cartItemsArray]);

  useEffect(() => {
    console.log("Checkoutpage - shippingInfo : ", shippingInfo);
  }, [shippingInfo]);

  useEffect(() => {
    console.log("Checkoutpage - deliveryInfo : ", deliveryInfo);
  }, [deliveryInfo]);

  useEffect(() => {
    console.log("Checkoutpage - billingInfo : ", billingInfo);
  }, [billingInfo]);

  useEffect(() => {
    console.log("Checkoutpage - cartItemsArray : ", cartItemsArray);
  }, [cartItemsArray]);

  return (
    <div className={`w-full flex ${className || ""} layout-container`}>
      <div ref={containerRef} className="layout-left w-full md:w-3/4 ">
        {/* shipping information section */}
        <ShippingSection
          shippingInfo={shippingInfo}
          setShippingInfo={setShippingInfo}
          showAddressList={showAddressList}
          setShowAddressList={setShowAddressList}
          shipToAddresses={shipToAddresses}
          selectAddress={selectAddress}
          dropShip={dropShip}
          setDropShip={setDropShip}
          setTempDeliveryInfo={setTempDeliveryInfo}
          tempDeliveryInfo={tempDeliveryInfo}
          defaultDeliveryOption={deliveryOption}
          deliveryInfo={deliveryInfo}
          setDeliveryInfo={setDeliveryInfo}
        />

        {/* Delivery Service section */}
        <DeliverySection
          setSectionRef={setSectionRef}
          setDeliveryInfo={setDeliveryInfo}
          deliveryInfo={deliveryInfo}
          setShowDeliveryOptions={setShowDeliveryOptions}
          toggleShowDeliveryOptions={() =>
            setShowDeliveryOptions((prev) => !prev)
          }
          showDeliveryOptions={showDeliveryOptions}
          dropShip={dropShip}
          setTempDeliveryInfo={setTempDeliveryInfo}
          tempDeliveryInfo={tempDeliveryInfo}
          carrierServices={carrierServices}
          services={services}
          handleCarrierSelectionChange={handleCarrierSelectionChange}
          selectedCarrierServices={selectedCarrierServices}
          setSelectedCarrierServices={setSelectedCarrierServices}
          handleDefaultServiceChange={handleDefaultServiceChange}
          handleCarrierChange={handleCarrierChange}
          handleServiceChange={handleServiceChange}
          handleDateChange={handleDateChange}
          handleMemoChange={handleMemoChange}
          handleSignatureServiceChange={handleSignatureServiceChange}
          handleConfirmDeliveryOptions={handleConfirmDeliveryOptions}
        />

        {/* Billing information section */}
        <BillingSection
          setSectionRef={setSectionRef}
          isPoNumberVerified={isPoNumberVerified}
          setShowBillToAddressList={setShowBillToAddressList}
          showBillToAddressList={showBillToAddressList}
          billingInfo={billingInfo}
          handlePoNumberChange={handlePoNumberChange}
          poNullError={poNullError}
          verifyPoNumber={verifyPoNumber}
          userAccountInfo={userAccountInfo}
          billToAddresses={billToAddresses}
          selectBillToAddress={selectBillToAddress}
        />

        {/* Items section */}
        {/*  <ItemSection
          setSectionRef={setSectionRef}
          cartItemsArray={cartItemsArray}
          totalNumberOfItems={totalNumberOfItems}
          //
          cartItems={cartItemsArray}
          setTotalPrice={setTotalPrice}
          setDiscountedTotalPrice={setDiscountedTotalPrice}
          setSavedTotal={setSavedTotal}
        />*/}

        <ItemSection
          setSectionRef={setSectionRef}
          updateLocalItems={updateLocalItems}
          cartItemsArray={cartItemsArray}
          setCartItemsArray={setCartItemsArray}
          totalNumberOfItems={totalNumberOfItems}
          setTotalNumberOfItems={setTotalNumberOfItems} // Pass setTotalNumberOfItems to ItemSection
          setTotalPrice={setTotalPrice}
          setDiscountedTotalPrice={setDiscountedTotalPrice}
          setSavedTotal={setSavedTotal}
          onItemRemoved={onItemRemoved}
        />
      </div>

      {/* Total price */}
      <div
        ref={containerRef}
        className="layout-right w-full md:w-1/4 sticky"
        style={{ top: "1rem" }}
      >
        <StickyElement
          scrollableHeight={scrollableHeight}
          isPoNumberVerified={isPoNumberVerified}
          userAccountInfo={userAccountInfo}
          cartItems={cartItemsArray}
          isPreorder={isPreorder}
          dropShip={dropShip}
          shippingInfo={shippingInfo}
          billingInfo={billingInfo}
          deliveryInfo={deliveryInfo}
          totalPrice={totalPrice}
          discountedTotalPrice={discountedTotalPrice}
          savedTotal={savedTotal}
          toggleCheckoutLoading={toggleCheckoutLoading}
        />
      </div>
    </div>
  );
}
