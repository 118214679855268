import Axios from "axios";
import {
  POST_BULKORDER_FAIL,
  POST_BULKORDER_REQUEST,
  POST_BULKORDER_SUCCESS,
  POST_ITEMVERIFICATION_FAIL,
  POST_ITEMVERIFICATION_REQUEST,
  POST_ITEMVERIFICATION_SUCCESS,
} from "../constants/bulkorderConstants";

const apiUrl = process.env.REACT_APP_API_URL;

// eslint-disable-next-line import/prefer-default-export
export const productValidation = (productInfo) => async (dispatch) => {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to the login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: POST_ITEMVERIFICATION_REQUEST, payload: tokenUserId });

  try {
    const response = await Axios.post(
      `${apiUrl}/bulkorder/verifyItems/${tokenUserId}`,
      productInfo,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );

    dispatch({ type: POST_ITEMVERIFICATION_SUCCESS, payload: response.data });

    return response; // Return the response data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: POST_ITEMVERIFICATION_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: POST_ITEMVERIFICATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    console.log("error : ", error);

    throw error; // Rethrow the error
  }
};

export const bulkCheckout = (orderInfo) => async (dispatch) => {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to the login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }
  console.log("tokenUserId", token);

  dispatch({ type: POST_BULKORDER_REQUEST, payload: tokenUserId });

  try {
    const response = await Axios.post(
      `${apiUrl}/bulkorder/checkout/${tokenUserId}`,
      orderInfo,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );

    dispatch({ type: POST_BULKORDER_SUCCESS, payload: response.data });

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: POST_BULKORDER_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: POST_BULKORDER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    console.log("error : ", error);

    throw error;
  }
};
