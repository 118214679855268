import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getInvoiceLine,
  exportInvoicePdf,
} from "../../../actions/invoiceAction";
import "../Dashboard.css";
import invoiceLogo from "../invoiceLogo.png";

export default function ViewInvoiceTab(props) {
  const { erpInvoiceId, invoiceNum } = props; // Destructure props for easier access

  //erpInvoiceId is transffered in props as a parameter from parent component (index)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Retrieve userInfo from Redux state
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  // if fail for user validation, redirect to login page
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login";

  //const erpInvoiceId = props.erpInvoiceId;
  //const invoiceNum = props.invoiceNum;
  console.log("erpInvoiceId", erpInvoiceId);
  console.log("invoiceNum", invoiceNum);

  const invoiceInfo = useSelector((state) => state.getInvoiceLine);
  const { loading, error, invoice } = invoiceInfo;

  const [invoiceHeader, setInvoiceHeader] = useState(invoice?.invoiceHeader);
  const [invoiceLine, setInvoiceLine] = useState(invoice?.invoiceLine);

  let poqtyAccumulated = 0;
  let shipqtyAccumulated = 0;
  let boqtyAccumulated = 0;

  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");
      navigate(redirect);
    } else {
      console.log("You are already logged in ");

      // Fetch invoice line details
      dispatch(getInvoiceLine(erpInvoiceId));
    }
  }, [dispatch, navigate, redirect, userInfo]);

  useEffect(() => {
    if (invoice) {
      setInvoiceHeader(invoice.invoiceHeader);
      setInvoiceLine(invoice.invoiceLine);
    }
  }, [invoice]);

  // change date form
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const handleButtonClick = () => {
    navigate("/myaccount#invoice");
  };

  const handleClickDownloadInvoice = (erpinvoiceid, invoicenum) => {
    dispatch(exportInvoicePdf(erpinvoiceid, invoicenum));
  };

  return (
    <>
      <div className="support-tab w-full ">
        {/* Exporting pdf file */}

        <div className=" w-full flex items-center space-x-4">
          <button
            type="button"
            className="text-lg font-semibold"
            onClick={handleButtonClick}
          >
            {"< BACK"}
          </button>

          {/*  Download invoice */}

          <div
            style={{
              color: "#fff",
              backgroundColor: "#04add1",
              padding: "8px 20px",
              border: "none",
              borderRadius: "5px",
              fontSize: "16px",
              textAlign: "center",
              cursor: "pointer",
              width: "200px",
              marginLeft: "auto",
              marginRight: "0",
              transition: "background-color 0.3s",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#008080")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#04add1")}
            onClick={() => handleClickDownloadInvoice(erpInvoiceId, invoiceNum)}
          >
            Download invoice
          </div>
        </div>

        <div className=" border p-4 mt-5 mb-5">
          {/* First table */}
          <table
            width="100%"
            border="0"
            cellPadding="5"
            className="invoice_table_border"
          >
            <tr>
              <td
                width="35%"
                height="80"
                valign="top"
                style={{
                  fontSize: "12px",
                  paddingTop: "20px",
                  paddingLeft: "0px",
                }}
              >
                Asti Manufacturing Corp., Inc. <br />
                13950 Senlac Drive Suite 300
                <br />
                Farmers Branch, TX 75234
                <br />
                Tel (800) 993-1841
                <br />
                Fax (972) 241-1184
                <br />
                E-mail: orders@maevnuniforms.com
                <br />
              </td>
              {/* Maevn logo */}
              <td width="30%" align="center" valign="middle">
                <img
                  src={invoiceLogo}
                  width="90"
                  height="82"
                  alt="Invoice Logo"
                />
              </td>
              <td width="35%" rowSpan="3" align="center" valign="top">
                <table
                  width="100%"
                  cellPadding="2"
                  cellSpacing="0"
                  style={{ border: "1px solid black" }}
                >
                  <tr style={{ border: "1px solid black" }}>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>Invoice#</strong>
                    </td>
                    <td>{invoiceHeader?.num}</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>Invoiced Date</strong>
                    </td>

                    <td style={{ fontSize: "10px" }}>
                      {invoiceHeader?.invoiceDate
                        ? formatDate(invoiceHeader.invoiceDate)
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>SO#</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.sonum}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>PO#</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.customerPo}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>Customer#</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.accountNum}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>Terms</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.paymentTerms}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>Due Date</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.dueDate
                        ? formatDate(invoiceHeader.dueDate)
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>ShipVia</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.shipVia}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>Freight BillTo</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.billTransportationTo}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>Courier Tracking#</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.trackingNumber}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>Shipped Date</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.shipDate
                        ? formatDate(invoiceHeader.shipDate)
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>Ship#</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.shipNum}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>CartonID</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      Please see Packing List
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>Sales Rep</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.salesPerson}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>SO Issued By</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.issuedBy}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>Picked By</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.pickedBy ? invoiceHeader?.pickedBy : ""}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        border: "1px solid black",
                      }}
                    >
                      <strong>Scanned By</strong>
                    </td>
                    <td style={{ fontSize: "10px", border: "1px solid black" }}>
                      {invoiceHeader?.scannedBy ? invoiceHeader?.scannedBy : ""}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        paddingLeft: "5px",
                        width: "35%",
                        border: "1px solid black",
                      }}
                    >
                      <strong>SO Issued Date</strong>
                    </td>
                    <td
                      style={{
                        fontSize: "10px",
                        width: "65%",
                        border: "1px solid black",
                      }}
                    >
                      {invoiceHeader?.issuedDate
                        ? formatDate(invoiceHeader.issuedDate)
                        : ""}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td
                style={{
                  paddingTop: "15px",
                  paddingLeft: "0px",
                  verticalAlign: "top",
                  height: "80px",
                }}
              >
                <strong>Bill To:</strong>
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    border: "1px solid lightgray",
                    borderColor: "lightgray",
                  }}
                >
                  {invoiceHeader?.billToName} <br />
                  {invoiceHeader?.billToAddress} <br />
                  {invoiceHeader?.billToCity} {invoiceHeader?.billToState}(
                  {invoiceHeader?.billToStateAbb}) {invoiceHeader?.billToZip}
                  <br />
                  {invoiceHeader?.billToCountry}
                </pre>
              </td>
              <td
                rowSpan="2"
                style={{
                  width: "35%",
                  textAlign: "center",
                  verticalAlign: "top",
                }}
              >
                <div className="view-invoice-title">
                  <h1>INVOICE</h1>
                </div>

                {/* You can replace the src with the actual image path */}
                <h1>
                  <img src="/path/to/barcode/image.png" alt="" />
                </h1>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  whiteSpace: "pre-wrap",
                  paddingLeft: "0px",
                  fontWeight: "bold",
                }}
              >
                <strong>Ship To:</strong>
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    fontFamily: "Arial",
                    fontWeight: "bold",
                    border: "1px solid lightgray",
                    borderColor: "lightgray",
                  }}
                >
                  {invoiceHeader?.shipToName} <br />
                  {invoiceHeader?.shipToAddress} <br />
                  {invoiceHeader?.shipToCity} {invoiceHeader?.shipToState}(
                  {invoiceHeader?.shipToStateAbb}) {invoiceHeader?.shipToZip}{" "}
                  <br />
                  {invoiceHeader?.shipToCountry}
                </pre>
              </td>
            </tr>
          </table>
          {/* second table  */}
          <table
            width="100%"
            border="1"
            cellPadding="0"
            cellSpacing="0"
            className="invoice_table_border"
          >
            <tbody>
              <tr>
                {/* Header */}
                <td
                  width="14%"
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  SKU
                </td>
                <td
                  width="20%"
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Description
                </td>
                <td
                  width="8%"
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  PO
                </td>
                <td
                  width="8%"
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Shipped
                </td>
                <td
                  width="8%"
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  B.O.
                </td>
                <td
                  width="8%"
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  UnitPrice
                </td>
                <td
                  width="8%"
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  DC%
                </td>
                <td
                  width="8%"
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  DC Amount
                </td>
                <td
                  width="8%"
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Final Amount
                </td>
                <td
                  width="10%"
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Note
                </td>
              </tr>
            </tbody>
            <tbody>
              {/* Invoice Lines */}
              {invoiceLine?.map((line, index) => {
                // 누적값 계산
                poqtyAccumulated += line.poQty;
                shipqtyAccumulated += line.shipQty;
                boqtyAccumulated += line.boQty;

                return (
                  <tr key={index}>
                    <td>{line.sku}</td>
                    <td>{line.shortDescription}</td>
                    <td>{line.poQty}</td>
                    <td>{line.shipQty}</td>
                    <td>{line.boQty}</td>
                    <td>{line.unitPrice}</td>
                    <td>{line.dcRate}%</td>
                    <td>{line.dcAmount}</td>
                    <td>{line.finalAmount}</td>
                    <td>{line.note}</td>
                  </tr>
                );
              })}

              {/* Subtotal */}
              <tr>
                <td colSpan="2">&nbsp;</td>
                <td>{poqtyAccumulated}</td>
                <td>{shipqtyAccumulated}</td>
                <td>{boqtyAccumulated}</td>
                <td colSpan="5">&nbsp;</td>
              </tr>

              {/* Note */}
              <tr>
                <td colSpan="5" rowSpan="9">
                  Note:
                  {invoiceHeader?.note ? invoiceHeader?.note : ""}
                </td>
                <td
                  colSpan="3"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  SubTotal USD:
                </td>

                <td>${invoiceHeader?.subTotalPrice}</td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <td
                  colSpan="3"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Shipping / Handling Cost:
                </td>
                <td>${invoiceHeader?.shipHandlingCost}</td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <td
                  colSpan="3"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Drop Ship Cost:
                </td>
                <td>${invoiceHeader?.dropShipCost}</td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <td
                  colSpan="3"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Wire Transfer Fee:
                </td>
                <td>${invoiceHeader?.wireTransCost}</td>
                <td>&nbsp;</td>
              </tr>

              <tr>
                <td
                  colSpan="3"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Value Added Service:
                </td>
                <td>${invoiceHeader?.valueServiceCost}</td>
                <td>&nbsp;</td>
              </tr>

              {/* Totals */}
              <tr>
                <td
                  colSpan="3"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Total USD:
                </td>
                <td>${invoiceHeader?.totalAmount}</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td
                  colSpan="3"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Credit/Debit:
                </td>
                <td>${invoiceHeader?.appliedAmount}</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td colSpan="3" style={{ textAlign: "right" }}>
                  <span style={{ fontSize: "9px" }}>
                    {"Please Pay This Amount => "}
                  </span>

                  <span style={{ fontWeight: "bold" }}>Balance Due:</span>
                </td>
                <td>${invoiceHeader?.balanceDue}</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
          {/* last table */}
          <table width="100%" border="0" className="table-footer-info">
            <tbody>
              <tr>
                <td align="left" width="55%" valign="top">
                  <strong>DIRECT ALL INQUIRIES TO:</strong>
                  <br />
                  Customer Relations
                  <br />
                  (800) 993-1841
                </td>
                <td align="left" width="45%" valign="top">
                  <strong>
                    Please Make Checks Payable To: Maevn Corporation
                    <br />
                    Mail To: 13950 Senlac Drive Suite 300,
                    <br />
                    Farmers Branch TX 75234
                    <br />
                    <br />
                    Wire To: Asti Manufacturing Corp, Inc.
                    <br />
                    Beneficiary Bank: TEXAS CAPITAL BANK
                    <br />
                    Swift code: TXCBUS44
                    <br />
                    ABA #: 111017979
                    <br />
                    Account #: 1211093958
                    <br />
                    Bank Address : 2350 Lakeside Drive, Suite 400, Richardson,
                    TX 75082
                    <br />
                    Tel. 972-656-6529
                    <br />
                    Email: hollis.sullivan@texascapitalbank.com
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* move to back button */}
        <div className="flex justify-end">
          <div className="w-[100px] h-[40px] mt-4 mb-4 float-right">
            <button
              type="button"
              className="yellow-btn"
              onClick={handleButtonClick}
            >
              <div className="w-full text-sm font-semibold"> {"< BACK"} </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
