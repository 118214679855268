export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PROMOTION_ITEMS_REQUEST = "PROMOTION_ITEMS_REQUEST";
export const PROMOTION_ITEMS_SUCCESS = "PROMOTION_ITEMS_SUCCESS";
export const PROMOTION_ITEMS_FAIL = "PROMOTION_ITEMS_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";
