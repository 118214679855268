import {
  GET_SUBUSERLIST_REQUEST,
  GET_SUBUSERLIST_SUCCESS,
  GET_SUBUSERLIST_FAIL,
  POST_SUBUSER_REQUEST,
  POST_SUBUSER_SUCCESS,
  POST_SUBUSER_FAIL,
  EDIT_SUBUSER_REQUEST,
  EDIT_SUBUSER_SUCCESS,
  EDIT_SUBUSER_FAIL,
  GET_SUBUSER_REQUEST,
  GET_SUBUSER_SUCCESS,
  GET_SUBUSER_FAIL,
  PUT_STATUS_REQUEST,
  PUT_STATUS_SUCCESS,
  PUT_STATUS_FAIL,
} from "../constants/subuserConstants";

// eslint-disable-next-line import/prefer-default-export
export const getSubuserListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUBUSERLIST_REQUEST:
      return { loading: true };
    case GET_SUBUSERLIST_SUCCESS:
      return { loading: false, subusers: action.payload };
    case GET_SUBUSERLIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const changeSubuserStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_STATUS_REQUEST:
      return { loading: true };
    case PUT_STATUS_SUCCESS:
      return { loading: false, result: action.payload };
    case PUT_STATUS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getSubuserReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUBUSER_REQUEST:
      return { loading: true };
    case GET_SUBUSER_SUCCESS:
      return { loading: false, subuser: action.payload };
    case GET_SUBUSER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const editSubuserReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_SUBUSER_REQUEST:
      return { loading: true };
    case POST_SUBUSER_SUCCESS:
      return { loading: false, subuser: action.payload };
    case POST_SUBUSER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
