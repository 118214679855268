import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import BreadcrumbCom from "../../BreadcrumbCom";
import EmptyCardError from "../../EmptyCardError";
import PageTitle from "../../Helpers/PageTitle";
import Layout from "../../Partials/Layout";
import RoleTable from "./RoleTable";
import { getRoles } from "../../../actions/roleAction";

export default function CustomerRoles({ cart = true }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // get user
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  const { search } = useLocation(); //search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login"; // check if redirect url exist

  // get roles
  const customerRoles = useSelector((state) => state.getRoles);
  const { loading, error, roles } = customerRoles; //payload로 받은 데이터를 customerRoles.roles 안에 저장 (data from API)

  const adminUserId = roles?.result.adminUserId;
  const userId = roles?.result.userId;
  const roleList = roles?.result.roleList;

  // user info
  const loginId = userInfo?.result.user.loginId;
  const userLevel = userInfo?.result.user.parentAccount;
  const userEmail = userInfo?.result.user.contactEmail;

  // get login info
  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");
      navigate(redirect);
    } else {
      console.log("You are already logined ");
    }
    dispatch(getRoles());
  }, [dispatch, userInfo, navigate, redirect]);

  /*
  // for data log from API 
  useEffect(() => {
    console.log("roles?.result: ", roles?.result);
    console.log("adminUserId: ", adminUserId);
    console.log("userId: ", userId);
    console.log("roleList: ", roleList);
  }, [roles]);
 */

  return (
    <Layout childrenClasses={cart ? "pt-0 pb-0" : ""}>
      {cart === false ? (
        <div className="cart-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom
              paths={[
                { name: "home", path: "/" },
                { name: "User Roles", path: "/customerroles" },
              ]}
            />
            <EmptyCardError />
          </div>
        </div>
      ) : (
        <div className="cart-page-wrapper w-full bg-white pb-[60px]">
          <div className="w-full h-25">
            <PageTitle
              title="User Roles"
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "User Roles", path: "/customerroles" },
              ]}
            />
          </div>

          <div className="w-full mt-[23px]">
            <div className="container-x mx-auto">
              <RoleTable
                isLoading={loading}
                adminUserId={adminUserId}
                userId={userId}
                roleList={roleList}
                loginId={loginId}
                userLevel={userLevel}
                userEmail={userEmail}
              />
              <div className="w-full mt-[30px] flex sm:justify-end"></div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
