import React from "react";
import InputCom from "../../../Helpers/InputCom";

export default function UserInformation({
  companyName,
  userName,
  contactEmail,
  paymentInfoTmp,
  setPaymentInfoTmp,
  warnings,
}) {
  const handleInputChange = (field, value) => {
    setPaymentInfoTmp({
      ...paymentInfoTmp,
      [field]: value,
    });
  };

  return (
    <>
      <div
        className="flex space-x-3 items-center "
        style={{
          marginBottom: "20px",
          borderBottom: "1px solid lightgray",
        }}
      >
        <span
          className="text-2xl font-bold payment-subtitle"
          style={{ color: "#000000" }}
        >
          Your Information
        </span>
      </div>
      <div
        className="input-item mb-8"
        style={{
          marginBottom: "30px",
          border: "1px solid lightgray",
          padding: "25px 20px 5px 20px",
        }}
      >
        <div className="w-4/5 flex" style={{ marginBottom: "20px" }}>
          <span
            className="mr-4 flex-none  flex items-center text-sm  font-semibold"
            style={{ width: "80px" }}
          >
            Company{" "}
          </span>
          <InputCom
            placeholder={companyName}
            type="text"
            inputClasses="h-[30px]"
            isReadOnly={true}
          />
        </div>

        <div className="w-4/5 flex" style={{ marginBottom: "20px" }}>
          <span
            className="mr-4 flex-none  flex items-center text-sm font-semibold"
            style={{ width: "80px" }}
          >
            Account#
          </span>
          <InputCom
            placeholder={userName}
            type="text"
            inputClasses="h-[30px]"
            isReadOnly={true}
          />
        </div>

        <div className="w-4/5 flex" style={{ marginBottom: "20px" }}>
          <span
            className="mr-4 flex-none  flex items-center text-sm  font-semibold"
            style={{ width: "80px" }}
          >
            Email
          </span>
          <InputCom
            placeholder={contactEmail}
            type="text"
            inputClasses="h-[30px]"
            isReadOnly={true}
          />
        </div>
      </div>
    </>
  );
}
