import { Link } from "react-router-dom";
import Facebook from "../../../Helpers/icons/Facebook";
import Instagram from "../../../Helpers/icons/Instagram";
import Youtube from "../../../Helpers/icons/Youtube";
import Visa from "../../../../images/creditcard_visa.svg";
import Master from "../../../../images/creditcard_mastercard.svg";
import Amex from "../../../../images/creditcard_amex.svg";

export default function Footer() {
  return (
    <footer className="footer-section-wrapper bg-white print:hidden">
      <div
        className="container-x block mx-auto pt-[30px]"
        style={{ fontFamily: "'Roboto', sans-serif", fontSize: "14px" }}
      >
        {/* logo area  
        <div className="w-full flex flex-col items-center mb-[50px]"> 
          <div className="mb-[40px]">
            <a href="/">
              <img
                //width="152"
                //height="36"
                //src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
                width="200"
                height="70"
                src={`https://maevn.s3.us-east-2.amazonaws.com/banners/maevnLogo.PNG`}
                alt="logo"
              />
            </a>
          </div>
          <div className="w-full h-[1px] bg-[#E9E9E9]"></div>
        </div>
      */}

        <div className="lg:flex justify-between mb-[50px]">
          {/* Original footer */}
          {/*
           <div className="lg:w-[424px]  ml-0 w-full mb-10 lg:mb-0 text-[15px] ">
            <div className="mb-5">
              <h1 className="text-[15px] font-500 text-[#2F2F2F] mb-3">
                MAEVN UNIFORMS
              </h1>
            </div>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                version="1.1"
                id="Capa_1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 466.583 466.582"
                className="h-[18px] w-auto text-black mr-2"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <path d="M233.292,0c-85.1,0-154.334,69.234-154.334,154.333c0,34.275,21.887,90.155,66.908,170.834 c31.846,57.063,63.168,104.643,64.484,106.64l22.942,34.775l22.941-34.774c1.317-1.998,32.641-49.577,64.483-106.64 c45.023-80.68,66.908-136.559,66.908-170.834C387.625,69.234,318.391,0,233.292,0z M233.292,233.291c-44.182,0-80-35.817-80-80 s35.818-80,80-80c44.182,0,80,35.817,80,80S277.473,233.291,233.292,233.291z"></path>
                  </g>
                </g>
              </svg>

              <p className="text-[#9A9A9A] text-[15px] w-[247px] leading-[28px]">
                13950 Senlac Dr. Suite #300
              </p>
            </div>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-[18px] w-auto text-black mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
              {/* Add your SVG path here */}
          {/* 
           </svg>
              <p className="text-[#9A9A9A] text-[15px] w-[247px] leading-[28px]">
                Farmers Branch, TX 75234
              </p>
            </div>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-[18px] w-auto text-black mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              > 
              */}
          {/* Add your SVG path here */}
          {/*  
          </svg>
              <p className="text-[#9A9A9A] text-[15px] w-[247px] leading-[28px]">
                United States
              </p>
            </div>

            <div className="flex items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                id="Capa_1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                viewBox="0 0 24 24"
                className="h-[18px] w-auto mr-2"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <path d="M6.62,10.79a15.66,15.66,0,0,0,6.09,6.09l2.11-2.11a1.5,1.5,0,0,1,1.7-.36,11.55,11.55,0,0,0,4.33.83,1.5,1.5,0,0,1,1.5,1.5v3.28a1.5,1.5,0,0,1-1.65,1.5A19.5,19.5,0,0,1,2,4.15,1.5,1.5,0,0,1,3.5,2.5H6.78a1.5,1.5,0,0,1,1.5,1.5,11.55,11.55,0,0,0,.83,4.33,1.5,1.5,0,0,1-.36,1.7Z" />
                  </g>
                </g>
              </svg>

              <p className="text-[#9A9A9A] text-[15px] w-[247px] leading-[28px]">
                800-993-1841
              </p>
            </div>

            <div className="flex items-center">
              <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                id="open-envelop"
                fill="#000000"
                className="h-[18px] w-auto mr-2"
              >
                <g id="SVGRepo_bgCarrier"></g>
                <g id="SVGRepo_tracerCarrier"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M18,4v6.76l-1.55,1.08L12,14.8,8,12.13l-.43-.28L6,10.76V4A2,2,0,0,1,8,2h8A2,2,0,0,1,18,4Z"></path>
                  <path d="M22,10.4v9.68A2,2,0,0,1,20,22H4a2,2,0,0,1-2-1.92V10.4l.13.09.7.49L4,11.79,6.45,13.5l5,3.33a.2.2,0,0,0,.08,0h0a.35.35,0,0,0,.14.06l0,0h.06a.82.82,0,0,0,.44,0h.06l0,0a.35.35,0,0,0,.14-.06h0a.2.2,0,0,0,.08,0l5-3.33h0L20,11.8,21.18,11l.69-.49Z"></path>
                  <polygon points="6 6.5 6 9 4.79 9.91 3.06 8.71 6 6.5"></polygon>
                  <polygon points="20.94 8.71 19.21 9.91 18 9 18 6.5 20.94 8.71"></polygon>
                </g>
              </svg>
              <p className="text-[#9A9A9A] text-[15px] w-[247px] leading-[28px]">
                itsupport@maevnuniforms.com
              </p>
            </div>
          </div>     */}

          <div className="flex-1 lg:flex">
            <div className="lg:w-1/2 w-full mb-10 lg:mb-0">
              <div className="mb-5">
                <h6 className="text-[18] font-500 text-[#2F2F2F]">
                  MAEVN UNIFORMS
                </h6>
              </div>
              <div>
                <ul className="flex flex-col space-y-2.5 ">
                  <li>
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                        version="1.1"
                        id="Capa_1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 466.583 466.582"
                        className="h-[18px] w-auto text-black mr-2"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <g>
                            <path d="M233.292,0c-85.1,0-154.334,69.234-154.334,154.333c0,34.275,21.887,90.155,66.908,170.834 c31.846,57.063,63.168,104.643,64.484,106.64l22.942,34.775l22.941-34.774c1.317-1.998,32.641-49.577,64.483-106.64 c45.023-80.68,66.908-136.559,66.908-170.834C387.625,69.234,318.391,0,233.292,0z M233.292,233.291c-44.182,0-80-35.817-80-80 s35.818-80,80-80c44.182,0,80,35.817,80,80S277.473,233.291,233.292,233.291z"></path>
                          </g>
                        </g>
                      </svg>

                      <p className="text-[#9A9A9A] text-[15px] w-[247px] leading-[28px]">
                        13950 Senlac Dr. Suite #300
                      </p>
                    </div>
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-[18px] w-auto text-black mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        {/* Add your SVG path here */}
                      </svg>
                      <p className="text-[#9A9A9A] text-[15px] w-[247px] leading-[28px]">
                        Farmers Branch, TX 75234
                      </p>
                    </div>
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-[18px] w-auto text-black mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        {/* Add your SVG path here */}
                      </svg>
                      <p className="text-[#9A9A9A] text-[15px] w-[247px] leading-[28px]">
                        United States
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                        id="Capa_1"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        viewBox="0 0 24 24"
                        className="h-[18px] w-auto mr-2"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <g>
                            <path d="M6.62,10.79a15.66,15.66,0,0,0,6.09,6.09l2.11-2.11a1.5,1.5,0,0,1,1.7-.36,11.55,11.55,0,0,0,4.33.83,1.5,1.5,0,0,1,1.5,1.5v3.28a1.5,1.5,0,0,1-1.65,1.5A19.5,19.5,0,0,1,2,4.15,1.5,1.5,0,0,1,3.5,2.5H6.78a1.5,1.5,0,0,1,1.5,1.5,11.55,11.55,0,0,0,.83,4.33,1.5,1.5,0,0,1-.36,1.7Z" />
                          </g>
                        </g>
                      </svg>

                      <p className="text-[#9A9A9A] text-[15px] w-[247px] leading-[28px]">
                        800-993-1841
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        id="open-envelop"
                        fill="#000000"
                        className="h-[18px] w-auto mr-2"
                      >
                        <g id="SVGRepo_bgCarrier"></g>
                        <g id="SVGRepo_tracerCarrier"></g>
                        <g id="SVGRepo_iconCarrier">
                          <path d="M18,4v6.76l-1.55,1.08L12,14.8,8,12.13l-.43-.28L6,10.76V4A2,2,0,0,1,8,2h8A2,2,0,0,1,18,4Z"></path>
                          <path d="M22,10.4v9.68A2,2,0,0,1,20,22H4a2,2,0,0,1-2-1.92V10.4l.13.09.7.49L4,11.79,6.45,13.5l5,3.33a.2.2,0,0,0,.08,0h0a.35.35,0,0,0,.14.06l0,0h.06a.82.82,0,0,0,.44,0h.06l0,0a.35.35,0,0,0,.14-.06h0a.2.2,0,0,0,.08,0l5-3.33h0L20,11.8,21.18,11l.69-.49Z"></path>
                          <polygon points="6 6.5 6 9 4.79 9.91 3.06 8.71 6 6.5"></polygon>
                          <polygon points="20.94 8.71 19.21 9.91 18 9 18 6.5 20.94 8.71"></polygon>
                        </g>
                      </svg>
                      <p className="text-[#9A9A9A] text-[15px] w-[247px] leading-[28px]">
                        itsupport@maevnuniforms.com
                      </p>
                    </div>
                  </li>
                  {/*  
                  <li>
                    <a href="/all-products">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Best Products
                      </span>
                    </a>
                  </li>
                */}
                </ul>
              </div>
            </div>
            <div className="lg:w-1/3 w-full mb-10 lg:mb-0">
              <div className="mb-5">
                <h6 className="text-[18] font-500 text-[#2F2F2F]">CUSTOMER</h6>
              </div>
              <div>
                <ul className="flex flex-col space-y-2.5 ">
                  <li>
                    <a href="/myaccount">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        My Account
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/myaccount#order">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Order History
                      </span>
                    </a>
                  </li>
                  {/* <li>
                    <a href="/myaccount#wishlist">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        My Wishlist
                      </span>
                    </a>
                  </li>
                 */}
                  <li>
                    <a href="/terms-conditions">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Terms and Conditions
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/privacy-policy">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Privacy Policy
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="/return-policy">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Return Policy
                      </span>
                    </a>
                  </li>
                  {/*  
                  <li>
                    <a href="/all-products">
                      <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                        Best Products
                      </span>
                    </a>
                  </li>
                */}
                </ul>
              </div>
            </div>
            <div className="lg:w-1/3 lg:flex lg:flex-col items-center w-full mb-10 lg:mb-0 ">
              <div>
                <div className="mb-5">
                  <h6 className="text-[18] font-500 text-[#2F2F2F]">COMPANY</h6>
                </div>
                <div>
                  <ul className="flex flex-col space-y-2.5 ">
                    <li>
                      <a href="/about">
                        <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          About Us
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/careers">
                        <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Careers
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/faq">
                        <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          FAQ
                        </span>
                      </a>
                    </li>
                    <li>
                      <a href="/contact">
                        <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Contact Us
                        </span>
                      </a>
                    </li>
                    {/* 
                    <li>
                      <a href="/blogs">
                        <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Cummunity Page
                        </span>
                      </a>
                    </li>
            
                    <li>
                      <a href="/tracking-order">
                        <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Tracking Order
                        </span>
                      </a>
                    </li>
                  
                    <li>
                      <a href="/become-saller">
                        <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Become Seller
                        </span>
                      </a>
                    </li>
                      */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 lg:flex lg:flex-col items-center w-full mb-10 lg:mb-0">
              <div>
                <div className="mb-5">
                  <h6 className="text-[18] font-500 text-[#2F2F2F]">
                    ACCEPTED CARDS
                  </h6>
                </div>
                <div>
                  <ul className="flex flex-row justify-center space-x-2.5">
                    {/*  
                   <li>
                      <a href="/flash-sale">
                        <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Flash Sale
                        </span>
                      </a>
                    </li>
                  */}
                    {/*
                    <li>
                      <a href="/about">
                        <span className="text-[#9A9A9A] text-[15px] hover:text-qblack border-b border-transparent hover:border-qblack cursor-pointer capitalize">
                          Support
                        </span>
                      </a>
                    </li>
                 */}

                    {/* Visa */}
                    <img src={Visa} alt="Visa" width="40" height="24" />

                    {/* Mastercard */}
                    <img src={Master} alt="Visa" width="40" height="24" />

                    {/* American Express */}
                    <img src={Amex} alt="Visa" width="40" height="24" />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-bar border-t border-qgray-border lg:h-[60px] lg:flex justify-center items-center">
          {/* 
         <div className="flex lg:space-x-5 justify-between items-center mb-3">
            <div className="flex space-x-5 items-center">
              <a href="#">
                <Instagram className="fill-current text-qgray hover:text-qblack" />
              </a>
              <a href="#">
                <Facebook className="fill-current text-qgray hover:text-qblack" />
              </a>
              <a href="#">
                <Youtube className="fill-current text-qgray hover:text-qblack" />
              </a>
            </div>
            <span className="sm:text-base text-[10px] text-qgray font-300">
              ©2022
              <a
                href="https://quomodosoft.com/"
                target="_blank"
                rel="noreferrer"
                className="font-500 text-qblack mx-1"
              >
                Quomodosoft
              </a>
              All rights reserved
            </span>
          </div>
        
          <div className="">
            <a href="#">
              <img
                width="318"
                height="28"
                src={`${process.env.PUBLIC_URL}/assets/images/payment-getways.png`}
                alt="payment-getways"
              />
            </a>
          </div>
            */}

          <span
            className="sm:text-base text-[14px] text-qgray font-250"
            style={{ fontFamily: "'Roboto', sans-serif", fontSize: "14px" }}
          >
            © 2024 Maevn Uniforms. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
}
