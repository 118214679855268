import {
  GET_ROLE_REQUEST,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAIL,
  POST_ROLE_REQUEST,
  POST_ROLE_SUCCESS,
  POST_ROLE_FAIL,
  EDIT_ROLE_REQUEST,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAIL,
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAIL,
} from "../constants/roleContants";

// eslint-disable-next-line import/prefer-default-export
export const getRolesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ROLE_REQUEST:
      return { loading: true };
    case GET_ROLE_SUCCESS:
      return { loading: false, roles: action.payload };
    case GET_ROLE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const addRolesReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_ROLE_REQUEST:
      return { loading: true };
    case POST_ROLE_SUCCESS:
      return { loading: false, roles: action.payload };
    case POST_ROLE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const editRolesReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_ROLE_REQUEST:
      return { loading: true };
    case EDIT_ROLE_SUCCESS:
      return { loading: false, roles: action.payload };
    case EDIT_ROLE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getPermissionsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PERMISSIONS_REQUEST:
      return { loading: true };
    case GET_PERMISSIONS_SUCCESS:
      return { loading: false, getPermissions: action.payload };
    case GET_PERMISSIONS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
