import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import "../../OrderProcess/Orders/OrderHistory.css";
import SecondaryButton from "../../Helpers/Buttons/SecondaryButton";
import PrimaryButton from "../../Helpers/Buttons/PrimaryButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import styled from "@emotion/styled";
import styles from "../../../components/ProductDetailPage/ProductDetail.module.css";
import OrderDetails from "../../OrderProcess/OrderDetails/OrderDetails";
import { useDispatch } from "react-redux";
import { bulkCheckout } from "../../../actions/bulkorderAction";
import Spinner from "../../Helpers/Loaders/Spinner";

function decodeHTMLEntities(text) {
  var txt = document.createElement("textarea");
  txt.innerHTML = text;
  return txt.value;
}

export default function BulkOrderInfo({ className, orderInfo, orderLine }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function formatCurrentDate() {
    const date = new Date();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  /* Dialog state */
  const [orderConfirmModalOpen, setOrderConfirmModalOpen] = useState(false);
  const [isPlacedAnOrder, setIsPlacedAnOrder] = useState(false);
  const [isOrderSuccess, setIsOrderSuccess] = useState("");

  /* Dialog design */
  const StyledDialogContent = styled(DialogContent)``;
  const StyledDialogActions = styled(DialogActions)`
    display: flex;
    justify-content: space-evenly;
    padding: 15px;
  `;
  const StyledDialog = styled(Dialog)`
    & .MuiDialog-paper {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      padding: 30px;
      border-radius: 10px;
      background: var(--Base-White, #fff);
    }
  `;

  // Spinner checkout loading
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const toggleCheckoutLoading = (isLoading) => {
    setLoadingCheckout(isLoading);
  };

  // Dialog buttons
  const handleCancelCheckout = () => {
    navigate(-1);
  };

  const handlePlaceAnOrder = () => {
    setOrderConfirmModalOpen(true);
  };

  const handleProceedCheckout = () => {
    setOrderConfirmModalOpen(false);

    console.log("orderInfo : ", orderInfo);

    // Turn on loading spinner
    toggleCheckoutLoading(true);

    // verify PO#
    if (
      orderInfo.CustomerPO === null ||
      orderInfo.CustomerPO === undefined ||
      orderInfo.CustomerPO === ""
    ) {
      return alert("Please enter a PO Number");
    }

    // verify addresses
    const requiredFields = [
      { value: orderInfo.ErpBillToAddressId, fieldName: "Billing Address ID" },
      { value: orderInfo.ErpShipToAddressId, fieldName: "Shipping Address ID" },
      { value: orderInfo.ShipToName, fieldName: "Name" },
      { value: orderInfo.ShipToAddress1, fieldName: "Address1" },
      { value: orderInfo.ShipToCity, fieldName: "City" },
      { value: orderInfo.ShipToCountryId, fieldName: "Country" },
    ];

    // Accumulate the names of empty shipping fields
    const emptyFields = requiredFields
      .filter((field) => !field.value && !orderInfo.IsDropShip) // Skip check if IsDropShip is true
      .map((field) => field.fieldName);

    if (emptyFields.length > 0) {
      const fieldNames = emptyFields.join(", ");
      toggleCheckoutLoading(false); // Ensure the spinner is stopped on validation error
      return alert(
        `It looks like your shipping information isn’t correct.\nPlease get in touch with our customer service team for assistance.\n\nEmpty fields : ${fieldNames}.\n`
      );
    }

    // Call order API
    dispatch(bulkCheckout(orderInfo))
      .then((response) => {
        // Turn off loading spinner after API response
        toggleCheckoutLoading(false);

        if (response.data.statusCode === 200) {
          // Show the success message after the spinner disappears
          setIsOrderSuccess(
            "Your order is successfully submitted. You will be receiving a packing list with your tracking number within 72 hours."
          );

          setIsPlacedAnOrder(true); // Show the success message
        }
      })
      .catch((error) => {
        // Turn off loading spinner on error
        toggleCheckoutLoading(false);

        if (error.response.data.statusCode === 200) {
          alert("Your order was successful! (error)");
        } else if (error.response.data.statusCode === 400) {
          alert(
            "Your PO number is duplicated. Please enter your PO number again."
          );
          // Handle the 400 error as needed
        } else if (error.response.data.statusCode === 500) {
          alert(
            "Please check your items you have been puchasing. Or something went wrong with your order confirmation. Please contact to our customer service team."
          );
          // Handle the 500 error as needed
        } else {
          alert(
            "An unexpected error occurred. Please contact to our customer service team."
          );
          console.log("Error:", error);
        }
      });

    setIsPlacedAnOrder(true);
  };

  // Total quantity to order
  const calculateTotalQty = () => {
    if (!orderLine || orderLine.length === 0) return 0;

    console.log("orderLine.length:", orderLine.length);

    return orderLine.reduce((total, item) => {
      // Safely convert qty to a number or default to 0
      const subQty = parseInt(item.qty, 10) || 0;
      return total + subQty;
    }, 0);
  };

  const totalQty = calculateTotalQty();

  // Function to sum subTotal values
  const calculateTotal = () => {
    if (!orderLine || orderLine.length === 0) return 0;

    return orderLine
      .reduce((total, item) => {
        // Check if subTotal exists and convert to a number safely
        const subTotalValue = item.subTotal ? parseFloat(item.subTotal) : 0;

        // Ensure that subTotalValue is a valid number
        return total + (!Number.isNaN(subTotalValue) ? subTotalValue : 0);
      }, 0)
      .toFixed(2); // Round to 2 decimal places
  };

  const total = calculateTotal();

  const handleStayHere = () => {
    setOrderConfirmModalOpen(false);
  };

  const handleCloseOrderConfirm = () => {
    setIsPlacedAnOrder(false);
    navigate(`/`);
  };

  return (
    <div
      className={`w-full ${className || ""} `}
      style={{ fontFamily: "Arial, sans-serif" }}
    >
      <div className="flex-1  mt-8  ">
        {loadingCheckout && <Spinner />}

        {/* This line will render the spinner when loadingCheckout is true */}
        {/* Show order details and confirmation prompt only if loadingCheckout is false */}
        {!loadingCheckout && (
          <>
            <h1 className="thank-you-message">
              <strong>
                {" "}
                Please confirm your order items before placing your order.
              </strong>
            </h1>
            <h1>
              A confirmation email will be sent to
              <span style={{ color: "#04add1" }}>
                {" "}
                {orderInfo?.contactEmail}{" "}
              </span>
              after your purchase.
            </h1>

            {/* Last confirmation alert for placing an order */}
            <Dialog
              open={orderConfirmModalOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="xs"
              fullWidth
            >
              <StyledDialogContent
                style={{ paddingTop: "0", paddingBottom: "0" }}
              >
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ textAlign: "center", lineHeight: "80px" }}
                >
                  Are you sure you want to place this order?
                </DialogContentText>
              </StyledDialogContent>

              <StyledDialogActions
                style={{ marginTop: "0", justifyContent: "flex-end" }}
              >
                <SecondaryButton
                  width="180px"
                  height="40px"
                  fontSize="16px"
                  onClick={handleStayHere}
                >
                  Stay here
                </SecondaryButton>

                <PrimaryButton
                  width="180px"
                  height="40px"
                  fontSize="16px"
                  onClick={handleProceedCheckout}
                >
                  Place an order
                </PrimaryButton>
              </StyledDialogActions>
            </Dialog>
          </>
        )}
      </div>

      {/* Order success alert */}
      {isPlacedAnOrder && (
        <StyledDialog
          open={isPlacedAnOrder}
          onClose={handleCloseOrderConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs" // Limit the width of the dialog
          fullWidth
        >
          <StyledDialogContent>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "9px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
                  fill="rgba(4, 173, 209, 0.80)"
                />
              </svg>
              <div
                style={{
                  width: "225px",
                  color: "var(--Base-Black, #000)",
                  fontFamily: "Roboto",
                  fontSize: "24px",
                  fontWeight: "500",
                }}
              >
                Thank you!
              </div>
            </div>
            <div
              style={{
                width: "325px",
                marginTop: "15px",
                color: "var(--Body-txt-Grey, #474747)",
                fontFamily: "Lato",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "22px",
              }}
            >
              {isOrderSuccess}
            </div>
          </StyledDialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <PrimaryButton
              width="121px"
              height="40px"
              fontSize="16px"
              onClick={handleCloseOrderConfirm}
            >
              Confirm
            </PrimaryButton>
          </div>
        </StyledDialog>
      )}

      {/* Order details */}
      <div>
        <div className="order-details-container">
          <h2 className="order-details-text">Order Details</h2>
        </div>
      </div>
      <div className="bulk-details-container">
        <div className="detail-item">
          <p className="detail-item-title-text">Ordered by</p>
          <p className="detail-item-detail-text">{orderInfo?.accountNumber}</p>
        </div>
        <div className="detail-item">
          <p className="detail-item-title-text">PO number</p>
          <p className="detail-item-detail-text">{orderInfo?.CustomerPO}</p>
        </div>
        <div className="detail-item">
          <p className="detail-item-title-text">Ordering on</p>
          <p className="detail-item-detail-text">{formatCurrentDate()}</p>
        </div>
        {/*  <div className="detail-item">
          <p className="detail-item-title-text">Order Status</p>
          <p className="detail-item-detail-text">
            {getStatusDescription(orderStatus)}  
            Opened
          </p>
        </div> */}
      </div>

      {/* delivery service */}
      <div className="delivery-service-container">
        <div className="bulk-delivery-service-block">
          <div className="Request-delivery-service-title">
            Request delivery service
          </div>
          <div className="bulk-delivery-service-detail">
            <p className="carrier-text">
              Carrier:{" "}
              <span className="carrier-service-text">
                {orderInfo?.CarrierName || " - "}
              </span>
            </p>
            <p className="carrier-text">
              Carrier Service:{" "}
              <span className="carrier-service-text">
                {orderInfo?.ServiceName || " - "}
              </span>
            </p>
          </div>
        </div>
        <div className="delivery-service-block">
          <div className="Request-delivery-service-title">
            Optional delivery service
          </div>
          <div className="bulk-delivery-service-detail">
            <p className="carrier-text">
              Requested Ship Date:{" "}
              <span className="carrier-service-text">
                {orderInfo?.RequestedShipDate || " - "}
              </span>
            </p>
            <p className="carrier-text">
              Note:{" "}
              <span className="carrier-service-text">
                {orderInfo?.Note || " - "}
              </span>
            </p>{" "}
            <p className="carrier-text">
              Signature Service:{" "}
              <span className="carrier-service-text">
                {orderInfo?.SignatureService === 0
                  ? " - "
                  : orderInfo?.SignatureService
                  ? "YES"
                  : " - "}
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* Addresses*/}
      <div className="flex space-x-10">
        {/* shipping */}
        <div>
          <div className="order-address-header">
            <div className="order-text-container">
              <p className="order-header-text ">
                Shipping address{" "}
                {orderInfo?.IsDropShip === 1 && (
                  <span className="order-detail-drop-ship">(DropShip)</span>
                )}{" "}
              </p>
            </div>
          </div>

          <div className="order-address-details">
            <div className="order-details-text">
              <p className="order-recipient-name">
                {" "}
                {orderInfo?.ShipToName || "Not Provided"}
              </p>
              <br />
              <p className="order-recipient-address">
                {orderInfo?.ShipToAddress1 || ""},{" "}
                {orderInfo?.ShipToAddress2
                  ? `${orderInfo.ShipToAddress2}, `
                  : " "}
                {orderInfo?.ShipToCity || ""} <br /> <br />
                {orderInfo?.StateName || ""}, {orderInfo?.ShipToZipcode || ""}{" "}
              </p>
            </div>
          </div>
        </div>
        {/* billing */}
        <div>
          <div className="order-address-header">
            <div className="order-text-container">
              <p className="order-header-text "> Billing address </p>
            </div>
          </div>
          <div className="order-address-details">
            <div className="order-details-text">
              <p className="order-recipient-name">
                {" "}
                {orderInfo?.BillToName || "Not Provided"}
              </p>
              <br />
              <p className="order-recipient-address">
                {orderInfo?.BillToAddress1 || ""},{" "}
                {orderInfo?.BillToAddress2
                  ? `${orderInfo.BillToAddress2}, `
                  : " "}
                {orderInfo?.BillToCity || ""}
                <br /> <br />
                {orderInfo?.BillToStateName || ""},{" "}
                {orderInfo?.BillToCountryName || ""},{" "}
                {orderInfo?.BillToZipcode || ""}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* items */}
      <div className="w-full px-10 py-[30px] border border-[#EDEDED]  mt-10">
        <div className="sub-total mb-6">
          <div className=" flex justify-between mb-5">
            <p className="text-[13px] font-medium text-qblack uppercase">
              Product
            </p>

            <p className="text-[13px] font-medium text-qblack uppercase">
              Sub total
            </p>
          </div>
          <div className="w-full h-[1px] bg-[#EDEDED]"></div>
        </div>

        {orderLine &&
          orderLine.map((item, index) => {
            /* decodeHTMLEntities */
            const decodedDescription = decodeHTMLEntities(
              item.shortDescription
            );

            return (
              <div className="product-list w-full mb-[28px]" key={index}>
                <ul className="flex flex-col space-y-5">
                  <li>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center space-x-2">
                        {/* <img
                          src={`https://maevn-images.s3.us-east-2.amazonaws.com/110x110/${
                            item.productLine
                              ? item.productLine.replace(/\s+/g, "")
                              : "defaultProductLine"
                          }/${item.styleNumber || "defaultStyleNumber"}${
                            item.colorCode || "defaultColorCode"
                          }.jpg`}
                          alt={`${item.productLine} in ${item.color}`} // Descriptive alt text
                          style={{ width: "60px", height: "60px" }}
                        /> */}

                        <div>
                          <h4 className="text-[15px] text-qblack mb-1">
                            {decodedDescription}
                            <sup className="text-[14px] text-qgray ml-2 mt-2">
                              x {item.qty}
                            </sup>
                          </h4>
                          <p className="text-[14px] text-qgray">
                            Style no:{"  "} {item.styleNumber}, Color:{"  "}{" "}
                            {item.color}, Fit:{"  "}
                            {item.fit}, Size:{"  "}
                            {item.size}
                          </p>
                        </div>
                      </div>
                      <div>
                        <span className="text-[14px] text-qblack font-medium">
                          $
                          {item.discountRate > 0
                            ? item.discountedSubTotal
                            : item.subTotal}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            );
          })}

        <div className="w-full h-[1px] bg-[#EDEDED]"></div>

        <div className="mt-[30px]">
          <div className=" flex justify-between mb-5">
            <p className="text-2xl font-medium text-qblack">
              Total ({totalQty || "-"})
            </p>
            {/* Display fallback if total is not available */}
            <p className="text-2xl font-medium text-qred">${total || "0.00"}</p>
            {/*   <p className="text-2xl font-medium text-qred">
              $
              {discountedTotalPrice < totalPrice
                ? discountedTotalPrice
                : totalPrice}
            </p> */}
          </div>
        </div>
        <div>
          <p
            className="text-[15px] text-yellow-500 "
            style={{
              textAlign: "left",
              marginTop: "25px",
              marginBottom: "15px",
              color: "#FF4444",
            }}
          >
            ** Note : You can find the detailed shipping costs and tax prices on
            your invoice. <br /> &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;The actual invoice
            will also show the discount you received with your promo code.
          </p>
        </div>
      </div>

      <div className="flex justify-end ">
        <div
          style={{
            marginBottom: "150px",
          }}
        >
          <div className="button-container " style={{ marginBottom: "50px" }}>
            {/* Cancelation button */}
            <SecondaryButton
              style={{ marginRight: "15px" }}
              width="200px"
              height="40px"
              fontSize="16px"
              onClick={handleCancelCheckout}
            >
              Back
            </SecondaryButton>
            <PrimaryButton
              width="200px"
              height="40px"
              fontSize="16px"
              onClick={handlePlaceAnOrder}
            >
              Place an order
            </PrimaryButton>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
