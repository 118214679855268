import Axios from "axios";
import {
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDERDETAILS_REQUEST,
  GET_ORDERDETAILS_SUCCESS,
  GET_ORDERDETAILS_FAIL,
  GET_CANCELREQUEST_REQUEST,
  GET_CANCELREQUEST_SUCCESS,
  GET_CANCELREQUEST_FAIL,
} from "../constants/orderConstants";

const apiUrl = process.env.REACT_APP_API_URL;

// eslint-disable-next-line import/prefer-default-export
export const getOrders = () => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_ORDERS_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(`${apiUrl}/order/orders/${tokenUserId}`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_ORDERS_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_ORDERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_ORDERS_SUCCESS, payload: data.data.result.result });
};

export const getOrderDetails = (orderId) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_ORDERDETAILS_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(
      `${apiUrl}/order/orderdetails/${tokenUserId}/${orderId}`,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_ORDERDETAILS_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_ORDERDETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_ORDERDETAILS_SUCCESS, payload: data });
};

export const getOrderCancellationRequest = (orderId) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_CANCELREQUEST_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(
      `${apiUrl}/order/cancelRequest/${tokenUserId}/${orderId}`,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_CANCELREQUEST_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_CANCELREQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_CANCELREQUEST_SUCCESS, payload: data });
  console.log("data", data);
};
