export default function CustomCheckbox(props) {
  const { id, name, handleChange, checked, className } = props;

  return (
    <div className="checkbox-wrapper">
      <input
        className={`${className} custom-checkbox-input`}
        id={id}
        type="checkbox"
        name={name}
        onChange={handleChange}
        checked={checked}
      />
      <label htmlFor={id} className="custom-checkbox-label">
        {/* Custom square checkbox indicator */}
        {checked && <span className="checkbox-indicator"></span>}
      </label>
    </div>
  );
}
