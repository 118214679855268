import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function PrivacyPolicy() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Careers", path: "Careers" },
            ]}
            title="Careers"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-2">
                POSITION: CUSTOMER SERVICE REPRESENTATIVE – MAJOR ACCOUNTS
              </h2>
              <p className="text-[15px] text-qblack leading-7">
                <span>
                  <strong>Career Level Required : </strong>
                </span>
                Experienced (Non-Manager)
                <br />
                <span>
                  <strong>Experience Required :</strong>
                </span>
                3 years minimum <br />
                <span>
                  <strong>Education Required : </strong>
                </span>
                Some college preferred <br />
                <span>
                  <strong>Job Type : </strong>
                </span>
                Employee <br />
                <span>
                  <strong>Job Status : </strong>
                </span>
                Full Time <br />
                Email resume to recruiter@maevn.com or fax to (972) 241-1184 (no
                phone calls please).
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-2">
                JOB SUMMARY:
              </h2>
              <p className="text-[15px] text-qblack leading-7 mb-10">
                The Major Accounts Customer Service Representative (CSR) serves
                as the primary internal contact for the company’s top accounts.
                To this end, the Major Accounts CSR is expected to: <br />
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qblack leading-7">
                    Have excellent MS Office skills and experience with company
                    management systems such as Oracle, Mas or similar order
                    management systems. Quick Books experience also preferred.
                  </li>
                  <li className="text-[15px] text-qblack leading-7">
                    Understand thoroughly the business process, procedures, and
                    practices of large corporations for efficient handling of
                    orders and shipping of goods.
                  </li>
                  <li className="text-[15px] text-qblack leading-7">
                    Understanding and knowledge of shipping practices including
                    bills of lading, commercial invoices and custom’s paperwork
                    requirements. Ability to work with warehouse and QC
                    personnel to ensure customer satisfaction.
                  </li>
                  <li className="text-[15px] text-qblack leading-7">
                    Communicate with other departments such as AR, IT and Sales
                    to ensure that orders and Invoices are prepared in a
                    complete, accurate, and timely manner. Knowledge of EDI and
                    other electronic methods for order and invoice transmittal
                    preferred.
                  </li>
                  <li className="text-[15px] text-qblack leading-7">
                    Communicate with sales and marketing personnel on a daily
                    basis to ensure customer desires are heard and acted upon in
                    a timely manner.
                  </li>
                  <li className="text-[15px] text-qblack leading-7">
                    Know the product lines well and stay abreast of new products
                    as they are developed.
                  </li>
                </ul>
              </p>

              <div>
                <h2 className="text-[18px] font-medium text-qblack mb-2">
                  KEY RESPONSIBILITIES:
                </h2>
                <ul className="list-disc ml-5">
                  <li className="text-[15px] text-qblack leading-7">
                    Have excellent MS Office skills and experience with company
                    management systems such as Oracle, Mas or similar order
                    management systems. Quick Books experience also preferred.{" "}
                  </li>
                  <li className="text-[15px] text-qblack leading-7">
                    Understand thoroughly the business process, procedures, and
                    practices of large corporations for efficient handling of
                    orders and shipping of goods.
                  </li>
                  <li className="text-[15px] text-qblack leading-7">
                    Understanding and knowledge of shipping practices including
                    bills of lading, commercial invoices and custom’s paperwork
                    requirements. Ability to work with warehouse and QC
                    personnel to ensure customer satisfaction.
                  </li>
                  <li className="text-[15px] text-qblack leading-7">
                    Communicate with other departments such as AR, IT and Sales
                    to ensure that orders and Invoices are prepared in a
                    complete, accurate, and timely manner. Knowledge of EDI and
                    other electronic methods for order and invoice transmittal
                    preferred.
                  </li>
                  <li className="text-[15px] text-qblack leading-7">
                    Communicate with sales and marketing personnel on a daily
                    basis to ensure customer desires are heard and acted upon in
                    a timely manner.
                  </li>
                  <li className="text-[15px] text-qblack leading-7">
                    Know the product lines well and stay abreast of new products
                    as they are developed.
                  </li>
                </ul>
              </div>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-2">
                KEY RESPONSIBILITIES:
              </h2>
              <ul className="list-disc ml-5">
                <li className="text-[15px] text-qblack leading-7">
                  Communications – Same day email and phone call response is
                  required. Occasional after hour time may occasionally be
                  required.
                </li>
                <li className="text-[15px] text-qblack leading-7">
                  Order Entry / Invoicing – All orders are entered the day
                  received and invoiced the day they ship. Occasional overtime
                  may be required to accomplish this requirement.
                </li>
                <li className="text-[15px] text-qblack leading-7">
                  Account Setup and Maintenance – The CSR – Major Accounts is
                  responsible for all parameters required by the account to
                  ensure product is ordered, processed and shipped to the
                  account’s specifications. Initial set up and on-going account
                  maintenance is required to accomplish this.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
