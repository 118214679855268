import { useState } from "react";
import "./style.css";
import Arrow from "../../Helpers/icons/Arrow";

export default function Selectbox({ datas = [], className, action, children }) {
  const [item, setItem] = useState(datas[0]);
  const [toggle, setToggle] = useState(false);
  const handler = (e, value) => {
    if (action) {
      action(value);
    }
    setItem(value);
    setToggle(false);
  };

  return (
    <>
      {datas.length > 0 && (
        <div
          className={`my-select-box ${className || ""}`}
          onMouseEnter={() => setToggle(true)}
          onMouseLeave={() => setToggle(false)}
        >
          <button type="button" className="my-select-box-btn ">
            {children ? children({ item }) : <span>{item}</span>}
          </button>
          <div className={`my-select-box-section ${toggle ? "open" : ""}`}>
            <ul className="list">
              {datas.map((value) => (
                <li
                  className={item === value ? "selected" : ""}
                  key={Math.random() + value}
                  onClick={(e) => handler(e, value)}
                >
                  {value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
