import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SearchBox2() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    navigate(`/AllItems/${searchTerm}`);
  };

  const handleClearSearch = () => {
    setSearchTerm(""); // Clear the search input
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "436px",
          height: "42px",
          padding: "11px 19px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px",
          flexShrink: 0,
          borderRadius: "5px",
          border: `1px solid ${searchTerm ? "#131313" : "#C3C3C3"}`, // Change border color when searchTerm is not empty
          background: "var(--Base-White, #FFF)",
        }}
      >
        <form
          action="#"
          onSubmit={handleSearchClick}
          style={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <span>
            <button type="button" onClick={handleSearchClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M15 14.2155L10.81 10.0255C11.8169 8.8167 12.319 7.26626 12.2118 5.69671C12.1047 4.12716 11.3966 2.65934 10.2348 1.59859C9.07298 0.537844 7.54695 -0.0341578 5.97415 0.00157912C4.40136 0.037316 2.90288 0.67804 1.79046 1.79046C0.67804 2.90288 0.037316 4.40136 0.00157912 5.97415C-0.0341578 7.54695 0.537844 9.07298 1.59859 10.2348C2.65934 11.3966 4.12716 12.1047 5.69671 12.2118C7.26626 12.319 8.8167 11.8169 10.0255 10.81L14.2155 15L15 14.2155ZM1.12948 6.12287C1.12948 5.13527 1.42234 4.16985 1.97102 3.34869C2.5197 2.52753 3.29956 1.88752 4.21198 1.50958C5.1244 1.13164 6.12841 1.03276 7.09703 1.22543C8.06565 1.4181 8.95539 1.89367 9.65373 2.59201C10.3521 3.29035 10.8276 4.18008 11.0203 5.14871C11.213 6.11733 11.1141 7.12133 10.7362 8.03375C10.3582 8.94618 9.7182 9.72604 8.89705 10.2747C8.07589 10.8234 7.11047 11.1163 6.12287 11.1163C4.79899 11.1148 3.52976 10.5882 2.59363 9.6521C1.65751 8.71598 1.13095 7.44675 1.12948 6.12287Z"
                  fill="#131313"
                />
              </svg>
            </button>
          </span>
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            value={searchTerm}
            onChange={handleInputChange}
            style={{
              flexGrow: 1,
              border: "none",
              outline: "none",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400", // Regular weight for input text
              lineHeight: "normal",
              color: "#727272", // Ensuring the text color matches the specified color
            }}
          />
          {searchTerm && (
            <span
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={handleClearSearch}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 15 14"
                fill="none"
              >
                <path
                  d="M14 0.5L1 13.5"
                  stroke="#474747"
                  strokeLinecap="round"
                />
                <path
                  d="M1 0.5L14 13.5"
                  stroke="#474747"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          )}
        </form>
      </div>
    </>
  );
}
