import React from "react";
import "./OrderReview.css";

const DropshipModal = ({
  modalShow,
  modalInputValues,
  setModalInputValues,
  handleStateChange,
  handleCloseModalWithoutDropShip,
  buttonDisabled,
  setShowErrorMessage,
  showErrorMessage,
  handleCloseModal,
  CountryState = [],
  states = [],
}) => {
  return (
    <>
      {modalShow && (
        <div className="address-modal">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">Dropship Address</div>
              <span
                className="address-close-button"
                onClick={handleCloseModalWithoutDropShip}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M7 7.5L17 17.5M7 17.5L17 7.5"
                    stroke="#0B0B0B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <form className="address-form">
              {/* Name */}
              <label htmlFor="Name" className="modal-label">
                Company Name
              </label>
              <input
                type="text"
                id="Name"
                value={modalInputValues.companyName || ""}
                onChange={(e) =>
                  setModalInputValues({
                    ...modalInputValues,
                    companyName: e.target.value,
                  })
                }
              />
              {/* Address 1 */}
              <label htmlFor="address1" className="modal-label">
                Address 1
              </label>
              <input
                type="text"
                id="address1"
                value={modalInputValues.address1 || ""}
                onChange={(e) =>
                  setModalInputValues({
                    ...modalInputValues,
                    address1: e.target.value,
                  })
                }
              />
              {/* Address 2 */}
              <label htmlFor="address2" className="modal-label">
                Address 2 (Option)
              </label>
              <input
                type="text"
                id="address2"
                value={modalInputValues.address2 || ""}
                onChange={(e) =>
                  setModalInputValues({
                    ...modalInputValues,
                    address2: e.target.value,
                  })
                }
              />

              {/* Country */}
              <div>
                <label htmlFor="country" className="modal-label">
                  Country
                </label>
                <select
                  id="country"
                  value={modalInputValues.countryName || ""}
                  onChange={handleStateChange}
                  required
                >
                  <option value="">Select Country</option>
                  {CountryState.map((country) => (
                    <option
                      key={country.countryName}
                      value={country.countryName}
                    >
                      {country.countryName}
                    </option>
                  ))}
                </select>
              </div>
              {/* Container for city, state, zipcode */}
              <div className="select-container">
                {/* city */}
                <div>
                  <label htmlFor="city" className="modal-label">
                    City
                  </label>
                  <input
                    type="text"
                    id="city"
                    value={modalInputValues.city || ""}
                    onChange={(e) =>
                      setModalInputValues({
                        ...modalInputValues,
                        city: e.target.value,
                      })
                    }
                  />
                </div>

                {/* State */}
                <div>
                  <label htmlFor="state" className="modal-label">
                    State
                  </label>
                  <select
                    id="state"
                    value={modalInputValues.stateName}
                    onChange={(event) => {
                      const selectedState = event.target.value;
                      const selectedStateObj = states.find(
                        (item) => item.stateName === selectedState
                      );
                      setModalInputValues({
                        ...modalInputValues,
                        stateName: selectedState,
                        stateId: selectedStateObj?.erpStateId || 0,
                      });
                    }}
                    required
                  >
                    <option value="">Select State</option>
                    {states.map((state) => (
                      <option key={state.stateName} value={state.stateName}>
                        {state.stateName}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Zip code */}
                <div>
                  <label htmlFor="zipCode" className="modal-label">
                    Zip code
                  </label>
                  <input
                    type="text"
                    id="zipCode"
                    value={modalInputValues.zipCode || ""}
                    onChange={(e) =>
                      setModalInputValues({
                        ...modalInputValues,
                        zipCode: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              {/* close modal */}
              <button
                type="button"
                className="address-confirm-button"
                onClick={() => {
                  if (buttonDisabled) {
                    setShowErrorMessage(true);
                  } else {
                    setShowErrorMessage(false);
                    handleCloseModal();
                  }
                }}
              >
                Use this address
              </button>
              {showErrorMessage && (
                <p className="text-red-500 mt-2 text-sm">
                  Please ensure your address is correct.
                </p>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default DropshipModal;
