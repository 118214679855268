import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./QuickOrder.css";
import TertiaryButton from "../Helpers/Buttons/TertiaryButton";

const QuickOrderButton = ({ onClick }) => {
  //validate user
  const { search } = useLocation(); //search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login"; // check if redirect url exist

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  //permissions
  const shoppingCartPermission = userInfo.result.user.permissions.ShoppingCart;

  return (
    <>
      {/* Check Subuser permission */}
      {shoppingCartPermission === 1 && (
        <div className="absolute top-10 right-0">
          <TertiaryButton fontSize="16px" color="#101010" onClick={onClick}>
            Quick Order Form
          </TertiaryButton>

          {/*   
          <button
            type="button"
            className="quick-order-button"
            onClick={onClick}
          >
            <div className="flex space-x-2 items-center">
              <span className="quick-order-button-font  ">
                QUICK ORDER FORM
              </span>
              <span>
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1.08984"
                    width="6.94106"
                    height="1.54246"
                    transform="rotate(45 1.08984 0)"
                    fill="white"
                  />
                  <rect
                    x="6"
                    y="4.9082"
                    width="6.94106"
                    height="1.54246"
                    transform="rotate(135 6 4.9082)"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
      </button> */}
        </div>
      )}
    </>
  );
};
export default QuickOrderButton;
