function DataIteration(props) {
  const { datas = [], startLength, endLength, children } = props;

  /*
  console.log(
    "startLength",
    "endLength",
    "datas.length",
    startLength,
    endLength,
    datas
  );
*/

  return (
    <>
      {datas &&
        datas.length >= endLength &&
        datas
          .slice(startLength, endLength)
          .map((value) => children({ datas: value }))}
    </>
  );
}

export default DataIteration;
