import React, { useEffect, useState } from "react";
import InputCom from "../../../Helpers/InputCom";

export default function MoneyToPay({
  creditBalancePlaceHolder,
  invoicePlaceHolder,
  subtotalPlaceholder,
  totalPlaceholder,
  paymentInfoTmp,
  setPaymentInfoTmp,
  warnings,
}) {
  const [invoiceNote, setInvoiceNote] = useState(invoicePlaceHolder);

  // Set initial invoice note
  useEffect(() => {
    setInvoiceNote(invoicePlaceHolder);
  }, [invoicePlaceHolder]);

  // Set values during initial rendering
  useEffect(() => {
    let initialBalance = creditBalancePlaceHolder;

    // Ensure initial balance does not exceed the subtotal during initial rendering
    if (Number(creditBalancePlaceHolder) > Number(subtotalPlaceholder)) {
      initialBalance = subtotalPlaceholder;
    }

    // Round initialBalance to two decimal places
    let roundedBalance = Math.round(initialBalance * 100) / 100;

    // Convert to string and format balance with up to 2 decimal points, removing trailing zeros if needed
    let formattedBalance = roundedBalance.toString();
    if (roundedBalance % 1 === 0) {
      // It's a whole number
      formattedBalance = roundedBalance.toString();
    } else {
      formattedBalance = roundedBalance.toFixed(2); // Keep two decimal places for non-whole numbers
    }

    // Calculate the initial subtotal value as the value of subtotalPlaceholder minus the initial balance value
    const initialSubTotal =
      Math.round((Number(subtotalPlaceholder) - roundedBalance) * 1000) / 1000;

    // Update "PaymentInfoTmp" state with the newly calculated values
    setPaymentInfoTmp((prevState) => ({
      ...prevState,
      balance: formattedBalance,
      subTotal: Math.round((roundedBalance + initialSubTotal) * 1000) / 1000,
      total: initialSubTotal,
    }));
  }, [creditBalancePlaceHolder, subtotalPlaceholder]);

  function handleInputChange(field, value) {
    // Convert input value to a float for numeric operations. If the input is not a number, default to 0.
    // let numericValue = parseFloat(value) || 0;

    let numericValue = parseFloat(value);
    if (isNaN(numericValue) || numericValue < 0) {
      numericValue = "";
    }

    // Prepare a new state object based on the current state.
    let newState = { ...paymentInfoTmp };

    if (field === "subTotal") {
      // Directly update subTotal
      // newState.subTotal = numericValue;
      newState.subTotal = numericValue === "" ? "" : numericValue;

      // Adjust the balance if the new subtotal is LESS than the current balance
      if (numericValue < parseFloat(paymentInfoTmp.balance)) {
        newState.balance = numericValue === "" ? 0 : numericValue;
      }

      // Adjust the balance if the new subtotal is more than creditBalancePlaceHolder
      if (numericValue > parseFloat(creditBalancePlaceHolder)) {
        newState.balance = creditBalancePlaceHolder;
      }
    } else if (field === "balance") {
      // Ensure the balance does not exceed creditBalancePlaceHolder or subTotal.
      const maxBalance = Math.min(
        parseFloat(creditBalancePlaceHolder),
        //parseFloat(paymentInfoTmp.subTotal)
        parseFloat(paymentInfoTmp.subTotal) || 0
      );
      newState.balance = numericValue > maxBalance ? maxBalance : numericValue;
      newState.balance = numericValue === "" ? "" : newState.balance;
    }
    // Ensure balance does not exceed creditBalancePlaceHolder when subTotal is empty
    if (
      newState.subTotal === "" &&
      newState.balance > parseFloat(creditBalancePlaceHolder)
    ) {
      newState.balance = creditBalancePlaceHolder;
    }

    // Recalculate the total as subTotal minus balance, ensuring no negative values
    newState.total = Math.max(
      0,
      Math.round((newState.subTotal - newState.balance) * 100) / 100
    );

    // Update the state with the new values.
    setPaymentInfoTmp(newState);
  }

  /*
  const handleInputChange = (field, value) => {
    if (["balance", "subTotal"].includes(field) && isNaN(Number(value))) {
      return;
    }

    if (field === "balance") {
      console.log("balance : ", value);
      console.log("creditBalancePlaceHolder : ", creditBalancePlaceHolder);
    }

    if (field === "subTotal") {
      console.log("subTotal : ", value);
      console.log("subtotalPlaceholder : ", subtotalPlaceholder);
    }

    // 'note' 필드 처리
    if (field === "invoice") {
      setInvoiceNote(value);
      return;
    }

    // If input value to 'balance' field
    let adjustedValue = value;

    if (field === "balance") {
      // Ensure the entered balance does not exceed the subtotal or the creditBalancePlaceHolder
      const maxBalance = Math.min(
        Number(PaymentInfoTmp.subTotal),
        Number(creditBalancePlaceHolder)
      );

      adjustedValue = Math.min(Number(value), maxBalance);
    }

    // If input value to 'balance' field
    if (field === "subTotal") {
      if (Number(value) <= Number(creditBalancePlaceHolder)) {
        paymentInfoTmp.balance = value;
      }
    }

    // Preparing the updatedPaymentInfoTmp object with potentially adjusted values
    const updatedPaymentInfoTmp = {
      ...paymentInfoTmp,
      [field]: adjustedValue,
    };

    // Automatically update the total
    if (field === "balance" || field === "subTotal") {
      const balance = updatedPaymentInfoTmp.balance
        ? Number(updatedPaymentInfoTmp.balance)
        : 0;
      const subTotal = updatedPaymentInfoTmp.subTotal
        ? Number(updatedPaymentInfoTmp.subTotal)
        : 0;
      let totalValue = subTotal - balance;

      // Apply conditional cleanup logic after decimal point
      if (Math.floor(totalValue) !== totalValue) {
        // If there are decimal places
        if (Math.floor(totalValue * 100) !== totalValue * 100) {
          // If there are more than two decimal places, round to two places.
          totalValue = Number(totalValue.toFixed(2));
        } else {
          // If there is a single decimal point, use it as is (remove unnecessary 0)
          totalValue = Number(totalValue);
        }
      }
      updatedPaymentInfoTmp.total = totalValue;
    }

    setPaymentInfoTmp(updatedPaymentInfoTmp);
    console.log("updatedPaymentInfoTmp", updatedPaymentInfoTmp);
  };
*/

  return (
    <>
      <div
        className="input-item mb-8"
        style={{
          marginBottom: "30px",
          border: "1px solid lightgray",
          padding: "25px 20px 25px 20px",
        }}
      >
        <div className="input-item flex space-x-2.5 mb-4">
          <div className="w-1/3 h-full">
            <InputCom
              label="Credit used from your Balance (USD)"
              name="balance"
              placeholder={creditBalancePlaceHolder}
              type="number"
              inputClasses="h-[30px]"
              inputHandler={(e) => handleInputChange("balance", e.target.value)}
              value={paymentInfoTmp.balance}
              warnings={warnings}
            />
          </div>
          <div className="w-1/3 h-full">
            <InputCom
              label="Subtotal Amount on Invoices (USD)"
              name="subTotal"
              placeholder={subtotalPlaceholder}
              type="number"
              inputClasses="h-[30px]"
              inputHandler={(e) =>
                handleInputChange("subTotal", e.target.value)
              }
              value={paymentInfoTmp.subTotal}
              warnings={warnings}
            />
          </div>
          <div className="w-1/3 h-full">
            <InputCom
              label="Total Amount Paying (USD)"
              name="total"
              placeholder={totalPlaceholder}
              type="number"
              inputClasses="h-[30px]"
              inputHandler={(e) => handleInputChange("total", e.target.value)}
              value={paymentInfoTmp.total}
              warnings={warnings}
              isReadOnly={true}
            />
          </div>
        </div>
        <div className="h-full flex flex-col">
          <span className="text-ms text-qgray text-[13px] font-normal">
            Note
          </span>
          <input
            readOnly
            value={invoiceNote}
            onChange={(e) => handleInputChange("invoice", e.target.value)} // Adds an onChange event handler that handles user input.
            type="text"
            className="h-[70px] w-full border border-lightgray focus:outline-none focus:border-lightgray"
            style={{ borderColor: "#F0F0F0", fontSize: "14px" }}
          />
        </div>
      </div>
    </>
  );
}
