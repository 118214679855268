import Axios from "axios";

import {
  GET_COLLECTIONS_REQUEST,
  GET_COLLECTIONS_SUCCESS,
  GET_COLLECTIONS_FAIL,
  GET_STYLES_REQUEST,
  GET_STYLES_SUCCESS,
  GET_STYLES_FAIL,
  GET_QUICKORDER_REQUEST,
  GET_QUICKORDER_SUCCESS,
  GET_QUICKORDER_FAIL,
  GET_FILTEREDINVENTORY_REQUEST,
  GET_FILTEREDINVENTORY_SUCCESS,
  GET_FILTEREDINVENTORY_FAIL,
} from "../constants/quickorderConstants";

const apiUrl = process.env.REACT_APP_API_URL;
const devApiUrl = process.env.REACT_APP_DEV_API_URL;

// eslint-disable-next-line import/prefer-default-export
export const getCollections = () => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }
  console.log("token", token.result.token);

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_COLLECTIONS_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(`${apiUrl}/quickorder/getProductLines`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_COLLECTIONS_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_COLLECTIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_COLLECTIONS_SUCCESS, payload: data });
  console.log("data (getCollections) : ", data);
};

export const getStyles = (erpProgramId) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }
  console.log("token", token.result.token);

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_STYLES_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(`${apiUrl}/quickorder/getStyles/${erpProgramId}`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_STYLES_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_STYLES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_STYLES_SUCCESS, payload: data });
  console.log("data (getCollections) : ", data);
};

export const getInitialInventory = (stylenumber) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }
  console.log("token", token.result.token);

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_QUICKORDER_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(`${apiUrl}/quickorder/initialData/${stylenumber}`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_QUICKORDER_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_QUICKORDER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_QUICKORDER_SUCCESS, payload: data?.data.result });
  console.log("data?.data.result (getInitialInventory) : ", data?.data.result);
};

export const getFilteredInventory = (filters) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_FILTEREDINVENTORY_REQUEST, payload: tokenUserId });

  let data;
  try {
    console.log("filters (getFilteredInventory) : ", filters);

    const response = await Axios.get(`${apiUrl}/quickorder/filteredItems`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
      params: {
        styleNumber: filters.StyleNumber,
        colorCode: filters.ColorCode,
        fit: filters.Fit,
        inseamLength: filters.InseamLength,
      },
    });
    data = response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_FILTEREDINVENTORY_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_FILTEREDINVENTORY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_FILTEREDINVENTORY_SUCCESS, payload: data?.data.result });
  console.log("data (getFilteredInventory) : ", data?.data.result);
};
