import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import styled from "@emotion/styled";

const StyledDialogTitle = styled(DialogTitle)`
  font-family: "Roboto Condensed", sans-serif;
  color: black;
  text-align: left;
  font-weight: bold;
  padding: 10px;
  font-size: 20px;
`;

const StyledDialogContent = styled(DialogContent)`
  text-align: center;
  padding: 30px;
`;

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: space-evenly;
  padding: 15px;
`;

const BackorderDialog = ({
  dialogOpen,
  setDialogOpen,
  backorderItems,
  handleGoToCart,
  handleConfirmOrder,
}) => {
  return (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <StyledDialogContent style={{ paddingTop: 30, paddingBottom: 5 }}>
        <StyledDialogTitle id="alert-dialog-title">
          BACKORDER DETAILS
        </StyledDialogTitle>
        <DialogContentText id="alert-dialog-description">
          Your order is including backorder items that exceed the current
          orderable quantity. If you prefer not to place a backorder, kindly
          adjust the quantity of the item in your order to match the currently
          available stock.
          <br /> <br />
        </DialogContentText>

        {/* Dynamically generate the backorder list as a table */}
        <table
          style={{
            width: "100%",
            fontSize: "0.75rem",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Style No.
              </th>
              <th style={{ border: "1px solid black", padding: "8px" }}>Fit</th>
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Color
              </th>
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Size
              </th>
              <th style={{ border: "1px solid black", padding: "8px" }}>
                Quantity
              </th>
            </tr>
          </thead>
          <tbody>
            {backorderItems?.map((item, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {item.styleNumber}
                </td>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {item.fit}
                </td>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {item.color}
                </td>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {item.size}
                </td>
                <td style={{ border: "1px solid black", padding: "8px" }}>
                  {item.qty}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </StyledDialogContent>
      <StyledDialogActions
        style={{ marginTop: "30", justifyContent: "flex-end" }}
      >
        <button
          type="button"
          className="text-black bg-white border border-black text-sm font-semibold w-1/4 h-10"
          onClick={handleGoToCart}
        >
          Go to Cart
        </button>
        <button
          type="button"
          className="black-btn text-sm font-semibold w-1/4 h-10"
          onClick={handleConfirmOrder}
        >
          Confirm
        </button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default BackorderDialog;
