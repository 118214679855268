import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function PaymentConfirmTab() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userId, setUserId] = useState();
  const [paymentId, setPaymentId] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [balanceToUse, setBalanceToUse] = useState();
  const [cardHolderName, setCardHolderName] = useState();
  const [invoiceId, setInvoiceId] = useState();
  const [paymentAmount, setPaymentAmount] = useState();
  const [refNum, setRefNum] = useState();
  const [createdDate, setCreatedDate] = useState();
  const [loginId, setLoginId] = useState("");

  // move to invoice page
  const handleContinueShoppingButtonClick = () => {
    navigate("/myaccount#invoice");
  };

  // get username from local storage
  useEffect(() => {
    const localStorageData = localStorage.getItem("userInfo");
    if (localStorageData) {
      const parsedData = JSON.parse(localStorageData);
      setLoginId(parsedData.result.user.loginId);
      console.log("loginId : ", loginId);
    }
  }, []);

  // get payment details from redux
  const paymentResult = useSelector(
    (state) => state.postPaymentConfirmRedux.paymentResult
  );

  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(inputDate);

    const day = date.getDate(); // 일
    const monthIndex = date.getMonth(); // 월
    const year = date.getFullYear(); // 연도

    return `${day} ${months[monthIndex]}, ${year}`;
  }

  useEffect(() => {
    if (paymentResult && paymentResult.data && paymentResult.data.result) {
      setPaymentId(paymentResult.data.result.id);
      setAccountNumber(paymentResult.data.result.accountNumber);
      setBalanceToUse(paymentResult.data.result.balanceToUse);
      setCardHolderName(paymentResult.data.result.cardHolderName);
      setInvoiceId(paymentResult.data.result.invoiceId);
      setPaymentAmount(paymentResult.data.result.paymentAmount);
      setUserId(paymentResult.data.result.userId);

      // parsing date type
      const formattedDate = formatDate(paymentResult.data.result.createdDate);
      setCreatedDate(formattedDate);

      // parsing credit card number
      const fullCardNumber = paymentResult.data.result.cardNum;
      const maskedCardNumber =
        fullCardNumber.length > 4
          ? "X".repeat(fullCardNumber.length - 4) + fullCardNumber.slice(-4)
          : fullCardNumber;
      setRefNum(maskedCardNumber);
    }
  }, [paymentResult]);
  return (
    <div className="bg-white min-h-screen p-8 flex flex-col justify-center">
      {/* Title */}
      <div className="mb-10">
        <span className="text-4xl font-extrabold text-black">
          Payment Successful!
        </span>{" "}
        <div className="text-sm text-gray-500 mt-2">
          We would like to inform you that it may take up to 48 hours for your
          payment details to be processed and reflected in your invoice.
          <br /> Thank you for your patience and for choosing to shop with us.
          <br />
          If you have any questions or need assistance, please feel free to
          reach out to our customer support team.
        </div>
      </div>

      {/* Payment Details */}
      <div className="mb-10">
        {paymentResult ? (
          <div
            className="grid grid-cols-2 gap-2 bg-gray-100 p-2 rounded-md w-1/2"
            style={{ gridTemplateColumns: "200px auto" }}
          >
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              Username
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              {loginId}
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              Account No.
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              {accountNumber}
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              Invoice No.
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              {invoiceId}
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              Payment Amount
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              ${paymentAmount}
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              Balance Used
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              ${balanceToUse}
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              Total Amount
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              ${paymentAmount + balanceToUse}
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              Card No.
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-0.5">
              {refNum}
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-1">
              Card Holder Name
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-1">
              {cardHolderName}
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-1">
              Payment ID
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-1">
              {paymentId}
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-1">
              Created Date
            </div>
            <div className="text-md text-gray-800 font-semibold border-b border-gray-300 py-1">
              {createdDate}
            </div>
          </div>
        ) : (
          <div className="text-lg text-black font-bold">Loading...</div>
        )}
      </div>

      {/* Continue Shopping Button */}
      <div>
        <button
          className="px-6 py-3 bg-black text-white text-xl font-bold rounded hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
          onClick={handleContinueShoppingButtonClick}
        >
          CONTINUE SHOPPING
        </button>
      </div>
    </div>
  );
}
