import React from "react";
import "./Button.css"; // CSS 파일 임포트

export default function TertiaryButton({
  width,
  height,
  fontSize,
  color,
  style,
  children,
  onClick,
  type = "button",
  disabled,
}) {
  // Combine external styles with internal styles
  const combinedStyles = {
    width,
    height,
    fontSize,
    color,
    ...style,
  };

  return (
    <button
      className="tertiaryButton"
      style={combinedStyles} // Apply width, height, fontSize, and color from props
      type={type} // Set button type (button, submit, reset)
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 19"
        fill="none"
      >
        <path
          d="M3.75 9.5H13.5M9.75 5L13.7197 8.96967C14.0126 9.26256 14.0126 9.73744 13.7197 10.0303L9.75 14"
          stroke="#101010"
          strokeWidth="1.67"
          strokeLinecap="round"
        />
      </svg>
    </button>
  );
}
