import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx"; // To read the Excel file
import PageTitle from "../../Helpers/PageTitle";
import Layout from "../../Partials/Layout";
import SecondaryButton from "../../Helpers/Buttons/SecondaryButton";
import PrimaryButton from "../../Helpers/Buttons/PrimaryButton";
import {
  checkBackorderItems,
  productValidation,
} from "../../../actions/bulkorderAction";
import BackorderDialog from "./BackorderDialog";

export default function ImportItems() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Retrieve the initial order list from the state passed by navigate
  const initialOrderInfo = location.state?.orderInfo || {};

  const [orderInfo, setOrderInfo] = useState(initialOrderInfo);
  const [orderList, setOrderList] = useState([]);

  // Validate user
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login";

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  // File input key state
  const [fileInputKey, setFileInputKey] = useState(0);
  const [selectedFileName, setSelectedFileName] = useState("No file chosen");
  const [isFileCleared, setIsFileCleared] = useState(false); // for checking it the file is initialized

  // New state to track whether any validation process has started
  const [hasValidationStarted, setHasValidationStarted] = useState(false);

  // Validate data
  const [errorMessages, setErrorMessages] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [fileClearedMessage, setFileClearedMessage] = useState(""); // New state for the file cleared message
  const fileInputRef = useRef(null); // Add a ref for the file input

  // Validate result
  const [fileValidationSuccess, setFileValidationSuccess] = useState(false);
  const [apiErrorMessages, setApiErrorMessages] = useState([]); // API error message
  const [apiSuccess, setApiSuccess] = useState(null); // API result status

  // Backorder items and modal open status
  const [isModalOpen, setModalOpen] = useState(false);
  const [backorderItems, setBackorderItems] = useState([]);

  // Conversion function to format the order query data
  const convertOrderData = (orders) => {
    const sizeFields = [
      "XXS",
      "XS",
      "SM",
      "MD",
      "LG",
      "XL",
      "1XL",
      "2XL",
      "3XL",
      "4XL",
      "5XL",
      "OS",
    ];

    return orders.flatMap((order) => {
      // Convert STYLE to string explicitly
      const STYLE = String(order.STYLE);
      let { FIT, COLOR } = order;

      // Convert FIT to uppercase to handle both uppercase and lowercase input
      FIT = FIT ? FIT.toUpperCase() : "R";

      // Map FIT values to their corresponding descriptions
      FIT =
        FIT === "S"
          ? "Short"
          : FIT === "T"
          ? "Tall"
          : FIT === "P"
          ? "Petite"
          : FIT === "R"
          ? "Reg"
          : FIT; // If none of the above, keep the original FIT

      return sizeFields
        .filter(
          (sizeField) => order[sizeField] !== "" && order[sizeField] !== null
        ) // Only sizes with quantities
        .map((sizeField) => {
          const qty = order[sizeField];
          const fitSuffix =
            FIT === "Short"
              ? "S"
              : FIT === "Tall"
              ? "T"
              : FIT === "Petite"
              ? "P"
              : ""; // Add suffix based on FIT

          const sizeWithFit = sizeField + fitSuffix; // Concatenate size with fit suffix if applicable

          return {
            STYLE,
            FIT,
            COLOR,
            SIZE: sizeWithFit,
            QTY: qty,
          };
        });
    });
  };

  // Clear the file input
  const clearFileInput = () => {
    // Clear the file input using the ref
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    // Force re-render by updating the key
    setFileInputKey((prevKey) => prevKey + 1);
    setSelectedFileName("No file chosen"); // Reset the file name

    // Clear the file data and API messages
    setFileData(null);
    setApiErrorMessages([]);
    setApiSuccess(null);
  };

  {
    /* 
  // initialize the file
  useEffect(() => {
    if (isFileCleared && fileInputRef.current) {
      fileInputRef.current.value = "";
      setFileInputKey((prevKey) => prevKey + 1);
      setIsFileCleared(false);
    }
  }, [isFileCleared]);

*/
  }

  // Product validation API
  const handleApiResult = async (convertedData) => {
    try {
      const response = await dispatch(productValidation(convertedData));
      if (response && response.data && response.data.isSuccess) {
        setApiSuccess(true); // API Success
        setOrderList(response.data.result); // Set order list
        setApiErrorMessages([]); // Initialize error message

        // Add orderLine to orderInfo with Pid and Qty from response.data.result
        const updatedOrderInfo = {
          ...orderInfo,
          orderLine: response.data.result.map((item) => ({
            Pid: item.pid,
            Qty: item.qty,
          })),
        };

        // Update orderInfo with the new orderLine field in state
        setOrderInfo(updatedOrderInfo);
      } else {
        // API failed
        setApiSuccess(false);
        setApiErrorMessages(response.data.errorMessages || []);
      }
    } catch (error) {
      setApiSuccess(false);
      setSelectedFileName("No file chosen");
      setApiErrorMessages(
        error.response?.data?.errorMessages || ["An unexpected error occurred."]
      );
    }
  };

  // Validate entered data format
  const validateData = (data) => {
    setHasValidationStarted(true); // Validation has started

    const errors = [];
    const seenCombinations = new Set(); // To track unique FIT, COLOR, SIZE combinations
    let hasDuplicateError = false; // Track if any duplication error occurs

    // 1) Check column headers match the expected format
    const expectedHeaders = [
      "STYLE",
      "FIT",
      "COLOR",
      "XXS",
      "XS",
      "SM",
      "MD",
      "LG",
      "XL",
      "1XL",
      "2XL",
      "3XL",
      "4XL",
      "5XL",
      "OS",
    ];

    const headers = Object.keys(data[0]);
    if (!expectedHeaders.every((header) => headers.includes(header))) {
      errors.push("The Excel file does not have the correct column headers.");
    }

    // Iterate through each row to validate
    data.forEach((row, index) => {
      // If the FIT field is empty or "R", treat it as "Reg"
      const fitUpperCase = row.FIT ? row.FIT.toUpperCase() : "R";

      // 2) Validate FIT column (S-> Short, T->Tall, R->Reg, P->Petite)
      if (!["S", "T", "R", "P"].includes(fitUpperCase)) {
        errors.push(
          `Row ${
            index + 1
          }: Invalid FIT value. Allowed values are "S", "T", "R", "P".`
        );
      }

      // 3) Validate COLOR column (only alphabets allowed)
      if (!/^[A-Za-z]+$/.test(row.COLOR)) {
        errors.push(`Row ${index + 1}: COLOR should only contain alphabets.`);
      }

      // 4) Validate quantity columns (must be integers)
      const sizeColumns = [
        "XXS",
        "XS",
        "SM",
        "MD",
        "LG",
        "XL",
        "1XL",
        "2XL",
        "3XL",
        "4XL",
        "5XL",
        "OS",
      ];
      sizeColumns.forEach((size) => {
        if (!Number.isInteger(row[size]) && row[size] !== "") {
          errors.push(
            `Row ${index + 1}: ${size} column must contain an integer.`
          );
        }
      });

      // 5) Check for duplicate STYLE, FIT, COLOR, SIZE combinations
      sizeColumns.forEach((size) => {
        if (row[size] !== "" && row[size] !== null) {
          const combination = `${row.STYLE}-${row.FIT}-${row.COLOR}-${size}`; // Create a unique key for FIT, COLOR, SIZE

          if (seenCombinations.has(combination)) {
            errors.push(
              `Row ${index + 1}: Duplicated items - STYLE: ${row.STYLE}, FIT: ${
                row.FIT
              }, COLOR: ${row.COLOR}, and SIZE: ${size}.`
            );
            hasDuplicateError = true; // Flag that a duplicate error occurred
          } else {
            seenCombinations.add(combination); // Add the combination to the set
          }
        }
      });
    });

    // Update the error messages state
    setErrorMessages(errors);

    if (errors.length === 0) {
      // If validation passes, convert the data
      const convertedData = convertOrderData(data);
      setFileData(convertedData);

      // Clear any file or error messages
      setFileClearedMessage("");
      setErrorMessages([]);

      // Call API to verify if the items user entered actually exist or not
      handleApiResult(convertedData);

      // API validation passed
      setApiSuccess(true);
      setFileValidationSuccess(true); // Validation passed, show SVG
    } else {
      // Validation failed
      clearFileInput(); // Clear the file input so the user can re-upload
      setFileData(null); // Clear the file data

      let errorMessage =
        "Incorrect file format. Check all data is correctly entered and try again.";

      // If there was a duplication error, append the custom message
      if (hasDuplicateError) {
        errorMessage = "Listed products are duplicated. Please try again.";
      }

      // Set the appropriate error message
      setFileClearedMessage(errorMessage);
    }
  };

  // Recognize the uploaded excel file
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    // Reset validation started state
    setHasValidationStarted(false);

    // Reset messages and validation states when a new file is selected
    setErrorMessages([]); // Clear validation error messages
    setApiErrorMessages([]); // Clear API error messages
    setApiSuccess(null); // Reset API result state
    setFileValidationSuccess(false); // Clear SVG (validation result sign)
    setFileClearedMessage(""); // Clear any file cleared messages

    // Update the displayed file name
    if (file) {
      setSelectedFileName(file.name);
    } else {
      setSelectedFileName("No file chosen");
    }

    if (!file) return; // if there is no file, return

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = "OrderForm";
      const worksheet = workbook.Sheets[sheetName];

      if (worksheet) {
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" });

        if (jsonData.length === 0) {
          setErrorMessages([
            "ERROR : It appears that no data has been entered in the order template file you uploaded. ",
          ]);
          clearFileInput(); // Clear the file input
          setFileData(null); // Clear the file data
          setSelectedFileName("No file chosen"); // Reset the file name
          setFileClearedMessage(
            "Incorrect file format. Check all data is correcelty enetered and try again."
          );
        } else {
          // Proceed to validate the data
          validateData(jsonData);
        }
      } else {
        // Validation failed: OrderForm sheet not found
        setErrorMessages([
          "Incorrect file format. Please ensure it contains an 'OrderForm' sheet' and try again",
        ]);
        clearFileInput(); // Clear the file input
        setFileData(null); // Clear the file data
        setSelectedFileName("No file chosen"); // Reset the file name
        setFileClearedMessage("ERROR : The uploaded file contains errors.");
      }
    };
    reader.readAsArrayBuffer(file);
  };

  // Handle checkout button click
  const handleGoToCheckoutButton = async () => {
    if (!orderList || orderList.length === 0) {
      alert("Please upload the Excel file for the bulk order.");
      return;
    }

    // Check if the entered data is verified
    if (errorMessages.length > 0 || apiErrorMessages.length > 0) {
      alert("Please check the data you've entered in the Excel file.");
      setIsFileCleared(false);
      return;
    }

    // Check for backorder items before proceeding to checkout
    const items = orderList?.filter(
      (item) => item.availableQty < item.qty || item.availableQty === 0
    );

    // backorder list
    if (items?.length > 0) {
      // If there are backorder items, open the dialog
      setBackorderItems(items);
      setModalOpen(true);
    } else {
      // If no backorder items, proceed to checkout
      navigate("/Importorderfile/Confirmation", {
        state: { orderInfo, orderDetail: orderList },
      });
    }
  };

  // Handle dialog confirmation (proceeding to checkout)
  const handleConfirmOrder = () => {
    setModalOpen(false); // Close the dialog

    // If no backorder items, proceed to checkout
    navigate("/Importorderfile/Confirmation", {
      state: { orderInfo, orderDetail: orderList },
    });
  };

  // Handle navigating to cart instead
  const handleGoToCart = () => {
    setModalOpen(false); // Close the dialog
  };

  const handleCancelButton = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[{ name: "home", path: "/" }]}
            title="Import Order File"
          />

          {/* Download Excel Button */}
          <div className="w-full">
            <div className="container-x mx-auto">
              <div className="content-item w-full mb-10">
                <div
                  style={{
                    marginTop: "80px",
                    marginBottom: "30px",
                    fontFamily: "Lato",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                  }}
                >
                  If you don’t have an order form, download or upload it to
                  place your order. Make sure all product information is in the
                  correct format.
                </div>
                <a
                  href="/files/Maevn_Order_Import_Template.xlsx"
                  download
                  style={{
                    color: "#1975FF",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    textDecorationLine: "underline",
                  }}
                >
                  Download Order Template
                </a>
              </div>
            </div>
          </div>

          {/* Upload Excel Button */}
          <div className="w-full">
            <div className="container-x mx-auto">
              <div
                className="content-item w-full mb-10 px-4 py-2"
                style={{
                  border: hasValidationStarted
                    ? apiSuccess && fileValidationSuccess
                      ? "2px solid var(--Verified-Green, #35E37A)" // Green border on success
                      : "2px solid red" // Red border on failure
                    : "1px solid var(--Main-Stroke, #C3C3C3)", // Grey border for initial state

                  background: hasValidationStarted
                    ? apiSuccess && fileValidationSuccess
                      ? "var(--Category_Light-Grey, #F5F5F5)" // Light grey background on success
                      : "var(--Category_Light-Grey, #F5F5F5)" // Transparent background on failure
                    : "transparent", // Transparent background for initial state
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <label
                    htmlFor="upload-button"
                    style={{
                      display: "flex",
                      width: "110px",
                      height: "30px",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      backgroundColor: "#191919",
                      color: "var(--Base-White, #FFF)",
                      cursor: "pointer",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "normal",
                      borderRadius: "5px",
                    }}
                  >
                    Choose File
                  </label>
                  <input
                    key={fileInputKey} // Add the key prop here
                    id="upload-button"
                    type="file"
                    accept=".xlsx"
                    onChange={handleFileUpload}
                    onClick={(e) => {
                      e.target.value = null; // Reset the input value
                    }}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                  <span
                    style={{
                      marginLeft: "10px",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px", // Add gap between file name and icon
                    }}
                  >
                    {selectedFileName}
                    {/* Conditionally render the SVG if both apiSuccess and validation are successful */}
                    {hasValidationStarted &&
                    apiSuccess &&
                    fileValidationSuccess ? (
                      <>
                        {" "}
                        <div
                          style={{
                            color: "#35E37A", // Corrected from fontColor to color
                            fontFamily: "Lato",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                          }}
                        >
                          Successfully uploaded
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 16C9.09428 16 10.1246 15.7902 11.0911 15.3707C12.0575 14.9564 12.9064 14.3808 13.6376 13.6441C14.3739 12.9074 14.9517 12.0582 15.371 11.0964C15.7903 10.1295 16 9.09608 16 7.99616C16 6.90136 15.7903 5.87306 15.371 4.91127C14.9517 3.94436 14.3739 3.09257 13.6376 2.35588C12.9064 1.61918 12.055 1.04364 11.0834 0.62926C10.117 0.209752 9.08661 0 7.99233 0C6.89805 0 5.86769 0.209752 4.90125 0.62926C3.9348 1.04364 3.08341 1.61918 2.34708 2.35588C1.61585 3.09257 1.04059 3.94436 0.621285 4.91127C0.207095 5.87306 0 6.90136 0 7.99616C0 9.09608 0.207095 10.1295 0.621285 11.0964C1.04059 12.0582 1.61841 12.9074 2.35475 13.6441C3.09108 14.3808 3.94247 14.9564 4.90892 15.3707C5.87536 15.7902 6.90572 16 8 16ZM7.14861 11.8024C6.99009 11.8024 6.84692 11.7691 6.71908 11.7026C6.59124 11.631 6.47108 11.5236 6.35858 11.3803L4.51007 9.15492C4.43848 9.05771 4.38223 8.96307 4.34132 8.87098C4.30553 8.77378 4.28763 8.67402 4.28763 8.5717C4.28763 8.35683 4.36178 8.17522 4.51007 8.02686C4.65836 7.87338 4.83989 7.79664 5.05465 7.79664C5.18249 7.79664 5.29754 7.82478 5.39981 7.88105C5.50208 7.93221 5.60435 8.02174 5.70662 8.14964L7.1256 9.93L10.2167 4.98801C10.4008 4.6964 10.6283 4.5506 10.8993 4.5506C11.1039 4.5506 11.2854 4.61711 11.4439 4.75012C11.6075 4.88313 11.6894 5.05707 11.6894 5.27194C11.6894 5.36914 11.6689 5.46635 11.628 5.56355C11.5922 5.66075 11.5462 5.75284 11.4899 5.83981L7.90796 11.3803C7.81592 11.5133 7.70342 11.6182 7.57047 11.695C7.44263 11.7666 7.30201 11.8024 7.14861 11.8024Z"
                            fill="#35E37A"
                          />
                        </svg>
                      </>
                    ) : hasValidationStarted &&
                      (!apiSuccess || !fileValidationSuccess) ? (
                      <>
                        <div
                          style={{
                            color: "var(--Warning-Color---Red, #F44)",
                            fontFamily: "Lato",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                          }}
                        >
                          File failed
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M9 1C4.8525 1 1.5 4.3525 1.5 8.5C1.5 12.6475 4.8525 16 9 16C13.1475 16 16.5 12.6475 16.5 8.5C16.5 4.3525 13.1475 1 9 1ZM12.225 11.725C12.1556 11.7945 12.0732 11.8497 11.9825 11.8873C11.8917 11.925 11.7945 11.9443 11.6962 11.9443C11.598 11.9443 11.5008 11.925 11.41 11.8873C11.3193 11.8497 11.2369 11.7945 11.1675 11.725L9 9.5575L6.8325 11.725C6.69227 11.8652 6.50207 11.944 6.30375 11.944C6.10543 11.944 5.91523 11.8652 5.775 11.725C5.63477 11.5848 5.55598 11.3946 5.55598 11.1962C5.55598 11.0981 5.57533 11.0008 5.6129 10.9101C5.65048 10.8194 5.70556 10.7369 5.775 10.6675L7.9425 8.5L5.775 6.3325C5.63477 6.19227 5.55598 6.00207 5.55598 5.80375C5.55598 5.60543 5.63477 5.41523 5.775 5.275C5.91523 5.13477 6.10543 5.05598 6.30375 5.05598C6.50207 5.05598 6.69227 5.13477 6.8325 5.275L9 7.4425L11.1675 5.275C11.2369 5.20556 11.3194 5.15048 11.4101 5.1129C11.5008 5.07533 11.5981 5.05598 11.6962 5.05598C11.7944 5.05598 11.8917 5.07533 11.9824 5.1129C12.0731 5.15048 12.1556 5.20556 12.225 5.275C12.2944 5.34444 12.3495 5.42687 12.3871 5.51759C12.4247 5.60832 12.444 5.70555 12.444 5.80375C12.444 5.90195 12.4247 5.99918 12.3871 6.08991C12.3495 6.18063 12.2944 6.26306 12.225 6.3325L10.0575 8.5L12.225 10.6675C12.51 10.9525 12.51 11.4325 12.225 11.725Z"
                            fill="#FF4444"
                          />
                        </svg>
                      </>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* File Cleared Notification Message */}
          <div className="w-full">
            <div className="container-x mx-auto">
              {fileClearedMessage && (
                <div className="file-cleared-message mt-4 mb-4 font-semibold text-lg italic">
                  {fileClearedMessage}
                </div>
              )}
            </div>
          </div>

          {/* Display Validation Errors */}
          <div className="w-full">
            <div className="container-x mx-auto mb-20">
              {errorMessages.length > 0 ? (
                <div
                  className="error-messages"
                  style={{
                    display: "flex",
                    flexDirection: "column", // error messages are displayed in a column
                    width: "1168px",
                    padding: "15px",
                    gap: "10px",
                    background: "#FFEBEB",
                    color: "var(--Warning-Color---Red, #F44)", // Updated color
                    fontFamily: "Lato", // Updated font-family
                    fontSize: "16px", // Updated font-size
                    fontStyle: "normal", // Updated font-style
                    fontWeight: "700", // Updated font-weight to bold
                    lineHeight: "normal", // Updated line-height
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                >
                  {errorMessages.map((error, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {/* Circle Header */}
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%", // Circle shape
                          backgroundColor: "var(--Warning-Color---Red, #F44)", // Circle color
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#FFF", // Text color inside the circle
                          fontSize: "12px", // Adjust the size of the index text
                        }}
                      />
                      <div>{error}</div>{" "}
                    </li>
                  ))}
                </div>
              ) : (
                fileData && (
                  <div>
                    {apiSuccess === false ? (
                      <div className="file-cleared-message mt-4 mb-4 font-semibold text-lg italic">
                        Listed products are not available. Please try again.
                        <ul
                          className="error-messages"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "1168px",
                            padding: "15px",
                            gap: "10px",
                            background: "#FFEBEB",
                            color: "var(--Warning-Color---Red, #F44)", // Updated color
                            fontFamily: "Lato", // Updated font-family
                            fontSize: "16px", // Updated font-size
                            fontStyle: "normal", // Updated font-style
                            fontWeight: "700", // Updated font-weight to bold
                            lineHeight: "normal", // Updated line-height
                            marginTop: "16px",
                            marginBottom: "16px",
                          }}
                        >
                          {apiErrorMessages.map((errorMessage, index) => (
                            <li
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {/* Circle Header */}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%", // Circle shape
                                  backgroundColor:
                                    "var(--Warning-Color---Red, #F44)", // Circle color
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "#FFF", // Text color inside the circle
                                  fontSize: "12px", // Adjust the size of the index text
                                }}
                              />
                              {/* Error Message */}
                              <div>{errorMessage}</div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <div
                        className="success-message"
                        style={{
                          display: "flex",
                          width: "1168px",
                          padding: "15px",
                          alignItems: "center",
                          gap: "10px",
                          background: "rgba(53, 227, 122, 0.10)",
                          color: "var(--Verified-Green, #35E37A)", // Updated color
                          fontFamily: "Lato", // Updated font-family
                          fontSize: "16px", // Updated font-size
                          fontStyle: "normal", // Updated font-style
                          fontWeight: "700", // Updated font-weight to bold
                          lineHeight: "normal", // Updated line-height
                          marginTop: "16px",
                          marginBottom: "16px",
                        }}
                      >
                        All products are available to checkout.
                      </div>
                    )}
                  </div>
                )
              )}
              {/* Contact us link */}
              <div className="mt-4" style={{ float: "right" }}>
                Encountering any issues? Please{" "}
                <a href="/contact" className="text-blue-500 underline">
                  contact us
                </a>
                .
              </div>
              <div style={{ clear: "both" }}></div> {/* Clear the float */}
            </div>
            {/* BackorderDialog for handling backorder items */}
            <BackorderDialog
              dialogOpen={isModalOpen}
              setDialogOpen={setModalOpen}
              backorderItems={backorderItems}
              onConfirm={handleConfirmOrder}
              handleGoToCart={handleGoToCart}
            />

            {/* Cancelation/Checkout Button */}
            <div className="container-x mx-auto mb-20">
              <div
                className="button-container "
                style={{ marginBottom: "50px" }}
              >
                <SecondaryButton
                  style={{ marginRight: "15px" }}
                  width="200px"
                  height="40px"
                  fontSize="16px"
                  onClick={handleCancelButton}
                >
                  Back
                </SecondaryButton>
                <PrimaryButton
                  width="200px"
                  height="40px"
                  fontSize="16px"
                  onClick={handleGoToCheckoutButton}
                >
                  Go To Checkout
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
