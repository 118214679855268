import { useState } from "react";

import Dialog from "@mui/material/Dialog";
import styled from "@emotion/styled";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function InputQuantityCom({
  key,
  itemId,
  quantity,
  availableQuantity,
  onQuantityChange,
}) {
  const [localQuantity, setLocalQuantity] = useState(Number(quantity));

  {
    /* alert for backorder
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

*/
  }
  const increment = () => {
    const newQuantity = localQuantity + 1;
    setLocalQuantity(newQuantity);
    onQuantityChange(itemId, newQuantity);
  };

  const decrement = () => {
    if (localQuantity > 1) {
      const newQuantity = localQuantity - 1;
      setLocalQuantity(newQuantity);
      onQuantityChange(itemId, newQuantity);
    }
  };

  {
    /* 
  const checkQuantity = (value) => {
    if (value > availableQuantity) {
      setDialogMessage(
        "You can place backorders for any remaining quantities that exceed the current orderable quantity. If you prefer not to place a backorder, kindly adjust the quantity of the item in your order to match the currently available stock."
      );
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
    }
  };
  */
  }

  // Update local state and check quantity on user input
  const handleChange = (event) => {
    let value = Math.floor(Number(event.target.value));
    value = Math.max(value, 1); // Ensure value is at least 1
    setLocalQuantity(value);
    // checkQuantity(value);  //check qty for backorder
  };

  // Propagate change to parent component when input loses focus or user presses Enter
  const handleBlur = () => {
    onQuantityChange(itemId, Number(localQuantity));
    //checkQuantity(localQuantity);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.blur(); // Triggers onBlur
    }
  };

  {
    /* 
  const StyledDialogContent = styled(DialogContent)`
    text-align: center;
    padding: 30px;
  `;
  
 */
  }

  {
    /* 
  const StyledDialogActions = styled(DialogActions)`
    display: flex;
    justify-content: space-evenly;
    padding: 15px;
  `;
    
 */
  }

  return (
    <div className="flex justify-between items-center ">
      <div className="flex items-center">
        {/*   <button
          onClick={decrement}
          type="button"
          className="text-base text-qgray"
        >
          -
        </button>  */}
        {/* <span className="text-qblack">{localQuantity}</span>  */}

        <input
          type="number"
          value={localQuantity}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className="text-center w-full border border-qgray-border px-30 py-1" // Adjusted classes for styling
          style={{
            flexGrow: 1,
            margin: "0 8px",
            borderRadius: "4px",
            maxWidth: "100px",
          }}
        />
        {/* Alert for backorder message */}
        {/* <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs" // Limit the width of the dialog
          fullWidth
        >
         <StyledDialogTitle id="alert-dialog-title">
                    Warning
                  </StyledDialogTitle>
          <StyledDialogContent style={{ paddingTop: "30", paddingBottom: "5" }}>
            <DialogContentText id="alert-dialog-description">
              {dialogMessage}
            </DialogContentText>
          </StyledDialogContent>
          <StyledDialogActions
            style={{ marginTop: "30", justifyContent: "flex-end" }}
          >
            {" "}
            <button
              type="button"
              className="black-btn text-sm font-semibold w-1/4 h-10"
              onClick={() => setDialogOpen(false)}
            >
              Confirm
            </button>
          </StyledDialogActions>
        </Dialog> */}
        {/*  <button
          onClick={increment}
          type="button"
          className="text-base text-qgray"
        >
          +
        </button> */}
      </div>
    </div>
  );
}
