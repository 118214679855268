import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import InputCom from "../../components/Helpers/InputCom";
import { checkout } from "../../actions/checkoutAction";
import PrimaryButton from "../Helpers/Buttons/PrimaryButton";
import SecondaryButton from "../Helpers/Buttons/SecondaryButton";
import ImportItems from "./ImportOrderFile";

const OrderInfoButton = ({
  isPoNumberVerified,
  userAccountInfo,
  cartItems,
  dropShip,
  isPreorder,
  shippingInfo,
  billingInfo,
  deliveryInfo,
  // totalPrice,
  // discountedTotalPrice,
  // savedTotal,
  toggleCheckoutLoading,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // Import file (Data vaildation)
  const handleProceedToImportFile = async () => {
    // If po number is not entered
    if (
      billingInfo.poNumber === null ||
      billingInfo.poNumber === undefined ||
      billingInfo.poNumber === ""
    ) {
      return alert("Please enter a PO Number");
    }

    // Check if poNumber validation is complete or not
    if (!isPoNumberVerified) {
      return alert("Please verify your PO Number before proceeding");
    } else {
    }

    // Check for empty or undefined shipping fields
    const requiredFields = [
      { value: billingInfo.erpAddressId, fieldName: "Billing Address ID" },
      { value: shippingInfo.erpAddressId, fieldName: "Shipping Address ID" },
      { value: shippingInfo.companyName, fieldName: "Name" },
      { value: shippingInfo.address1, fieldName: "Address1" },
      { value: shippingInfo.city, fieldName: "City" },
      { value: shippingInfo.countryId, fieldName: "Country" },
    ];

    // Accumulate the names of empty shipping fields
    const emptyFields = requiredFields
      .filter((field) => !field.value && !dropShip) // Skip check if IsDropShip is true
      .map((field) => field.fieldName);

    if (emptyFields.length > 0) {
      const fieldNames = emptyFields.join(", ");
      return alert(
        `It looks like your shipping information isn’t correct.\nPlease get in touch with our customer service team for assistance.\n\nEmpty fields : ${fieldNames}.\n`
        // `It looks like your shipping or billing address information isn’t correct. Please get in touch with our customer service team for assistance.`
      );
    }

    // Turn on loading spinner
    toggleCheckoutLoading(true);

    // Construct the orderInfo object
    const orderInfo = {
      UserId: tokenUserId,
      // Accouncting
      erpCustomerId: userAccountInfo.erpCustomerId,
      erpPaymentTermId: userAccountInfo.erpPaymentTermId,
      accountName: userAccountInfo.accountName,
      accountNumber: userAccountInfo.accountNumber,
      contactPhone: userAccountInfo.contactPhone,
      contactEmail: userAccountInfo.contactEmail,
      // Shipping address
      ErpShipToAddressId: shippingInfo.erpAddressId,
      ShipToName: shippingInfo.companyName,
      ShipToAddress1: shippingInfo.address1,
      ShipToAddress2: shippingInfo.address2,
      ShipToCity: shippingInfo.city,
      ShipToStateId: shippingInfo.stateId,
      ShipToCountryId: shippingInfo.countryId,
      StateName: shippingInfo.stateName,
      CountryName: shippingInfo.countryName,
      ShipToZipcode: shippingInfo.zipCode,
      RequestedShipDate: deliveryInfo.requestedDate,
      IsDropShip: dropShip,
      // Delivery
      SignatureService: deliveryInfo.signatureService,
      Note: deliveryInfo.memo,
      ErpCarrierId: deliveryInfo.erpCarrierId,
      ErpCarrierServiceId: deliveryInfo.erpServiceId,
      ServiceName: deliveryInfo.serviceName,
      CarrierName: deliveryInfo.carrierName,
      // Billing address
      BillToName: billingInfo.companyName,
      BillToAddress1: billingInfo.address1,
      BillToAddress2: billingInfo.address2,
      BillToCity: billingInfo.city,
      BillToStateId: billingInfo.stateId,
      BillToStateName: billingInfo.stateName,
      BillToCountryId: billingInfo.countryId,
      BillToCountryName: billingInfo.countryName,
      BillToZipcode: billingInfo.zipCode,
      ErpBillToAddressId: billingInfo.erpAddressId,

      // Item
      IsPreorder: isPreorder,
      CustomerPO: billingInfo.poNumber,
    };

    // Use navigate to send the orderInfo to ImportItems page
    navigate(`/Importorderfile`, { state: { orderInfo } });
  };

  // Navigate back to the previous page
  const handleCancelButton = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="button-container " style={{ marginBottom: "50px" }}>
        {/* Cancelation button */}
        <SecondaryButton
          style={{ marginRight: "15px" }}
          width="200px"
          height="40px"
          fontSize="16px"
          onClick={handleCancelButton}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          width="200px"
          height="40px"
          fontSize="16px"
          onClick={handleProceedToImportFile}
        >
          Go to import file
        </PrimaryButton>
      </div>
    </div>
  );
};

export default OrderInfoButton;
