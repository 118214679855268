import Axios from "axios";
import {
  POST_CONTACTUS_REQUEST,
  POST_CONTACTUS_SUCCESS,
  POST_CONTACTUS_FAIL,
} from "../constants/companyConstants";

// eslint-disable-next-line import/prefer-default-export
export const postContactusReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_CONTACTUS_REQUEST:
      return { loading: true };
    case POST_CONTACTUS_SUCCESS:
      return { loading: false, response: action.payload };
    case POST_CONTACTUS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
