import Axios from "axios";

import {
  GET_SUBUSERLIST_REQUEST,
  GET_SUBUSERLIST_SUCCESS,
  GET_SUBUSERLIST_FAIL,
  GET_CHECKSUBNAME_REQUEST,
  GET_CHECKSUBNAME_SUCCESS,
  GET_CHECKSUBNAME_FAIL,
  POST_SUBUSER_REQUEST,
  POST_SUBUSER_SUCCESS,
  POST_SUBUSER_FAIL,
  EDIT_SUBUSER_REQUEST,
  EDIT_SUBUSER_SUCCESS,
  EDIT_SUBUSER_FAIL,
  GET_SUBUSER_REQUEST,
  GET_SUBUSER_SUCCESS,
  GET_SUBUSER_FAIL,
  PUT_STATUS_REQUEST,
  PUT_STATUS_SUCCESS,
  PUT_STATUS_FAIL,
} from "../constants/subuserConstants";

const apiUrl = process.env.REACT_APP_API_URL;

// eslint-disable-next-line import/prefer-default-export
export const getSubuserList = () => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }
  console.log("token", token.result.token);

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_SUBUSERLIST_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(`${apiUrl}/userAuth/customerUsers/${tokenUserId}`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_SUBUSERLIST_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_SUBUSERLIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_SUBUSERLIST_SUCCESS, payload: data.data });
  //console.log("payload: ", data.data);
};

export const changeSubuserStatus = (requestedUserId) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }
  console.log("token", token.result.token);

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: PUT_STATUS_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.put(
      `${apiUrl}/userAuth/customerUsers/changeStatus/${requestedUserId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: PUT_STATUS_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: PUT_STATUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: PUT_STATUS_SUCCESS, payload: data.data });
  //console.log("payload: ", data.data);
};

export const verifyUserName = (username) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }
  console.log("token", token.result.token);

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_CHECKSUBNAME_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(`${apiUrl}/userAuth/verifyUserName/${username}`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    let errorMsg; // for error response
    if (error.response && error.response.status === 401) {
      errorMsg = error.response.data.error;
    } else if (error.response && error.response.status === 400) {
      errorMsg = error.response.data.error;
    } else {
      errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
    }
    dispatch({
      type: GET_CHECKSUBNAME_FAIL,
      payload: errorMsg,
    });
    throw new Error(errorMsg);
  }
};

export const addSubUser = (newSubuser) => async (dispatch) => {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to the login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  console.log("newSubuser :", newSubuser);
  dispatch({ type: POST_SUBUSER_REQUEST, payload: tokenUserId });

  let { response } = "";
  try {
    response = await Axios.post(
      `${apiUrl}/userAuth/customerUsers/${tokenUserId}`,
      newSubuser,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: POST_SUBUSER_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: POST_SUBUSER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: POST_SUBUSER_SUCCESS, payload: response });
  console.log("addSubUser in action : ", response.data);
  return response.data; // Rethrow the error
};

export const getSubuser = (subuserId) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }
  console.log("token", token.result.token);

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_SUBUSER_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(
      `${apiUrl}/userAuth/customerUser/${tokenUserId}/${subuserId}`,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_SUBUSER_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_SUBUSER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_SUBUSER_SUCCESS, payload: data.data });
  //console.log("payload: ", data.data);
};

export const editSubuser = (subuserInfo) => async (dispatch) => {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  let subuserId = subuserInfo.subuserId;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to the login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: EDIT_SUBUSER_REQUEST, payload: tokenUserId });

  try {
    const response = await Axios.put(
      `${apiUrl}/userAuth/customerUser/${tokenUserId}/${subuserId}`,
      subuserInfo,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );

    dispatch({ type: EDIT_SUBUSER_SUCCESS, payload: response.data });

    return response; // Return the response data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: EDIT_SUBUSER_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: EDIT_SUBUSER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }

    console.log("error : ", error);
    throw error; // Rethrow the error
  }
};
