import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";

import { listProducts } from "../../../actions/productActions";
import "react-input-range/lib/css/index.css";
import BreadcrumbCom from "../../BreadcrumbCom";
import ProductCardStyleOne from "../../Helpers/Cards/ProductCardStyleOne";
import DataIteration from "../../Helpers/DataIteration";
import Layout from "../../Partials/Layout";
import ProductsFilter from "./ProductsFilter";
import LoadingBox from "../../Helpers/Loaders/LoadingBox";

export default function AllProductPage() {
  const { search } = useLocation(); //search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login"; // check if redirect url exist

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;
  const [filters, setFilter] = useState({
    //garmentType
    Top: false,
    Pants: false,
    Jacket: false,
    //color
    BKM: false,
    BLK: false,
    CBL: false,
    CHC: false,
    HPK: false,
    HTR: false,
    KHI: false,
    MBL: false,
    NVY: false,
    NYM: false,
    PEW: false,
    PPL: false,
    RED: false,
    RYL: false,
    TEL: false,
    WHT: false,
    WIN: false,

    //fit
    Petite: false,
    Regular: false,
    Short: false,
    Tall: false,
    //size
    XXS: false,
    SM: false,
    XS: false,
    MD: false,
    LG: false,
    XL: false,
    twoXL: false,
    threeXL: false,
    fourXL: false,
    fiveXL: false,
    XSP: false,
    SMP: false,
    MDP: false,
    LGP: false,
    XLP: false,
    twoXLS: false,
    threeXLS: false,
    XST: false,
    SMT: false,
    MDT: false,
    LGT: false,
    XLT: false,
    threeXLT: false,

    //Inseam
    twofive: false,
    twosix: false,
    twoseven: false,
    twoeight: false,
    twoninedotfive: false,
    threeone: false,
    threethree: false,
  });
  const [volume, setVolume] = useState({ min: 0, max: 100 });
  const [price, setPrice] = useState({ min: 0, max: 100 });

  //validate logined user
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  useEffect(() => {
    if (!userInfo) {
      console.log("You need to login.");
      alert("Please sign in to access our website. ");
      navigate(redirect);
    } else {
      console.log("You are already logined ");
    }

    /* Garment Type */
    let GarmentTypeURL = "";
    if (filters.Top) {
      GarmentTypeURL += "Top,";
    }
    if (filters.Pants) {
      GarmentTypeURL += "Pant,";
    }
    if (filters.Jacket) {
      GarmentTypeURL += "Jacket,";
    }
    if (GarmentTypeURL !== "") {
      GarmentTypeURL = GarmentTypeURL.substring(0, GarmentTypeURL.length - 1);
    }

    /* Fit */
    let fitURL = "";
    if (filters.Petite) {
      fitURL += "Petite,";
    }
    if (filters.Regular) {
      fitURL += "Reg,";
    }
    if (filters.Short) {
      fitURL += "Short,";
    }
    if (filters.Tall) {
      fitURL += "Tall,";
    }
    if (fitURL !== "") {
      fitURL = fitURL.substring(0, fitURL.length - 1);
    }

    /* Size */
    /* Regular */
    let sizeURL = "";
    if (filters.XXS) {
      sizeURL += "XXS,";
    }
    if (filters.SM) {
      sizeURL += "SM,";
    }
    if (filters.XS) {
      sizeURL += "XS,";
    }
    if (filters.MD) {
      sizeURL += "MD,";
    }
    if (filters.LG) {
      sizeURL += "LG,";
    }
    if (filters.XL) {
      sizeURL += "XL,";
    }
    if (filters.twoXL) {
      sizeURL += "2XL,";
    }
    if (filters.threeXL) {
      sizeURL += "3XL,";
    }
    if (filters.fourXL) {
      sizeURL += "4XL,";
    }
    if (filters.fiveXL) {
      sizeURL += "5XL,";
    }
    /* Petite */
    if (filters.XSP) {
      sizeURL += "XSP,";
    }
    if (filters.SMP) {
      sizeURL += "SMP,";
    }
    if (filters.MDP) {
      sizeURL += "MDP,";
    }
    if (filters.LGP) {
      sizeURL += "LGP,";
    }
    if (filters.XLP) {
      sizeURL += "XLP,";
    }
    if (filters.twoXLP) {
      sizeURL += "2XLP,";
    }
    if (filters.threeXLP) {
      sizeURL += "3XLP,";
    }
    /* Short */
    if (filters.XSS) {
      sizeURL += "XSS,";
    }
    if (filters.SMS) {
      sizeURL += "SMS,";
    }
    if (filters.MDS) {
      sizeURL += "MDS,";
    }
    if (filters.LGS) {
      sizeURL += "LGS,";
    }
    if (filters.XLS) {
      sizeURL += "XLS,";
    }
    if (filters.twoXLS) {
      sizeURL += "2XLS,";
    }
    if (filters.threeXLS) {
      sizeURL += "3XLS,";
    }
    /* Tall */
    if (filters.XST) {
      sizeURL += "XST,";
    }
    if (filters.SMT) {
      sizeURL += "SMT,";
    }
    if (filters.MDT) {
      sizeURL += "MDT,";
    }
    if (filters.LGT) {
      sizeURL += "LGT,";
    }

    if (filters.XLT) {
      sizeURL += "XLT,";
    }
    if (filters.twoXLT) {
      sizeURL += "2XLT,";
    }
    if (filters.threeXLT) {
      sizeURL += "3XLT,";
    }
    if (sizeURL !== "") {
      sizeURL = sizeURL.substring(0, sizeURL.length - 1);
    }

    /* Insaem Length */
    let inseamURL = "";
    if (filters.twofive) {
      inseamURL += "25,";
    }
    if (filters.twosix) {
      inseamURL += "26,";
    }
    if (filters.twoseven) {
      inseamURL += "27,";
    }
    if (filters.twoeight) {
      inseamURL += "28,";
    }
    if (filters.twoninedotfive) {
      inseamURL += "29.5,";
    }
    if (filters.threeone) {
      inseamURL += "31,";
    }
    if (filters.threethree) {
      inseamURL += "33,";
    }
    if (inseamURL !== "") {
      inseamURL = inseamURL.substring(0, inseamURL.length - 1);
    }

    /* Price */
    let priceFromURL = "";
    if (price.min || price.min === 0) {
      priceFromURL += price.min.toString();
    }
    let priceToURL = "";
    if (price.max || price.max === 0) {
      priceToURL += price.max.toString();
    }

    /* Color */
    let colorURL = "";
    if (filters.BKM) {
      colorURL += "BKM,";
    }
    if (filters.BLK) {
      colorURL += "BLK,";
    }
    if (filters.CBL) {
      colorURL += "CBL,";
    }
    if (filters.CHC) {
      colorURL += "CHC,";
    }
    if (filters.HPK) {
      colorURL += "HPK,";
    }
    if (filters.HTR) {
      colorURL += "HTR,";
    }
    if (filters.KHI) {
      colorURL += "KHI,";
    }
    if (filters.MBL) {
      colorURL += "MBL,";
    }
    if (filters.NVY) {
      colorURL += "NVY,";
    }
    if (filters.NYM) {
      colorURL += "NYM,";
    }
    if (filters.PEW) {
      colorURL += "PEW,";
    }
    if (filters.PPL) {
      colorURL += "PPL,";
    }
    if (filters.RED) {
      colorURL += "RED,";
    }
    if (filters.RYL) {
      colorURL += "RYL,";
    }
    if (filters.TEL) {
      colorURL += "TEL,";
    }
    if (filters.WHT) {
      colorURL += "WHT,";
    }
    if (filters.WIN) {
      colorURL += "WIN,";
    }
    if (colorURL !== "") {
      console.log(colorURL);

      colorURL = colorURL.substring(0, colorURL.length - 1);
    }

    dispatch(
      listProducts({
        programId: 4080,
        GarmentType: GarmentTypeURL,
        fit: fitURL,
        size: sizeURL,
        inseam: inseamURL,
        color: colorURL,
        priceFrom: priceFromURL,
        priceTo: priceToURL,
      })
    );
  }, [dispatch, filters, price, navigate, redirect, userInfo]);

  const checkboxHandler = (e) => {
    const { name } = e.target;
    setFilter((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const volumeHandler = (e) => {
    setVolume(e);
  };

  const priceHandler = () => {
    setPrice(volume);
  };

  const [storage, setStorage] = useState(null);
  const filterStorage = (value) => {
    setStorage(value);
  };
  const [filterToggle, setToggle] = useState(false);

  return (
    <>
      <Layout>
        <div className="products-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom
              paths={[
                { name: "Home", path: "/" },
                {
                  name: "Momentum Lab Coats",
                  path: "/Collection/MomentumLabCoats",
                },
              ]}
            />
            {/* Collection Banner */}
            <div className="breadcrumb-wrapper font-400 text-[13px] text-qblack mb-[23px]">
              <img
                src="https://maevn-images.s3.us-east-2.amazonaws.com/banners/2024-06/2024-06-20_Collection-Banner-Part-01/Momentum-Lab-Coats_m1_SU24.jpg"
                alt="Collection"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div className="w-full lg:flex lg:space-x-[30px] lg:space-y-[1px]">
              {/* filter */}
              <div className="lg:w-[270px]">
                <ProductsFilter
                  filterToggle={filterToggle}
                  filterToggleHandler={() => setToggle(!filterToggle)}
                  filters={filters}
                  checkboxHandler={checkboxHandler}
                  volume={volume}
                  volumeHandler={volumeHandler}
                  priceHandler={priceHandler}
                  storage={storage}
                  filterstorage={filterStorage}
                  className="mb-[30px]"
                />
              </div>
              <div className="flex-1">
                <div className="products-sorting w-full bg-white md:h-[70px] flex md:flex-row flex-col md:space-y-0 space-y-5 md:justify-between md:items-center p-[30px] mb-[20px]">
                  <div>
                    <p className="font-400 text-[13px]">
                      <span className="text-qgray"> Showing</span>{" "}
                      {products && products.length !== 0
                        ? products.data.result.length
                        : 0}{" "}
                      results
                    </p>
                  </div>
                  {/*<div className="flex space-x-3 items-center">
                    <span className="font-400 text-[13px]">Sort by:</span>
                    <div className="flex space-x-3 items-center border-b border-b-qgray">
                      <span className="font-400 text-[13px] text-qgray">
                        Default
                      </span>
                      <span>
                        <svg
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1 1L5 5L9 1" stroke="#9A9A9A" />
                        </svg>
                      </span>
                    </div>
                </div> */}
                  <button
                    onClick={() => setToggle(!filterToggle)}
                    type="button"
                    className="w-10 lg:hidden h-10 rounded flex justify-center items-center border border-qyellow text-qyellow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                      />
                    </svg>
                  </button>
                </div>

                <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1  xl:gap-[30px] gap-5 mb-[40px]">
                  <>
                    {loading && <LoadingBox />}
                    {/*  {products && products.length !== 0 ? (*/}
                    {products && products.length !== 0 ? (
                      <DataIteration
                        datas={products.data.result}
                        startLength={0}
                        endLength={products.data.result.length}
                      >
                        {({ datas }) => (
                          <div data-aos="fade-up" key={datas.id}>
                            <ProductCardStyleOne datas={datas} />
                          </div>
                        )}
                      </DataIteration>
                    ) : (
                      <DataIteration
                        datas={products}
                        startLength={0}
                        endLength={0}
                      >
                        {" "}
                        {/*        <-----------emplty component*/}
                        {({ datas }) => (
                          <div data-aos="fade-up" key={datas.id}>
                            <ProductCardStyleOne datas={datas} />
                          </div>
                        )}
                      </DataIteration>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      }
    </>
  );
}
