export const GET_STATEMENTHEADER_REQUEST = "GET_STATEMENTHEADER_REQUEST";
export const GET_STATEMENTHEADER_SUCCESS = "GET_STATEMENTHEADER_SUCCESS";
export const GET_STATEMENTHEADER_FAIL = "GET_STATEMENTHEADER_FAIL";

export const POST_REQUESTNUM_REQUEST = "POST_REQUESTNUM_REQUEST";
export const POST_REQUESTNUM_SUCCESS = "POST_REQUESTNUM_SUCCESS";
export const POST_REQUESTNUM_FAIL = "POST_REQUESTNUM_FAIL";

export const GET_STATEMENTLINE_REQUEST = "GET_STATEMENTLINE_REQUEST";
export const GET_STATEMENTLINE_SUCCESS = "GET_STATEMENTLINE_SUCCESS";
export const GET_STATEMENTLINE_FAIL = "GET_STATEMENTLINE_FAIL";

export const GET_STATEMENTEPDF_REQUEST = "GET_STATEMENTEPDF_REQUEST";
export const GET_STATEMENTPDF_SUCCESS = "GET_STATEMENTPDF_SUCCESS";
export const GET_STATEMENTPDF_FAIL = "GET_STATEMENTPDF_FAIL";
