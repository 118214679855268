export const GET_SUBUSERLIST_REQUEST = "GET_SUBUSERLIST_REQUEST";
export const GET_SUBUSERLIST_SUCCESS = "GET_SUBUSERLIST_SUCCESS";
export const GET_SUBUSERLIST_FAIL = "GET_SUBUSERLIST_FAIL";

export const POST_SUBUSER_REQUEST = "POST_SUBUSER_REQUEST";
export const POST_SUBUSER_SUCCESS = "POST_SUBUSER_SUCCESS";
export const POST_SUBUSER_FAIL = "POST_SUBUSER_FAIL";

export const GET_CHECKSUBNAME_REQUEST = "GET_CHECKSUBNAME_REQUEST";
export const GET_CHECKSUBNAME_SUCCESS = "GET_CHECKSUBNAME_SUCCESS";
export const GET_CHECKSUBNAME_FAIL = "GET_CHECKSUBNAME_FAIL";

export const EDIT_SUBUSER_REQUEST = "EDIT_SUBUSER_REQUEST";
export const EDIT_SUBUSER_SUCCESS = "EDIT_SUBUSER_SUCCESS";
export const EDIT_SUBUSER_FAIL = "EDIT_SUBUSER_FAIL";

export const GET_SUBUSER_REQUEST = "GET_SUBUSER_REQUEST";
export const GET_SUBUSER_SUCCESS = "GET_SUBUSER_SUCCESS";
export const GET_SUBUSER_FAIL = "GET_SUBUSER_FAIL";

export const PUT_STATUS_REQUEST = "PUT_STATUS_REQUEST";
export const PUT_STATUS_SUCCESS = "PUT_STATUS_SUCCESS";
export const PUT_STATUS_FAIL = "PUT_STATUS_FAIL";
