import Accodion from "../Helpers/Accodion";
import InputCom from "../Helpers/InputCom";
import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function Faq() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="faq-page-wrapper w-full mb-10">
        <div className="page-title w-full">
          <PageTitle
            title="Frequently Asked Questions"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "FAQ", path: "/faq" },
            ]}
          />
        </div>
      </div>
      <div className="contact-wrapper w-full mb-10">
        <div className="container-x mx-auto">
          <div className="main-wrapper w-full lg:flex lg:space-x-[30px]">
            <div className="w-full mb-10 lg:mb-0">
              <h1 className="text-qblack font-bold text-[22px] mb-4">
                Frequently asked questions
              </h1>
              <div className="flex flex-col space-y-7 justify-between mb-10">
                <Accodion
                  title="CAN I ORDER FROM YOUR WEB SITE?"
                  des="We do not sell directly to consumers. Please visit our store locator in our company website (https://maevnuniforms.com) to find the retailer closest to you. "
                />
                <Accodion
                  init
                  title="WHEN NEW COLLECTIONS RELEASE?"
                  des="Thank you for your suggestion. Your suggestion will be forwarded on to our merchandising team. We consider many different variables when adding new styles to our line. Feedback from our customers is one of the most important variables considered. Visit our web site regularly to see what is new."
                />
                <Accodion
                  title="WHERE IS THE CLOSEST RETAILER?"
                  des="Please visit our store locator to find the retailer closest to you."
                />
                <Accodion
                  title="WHAT IS THE INSEAM LENGTH?"
                  des="Regular 31″, Petite 28″, Tall 33 You can check a complete measurement chart."
                />
                <Accodion
                  title="I HAVE A PROBLEM WITH YOUR MERCHANDISE. CAN I RETURN IT TO YOU?"
                  des="Maevn stands behind the quality of their merchandise 100% and will always accept returns from our retailers for damaged or defective merchandise. 
                  We can't accept a return from you due to the fact that we do not sell to individual consumers. 
                  Please return the merchandise to the store you bought it from. 
                  If the store has any questions in regards to the return please have them contact our customer service department for reassurance that we will take the items back."
                />
              </div>
            </div>

            {/*
            Have any question
            <div className="flex-1">
              <div className="bg-white sm:p-10 p-5">
                <div className="title flex flex-col items-center">
                  <h1 className="lg:text-[34px] text-xl font-bold text-qblack">
                    Have Any Qustion
                  </h1>
                  <span className="-mt-5 block">
                    <svg
                      width="354"
                      height="30"
                      viewBox="0 0 354 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 28.8027C17.6508 20.3626 63.9476 8.17089 113.509 17.8802C166.729 28.3062 341.329 42.704 353 1"
                        stroke="#FFBB38"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                </div>
                <div className="inputs mt-5">
                  <div className="mb-4">
                    <InputCom
                      label="Frist Name*"
                      placeholder="Demo Name"
                      name="first_name"
                      inputClasses="h-[50px]"
                    />
                  </div>
                  <div className="mb-4">
                    <InputCom
                      label="Email Address*"
                      placeholder="info@quomodosoft.com"
                      name="email"
                      inputClasses="h-[50px]"
                    />
                  </div>
                  <div className="mb-5">
                    <h6 className="input-label text-qgray capitalize text-[13px] font-normal block mb-2 ">
                      Message*
                    </h6>
                    <textarea
                      placeholder="Type your message here"
                      className="w-full h-[105px] focus:ring-0 focus:outline-none p-3 border border-qgray-border placeholder:text-sm"
                    ></textarea>
                  </div>
                  <div>
                    <a href="#">
                      <div className="black-btn text-sm font-semibold w-full h-[50px] flex justify-center items-center">
                        <span>Send Now</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
          </div>
          */}
          </div>
        </div>
      </div>
    </Layout>
  );
}
