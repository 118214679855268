import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function PrivacyPolicy() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Privacy Policy", path: "privacy-policy" },
            ]}
            title="Privacy Policy"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-10">
              <p className="text-[15px] text-qgraytwo leading-7">
                We here at Maevn® are dedicated to protecting your privacy. We
                have implemented a variety of security measures to maintain the
                safety of your personal information when you place an order or
                enter your personal information. We offer the use of a secure
                layer. All supplied sensitive information is transmitted via
                Secure Socket Layer (SSL) technology and then encrypted into our
                database to be only accessed by those authorized with special
                access rights to our systems, and are required to keep the
                information confidential. By using our site, you consent to our
                website privacy policy.
              </p>
            </div>
            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack mb-2">
                DO WE DISCLOSE ANY INFORMATION TO OUTSIDE PARTIES?
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7 mb-10">
                We do not sell, trade, or otherwise transfer to outside parties
                your personally identifiable information. This does not include
                trusted third parties who assist us in operating our website,
                conducting our business, or servicing you, so long as those
                parties agree to keep this information confidential. We may also
                release your information when we believe release is appropriate
                to comply with the law, enforce our site policies, or protect
                ours or others rights, property, or safety. However,
                non-personally identifiable visitor information may be provided
                to other parties for marketing, advertising, or other uses.
              </p>
              <div>
                <h2 className="text-[18px] font-medium text-qblack mb-2">
                  CALIFORNIA ONLINE PRIVACY PROTECTION ACT COMPLIANCE
                </h2>
                <p className="text-[15px] text-qgraytwo leading-7 mb-10">
                  Because we value your privacy we have taken the necessary
                  precautions to be in compliance with the California Online
                  Privacy Protection Act. We therefore will not distribute your
                  personal information to outside parties without your consent.
                </p>
              </div>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[18px] font-medium text-qblack  mb-2">
                CONTACTING US
              </h2>
              <p className="text-[15px] text-qgraytwo leading-7 mb-20">
                If there are any questions regarding this privacy policy you may
                contact us using the information below.
              </p>
            </div>

            <div className="content-item w-full mb-10">
              <h2 className="text-[15px] font-medium text-qblack">
                <strong>Maevn Corp</strong>
              </h2>
              <p className="text-[15px] text-qblack leading-7">
                13950 Senlac Dr. #300
                <br />
                Farmers Branch, TX 75234
                <br />
                800 – 993 – 1841
                <br />
                <a
                  href="https://maevnuniforms.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#04ADD1" }}
                >
                  https://maevnuniforms.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
