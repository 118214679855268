import {
  ADD_BASKET_REQUEST,
  ADD_BASKET_SUCCESS,
  ADD_BASKET_FAIL,
  GET_BASKET_REQUEST,
  GET_BASKET_SUCCESS,
  GET_BASKET_FAIL,
  UPDATE_BASKET_REQUEST,
  UPDATE_BASKET_SUCCESS,
  UPDATE_BASKET_FAIL,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAIL,
  CLEAR_BASKET_REQUEST,
  CLEAR_BASKET_SUCCESS,
  CLEAR_BASKET_FAIL,
  UPDATE_QUICKORDER_REQUEST,
  UPDATE_QUICKORDER_SUCCESS,
  UPDATE_QUICKORDER_FAIL,
} from "../constants/cartConstants";

export const getCartReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BASKET_REQUEST:
      return { loading: true };
    case GET_BASKET_SUCCESS:
      return { loading: false, cartItems: action.payload };
    case GET_BASKET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const addToCartReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_BASKET_REQUEST:
      return { loading: true };
    case ADD_BASKET_SUCCESS:
      return { loading: false };
    case ADD_BASKET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateToCartReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_BASKET_REQUEST:
      return { loading: true };
    case UPDATE_BASKET_SUCCESS:
      return { loading: false };
    case UPDATE_BASKET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const deleteItemReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ITEM_REQUEST:
      return { loading: true };
    case DELETE_ITEM_SUCCESS:
      return { loading: false };
    case DELETE_ITEM_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const clearBasketReducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_BASKET_REQUEST:
      return { loading: true };
    case CLEAR_BASKET_SUCCESS:
      return { loading: false };
    case CLEAR_BASKET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateCartQuickOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_QUICKORDER_REQUEST:
      return { loading: true };
    case UPDATE_QUICKORDER_SUCCESS:
      return { loading: false };
    case UPDATE_QUICKORDER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
