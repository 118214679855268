import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  getStatementHeader,
  postStatementRequestNum,
} from "../../../actions/statementAction";
import "../Dashboard.css";

export default function StatementTab(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [requestLoading, setRequestLoading] = useState(false);

  // Call statement data from API
  const statementInfo = useSelector((state) => state.getStatementHeader);
  const { statementHeader, loading, error } = useSelector(
    (state) => state.getStatementHeader
  );

  useEffect(() => {
    dispatch(getStatementHeader());
  }, []);

  /*
  if (
    statementHeader === undefined ||
    statementHeader === null ||
    statementHeader.length === 0
  ) {
    return <div>Your statement history is empty.</div>;
  }
  */

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleRequestStatementClick = async () => {
    try {
      // Start loading
      setRequestLoading(true);

      // Call API
      const response = await dispatch(postStatementRequestNum());

      if (
        response.data.result.id ||
        response?.data?.statusCode === 200 ||
        response?.data?.isSuccess
      ) {
        alert(
          ` Your statement (Requested No.${response.data.result.id}) has been successfully retrieved. `
        );

        // After adding new statement data, reload the statementHeader data
        dispatch(getStatementHeader());
      } else {
        alert(
          "Something happens. Please contact to Maevn customer service team to request your latest statement."
        );
      }
    } catch (error) {
      console.error(error);
      alert(
        "Something happens. Please contact to Maevn customer service team to request your latest statement."
      );
    } finally {
      // End loading
      setRequestLoading(false);
    }
  };

  // 날짜 포맷 변경 함수
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  // view statement
  const viewStatement = (statementId) => {
    console.log("Viewing invoice with statementId:", statementId);

    // for moving dashboard tab (update parent component's state)
    props.setSelectedStatementId(statementId);
  };

  return (
    <>
      {/* Statement Title */}
      <div className="flex justify-center items-center text-2xl font-semibold p-4 statement-title">
        STATEMENT
      </div>

      {/* Request Button */}
      {requestLoading ? (
        <button
          className="bg-blue-500 text-white font-semibold py-1 px-3 text-sm rounded shadow-md mb-4 opacity-50 cursor-not-allowed"
          disabled
        >
          Your statement is loading... Please wait.
        </button>
      ) : (
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-3 text-sm rounded shadow-md transition duration-300 ease-in-out mb-4 request-statement-button"
          onClick={handleRequestStatementClick}
        >
          Request New Statement
        </button>
      )}

      <div className="support-tab w-full ">
        <div className="relative w-full overflow-x-auto sm:rounded-lg statement-table ">
          <table className="w-full text-sm text-left text-gray-500">
            <tbody>
              {/* Table header */}
              <tr className="text-sm text-qblack font-semibold bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom">
                <td className="py-5 whitespace-nowrap w-[285px] text-center">
                  Request No
                </td>
                <td className="py-5 whitespace-nowrap w-[285px] text-center">
                  Statement Date
                </td>
                <td className="py-5 whitespace-nowrap w-[285px] text-center">
                  Status
                </td>
                <td className="py-5 whitespace-nowrap text-center">Action</td>
              </tr>
              {/* Table header end */}
              {statementHeader?.map((statement, index) => (
                <tr
                  className="bg-white hover:bg-gray-50"
                  key={statement.requestedNo}
                >
                  <td className="text-center py-3 px-2">
                    <span>{statement.requestedNo}</span>
                  </td>
                  <td className="text-center py-3 px-2">
                    <span> {formatDate(statement.statementDate)}</span>
                  </td>
                  <td className="text-center py-3 px-2">
                    <span>{statement.status}</span>
                  </td>
                  <td className="text-center py-3 flex space-x-2.5 justify-center">
                    {statement.status === "Processed" ? (
                      // "View" 버튼
                      <Link
                        type="button"
                        className="border border-qgray w-[120px] h-[28px] rounded flex justify-center items-center bg-blue-500"
                        onClick={() => viewStatement(statement.requestedNo)}
                        to="/myaccount#viewStatement"
                        style={{
                          background: "#001F3F",
                          fontFamily: "Arial, sans-serif",
                          fontSize: "13px",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        <span className="text-center">View Statement</span>
                      </Link>
                    ) : (
                      /*   <Link
                        type="button"
                        className="border border-qgray w-[120px] h-[28px] rounded flex justify-center items-center bg-blue-500"
                        to={`/viewStatement/${statement.requestedNo}`}
                        style={{
                          background: "#001F3F",
                          fontFamily: "Arial, sans-serif",
                          fontSize: "13px",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        <span className="text-center text-white">
                          View Statement
                        </span>
                      </Link>*/
                      // Empty space or alternative content for non-Processed status
                      <div className="w-[120px] h-[28px]"></div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
