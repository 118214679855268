import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Cart from "../../../Cart";
import Compair from "../../../Helpers/icons/Compair";
import ThinBag from "../../../Helpers/icons/ThinBag";
import ThinLove from "../../../Helpers/icons/ThinLove";
import ThinPeople from "../../../Helpers/icons/ThinPeople";
import SearchBox from "../../../Helpers/SearchBox";
import { getCart } from "../../../../actions/cartAction";
import SearchBox2 from "../../../Helpers/SearchBox2";

export default function Middlebar({ className }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //validate user
  const { search } = useLocation(); //search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login"; // check if redirect url exist

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading, error } = userSignin;

  //permission
  const wishListPermission = userInfo?.result.user.permissions.WishList;
  const shoppingCartPermission = userInfo?.result.user.permissions.ShoppingCart;

  //get cart
  const cartItems = useSelector((state) => state.getCart);
  const { cartLoading, cartError, cartItems: items } = cartItems;
  const [numberOfItems, setNumberOfItems] = useState(0);

  // Dispatch getCart on component mount to get the # of items in shopping cart
  useEffect(() => {
    dispatch(getCart(userInfo?.result?.user?.userId));
  }, [dispatch, userInfo?.result?.user?.userId]);

  // Update the numberOfItems whenever items changes
  useEffect(() => {
    if (items?.data?.result) {
      const totalQty = items.data.result.reduce((total, currentItem) => {
        return total + currentItem.qty;
      }, 0);
      setNumberOfItems(totalQty);
    }
  }, [items]);

  return (
    <div className={`w-full h-[86px] bg-white ${className}`}>
      <div className="container-x mx-auto h-full">
        <div className="relative h-full">
          <div className="flex justify-between items-center h-full">
            <div>
              <a href="/">
                <img
                  //width="152"
                  //height="36"
                  //src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
                  width="200"
                  height="70"
                  src={`https://maevn.s3.us-east-2.amazonaws.com/banners/maevnLogo.PNG`}
                  alt="logo"
                />
              </a>
            </div>
            <div className="w-[517px] h-[44px]">
              <SearchBox2 className="search-com" />
            </div>
            <div className="flex space-x-6 items-center">
              {/* <div className="compaire relative">
                <a href="/products-compaire">
                  <span>
                    <Compair />
                  </span>
                </a>
                <span className="w-[18px] h-[18px] rounded-full bg-qyellow absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px]">
                  2
                </span> 
                </div> */}
              {/* {wishListPermission === 1 && (
                <div className="favorite relative">
                  <a href="/wishlist">
                    <span>
                      <ThinLove />
                    </span>
                  </a>
                  <span className="w-[18px] h-[18px] rounded-full bg-qyellow absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px]">
                    -
                  </span>
                </div>
              )}
              */}
              {shoppingCartPermission === 1 && (
                <div className="cart-wrapper group relative py-4">
                  <div className="cart relative cursor-pointer">
                    <a href="/cart">
                      <span>
                        <ThinBag />
                      </span>
                    </a>
                    <span className="w-[18px] h-[18px] rounded-full bg-qyellow absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px]">
                      {numberOfItems}
                    </span>
                  </div>

                  {/* <div className="fixed left-0 top-0 w-full h-full z-40"></div> */}
                  {/* hidden group-hover:block" */}
                  {/* <Cart className="absolute -right-[45px] top-11 z-50 hidden group-hover:block" /> */}
                </div>
              )}
              <div>
                <button type="button">
                  <span>
                    <ThinPeople />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
