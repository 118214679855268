import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import Layout from "../../Partials/Layout";
import Thumbnail from "../Login/Thumbnail";
import { postemailrequest } from "../../../actions/passwordresetAction";

export default function EmailRequest() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const [usernameNull, setUsernameNull] = useState(null);
  const [emailNull, setEmailNull] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const dispatch = useDispatch();

  const [values, setValues] = useState({
    username: "",
    email: "",
  });

  const [placeholders, setPlaceholders] = useState({
    username: "Your username",
    email: "Your E-mail address",
  });

  const handleSubmit = async () => {
    // validate null value
    if (!username.trim()) {
      setUsernameNull("Please enter your username");
    } else {
      setUsernameNull("");
    }

    if (!email.trim()) {
      setEmailNull("Please enter your E-mail");
    } else {
      setEmailNull("");
    }

    if (!username.trim() || !email.trim()) {
      return;
    }

    console.log("username:", username);
    console.log("Email:", email);

    // call api
    try {
      const response = await dispatch(postemailrequest(username, email));
      console.log("response in EmailRequset", response);
      if (response && response.isSuccess) {
        alert(
          "A password reset link has been successfully sent to your email. Please check your inbox and follow the instructions. Note that reset link is valid for one hour from the time of your request. "
        );

        // Move to home page
        navigate("/Login");
      } else if (response && response.code === "ERR_BAD_REQUEST") {
        alert("An unexpected error occurred.");
      }
    } catch (error) {
      alert(
        "Your account information is invalid. Please provide the information registered with Maevn or Please contact your administrator."
      );
    }
  };

  function handleInputChange(event) {
    const { name, value } = event.target;

    if (name === "username") {
      setUsername(value);
    } else if (name === "email") {
      setEmail(value);
    }
    setValues({ ...values, [name]: value });
  }

  useEffect(() => {}, []);

  // For mobile device
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = {
    maxWidth: isMobile ? "400px" : "500px",
    width: isMobile ? "350px" : "450px",
    padding: "44px 40px 52px 40px",
    marginTop: "10px",
    backgroundColor: "white",
    border: "1px solid #E0E0E0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  return (
    <>
      {/*<Layout childrenClasses="pt-0 pb-0">  */}
      <div className="login-page-wrapper w-full py-20 flex flex-col justify-between min-h-screen">
        {/* <div className="w-full py-10"> */}
        <div className="flex-grow">
          <div className="login-form-positon w-full mx-auto px-4 lg:px-8 mb-20  flex justify-center items-center min-h-screen">
            <div className="login-container" style={containerStyle}>
              <div className="w-full">
                <div
                  className="login-title-area"
                  style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "15px",
                  }}
                >
                  <h1
                    style={{
                      color: "var(--Base-Black, #000)",
                      fontFamily: "Lato",
                      fontSize: "25px",
                      fontWeight: "700",
                    }}
                  >
                    Password Assistance
                  </h1>
                </div>
                <div className="flex mt-3 text-sm">
                  <p
                    style={{
                      color: "navy",
                      fontFamily: "Roboto",
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal",
                      marginBottom: "-20px", // Space between  Welcome back text and Username label
                    }}
                  >
                    Enter the pervious username and email address associated
                    with your Maevn account and we’ll send you a link to reset
                    your password.
                  </p>
                </div>

                <div className="input-area">
                  <div
                    className="input-item mb-5 "
                    style={{
                      marginTop: "40px",
                    }}
                  >
                    <div>
                      <TextField
                        required
                        margin="username"
                        fullWidth
                        placeholder={
                          username || usernameNull
                            ? username
                              ? "Username"
                              : usernameNull
                            : "Username"
                        }
                        className={`input-field px-4 w-full h-full bg-[#FAFAFA] focus:ring-0 focus:outline-none ${
                          usernameNull
                            ? "placeholder-red-500"
                            : "placeholder-text-base"
                        }`}
                        type="username"
                        id="username"
                        name="username"
                        value={values.username}
                        onChange={handleInputChange}
                        sx={{
                          "& .MuiInputBase-input::placeholder": {
                            color: usernameNull ? "red" : "inherit",
                          },
                        }}
                      ></TextField>
                    </div>
                  </div>

                  <div className="input-item mb-5 ">
                    <div>
                      <TextField
                        required
                        margin="email"
                        fullWidth
                        placeholder={
                          email || emailNull
                            ? email
                              ? "E-mail"
                              : emailNull
                            : "E-mail"
                        }
                        className={`input-field px-4 w-full h-full bg-[#FAFAFA] focus:ring-0 focus:outline-none ${
                          usernameNull
                            ? "placeholder-red-500"
                            : "placeholder-text-base"
                        }`}
                        type="email"
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={handleInputChange}
                        sx={{
                          "& .MuiInputBase-input::placeholder": {
                            color: emailNull ? "red" : "inherit",
                          },
                        }}
                      ></TextField>
                    </div>
                  </div>

                  <div className="signin-area">
                    <div className="flex justify-center">
                      <button
                        type="button"
                        className="flex justify-center items-center"
                        style={{
                          width: "419px",
                          height: "50px",
                          padding: "10px 20px",
                          backgroundColor:
                            "var(--Primary-color---Teal, #04ADD1)",
                          color: "#FDFDFD",
                          fontFamily: "Roboto",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "normal",
                          borderRadius: "5px",
                          marginBottom: "15px",
                        }}
                        onClick={handleSubmit}
                      >
                        <span>Request Reset Link</span>
                      </button>
                    </div>

                    <div className="flex mt-3 text-sm ">
                      <span
                        style={{
                          fontSize: "14px",
                          textDecoration: "underline",
                        }}
                      >
                        Has your email changed? <br />
                      </span>
                    </div>
                    <div className="flex mt-3 text-sm ">
                      <p
                        style={{
                          color: "var(--Secondary-txt-Grey, #7C7C7C)",
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      >
                        If you no longer use the email address associated with
                        your Maevn account, you may contact Customer Service to
                        restore your account.
                      </p>
                    </div>
                  </div>
                  <div className="signup-area flex justify-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer */}
        <div className="w-full text-center py-4">
          <p className="text-xs text-gray-400">
            © 2024 Maevn Uniforms. All Rights Reserved.
          </p>
        </div>
      </div>
      {/*  </Layout> */}
    </>
  );
}
