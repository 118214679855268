export const GET_INVOICEHEADER_REQUEST = "GET_INVOICEHEADER_REQUEST";
export const GET_INVOICEHEADER_SUCCESS = "GET_INVOICEHEADER_SUCCESS";
export const GET_INVOICEHEADER_FAIL = "GET_INVOICEHEADER_FAIL";

export const GET_INVOICELINE_REQUEST = "GET_INVOICELINE_REQUEST";
export const GET_INVOICELINE_SUCCESS = "GET_INVOICELINE_SUCCESS";
export const GET_INVOICELINE_FAIL = "GET_INVOICELINE_FAIL";

export const SET_SELECTEDINVOICE_REQUEST = "SET_SELECTEDINVOICE_REQUEST";
export const SET_SELECTEDINVOICE_SUCCESS = "SET_SELECTEDINVOICE_SUCCESS";
export const SET_SELECTEDINVOICE_FAIL = "SET_SELECTEDINVOICE_FAIL";

export const GET_INVOICEPDF_REQUEST = "GET_INVOICEPDF_REQUEST";
export const GET_INVOICEPDF_SUCCESS = "GET_INVOICEPDF_SUCCESS";
export const GET_INVOICEPDF_FAIL = "GET_INVOICEPDF_FAIL";
