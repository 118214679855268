export const GET_SHIPINFO_REQUEST = "GET_SHIPINFO_REQUEST";
export const GET_SHIPINFO_SUCCESS = "GET_SHIPINFO_SUCCESS";
export const GET_SHIPINFO_FAIL = "GET_SHIPINFO_FAIL";

export const PATCH_DEFAULTSHIP_REQUEST = "PATCH_DEFAULTSHIP_REQUEST";
export const PATCH_DEFAULTSHIP_SUCCESS = "PATCH_DEFAULTSHIP_SUCCESS";
export const PATCH_DEFAULTSHIP_FAIL = "PATCH_DEFAULTSHIP_FAIL";

export const PATCH_DEFAULTSERVICE_REQUEST = "PATCH_DEFAULTSERVICE_REQUEST";
export const PATCH_DEFAULTSERVICE_SUCCESS = "PATCH_DEFAULTSERVICE_SUCCESS";
export const PATCH_DEFAULTSERVICE_FAIL = "PATCH_DEFAULTSERVICE_FAIL";

export const GET_CHECKPO_REQUEST = "GET_CHECKPO_REQUEST";
export const GET_CHECKPO_SUCCESS = "GET_CHECKPO_SUCCESS";
export const GET_CHECKPO_FAIL = "GET_CHECKPO_FAIL";

export const GET_CHECKOUT_REQUEST = "GET_CHECKOUT_REQUEST";
export const GET_CHECKOUT_SUCCESS = "GET_CHECKOUT_SUCCESS";
export const GET_CHECKOUT_FAIL = "GET_CHECKOUT_FAIL";

export const GET_CONFIRMATION_REQUEST = "GET_CONFIRMATION_REQUEST";
export const GET_CONFIRMATION_SUCCESS = "GET_CONFIRMATION_SUCCESS";
export const GET_CONFIRMATION_FAIL = "GET_CONFIRMATION_FAIL";
