import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { listProducts } from "../../actions/productActions";
import "react-input-range/lib/css/index.css";
import BreadcrumbCom from "../BreadcrumbCom";
import ProductCardStyleOne from "../Helpers/Cards/ProductCardStyleOne";
import DataIteration from "../Helpers/DataIteration";
import Layout from "../Partials/Layout";
import ProductsFilter from "./ProductsFilter";
import LoadingBox from "../Helpers/Loaders/LoadingBox";

export default function AllProductPage() {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const [filters, setFilter] = useState({
    Top: false,
    Pants: false,
    Jacket: false,
    BLK: false,
    ACM: false,
    ANM: false,
    ANTQ: false,
    ASM: false,
    AVO: false,
    AWU: false,
    AWV: false,
    BAT: false,
    BBB: false,
    BBM: false,
    BCB: false,
    BEM: false,
    BEN: false,
    BFZ: false,
    BIB: false,
    BLC: false,
    BLD: false,
    BRBY: false,
    BUC: false,
    CBRS: false,
    CDBF: false,
    CHV: false,
    CIL: false,
    CMC: false,
    COD: false,
    COL: false,
    COP: false,
    CUC: false,
    CUP: false,
    DCT: false,
    DDS: false,
    DDZ: false,
    DGT: false,
    DIL: false,
    DOW: false,
    DRC: false,
    DTD: false,
    DWG: false,
    DYG: false,
    EDG: false,
    EEH: false,
    ELP: false,
    ESF: false,
    EVN: false,
    FAL: false,
    FBB: false,
    FFC: false,
    FFN: false,
    FLY: false,
    FOF: false,
    FON: false,
    FSS: false,
    FVF: false,
    GAS: false,
    GCM: false,
    GDGN: false,
    GMW: false,
    GST: false,
    HCH: false,
    HFL: false,
    HGO: false,
    HHH: false,
    HHN: false,
    HIR: false,
    HLD: false,
    HPT: false,
    HTDD: false,
    JUP: false,
    KLT: false,
    KTD: false,
    LDDS: false,
    LIS: false,
    LLLL: false,
    LON: false,
    MAMG: false,
    MDME: false,
    MDN: false,
    MFT: false,
    MLCK: false,
    MOB: false,
    MSR: false,
    NBM: false,
    NIG: false,
    NTCM: false,
    NUB: false,
    ODS: false,
    OFP: false,
    ONM: false,
    PAW: false,
    PIM: false,
    PKL: false,
    PLP: false,
    PNP: false,
    PPD: false,
    PPP: false,
    PPS: false,
    PRPK: false,
    PSR: false,
    PUG: false,
    QSV: false,
    SAD: false,
    SBM: false,
    SBR: false,
    SBU: false,
    SFR: false,
    SHH: false,
    SHR: false,
    SPB: false,
    SPK: false,
    SPO: false,
    SPT: false,
    SSB: false,
    SSF: false,
    SST: false,
    SUF: false,
    SWD: false,
    SWG: false,
    TAL: false,
    TGDZ: false,
    TOP: false,
    TPP: false,
    TRD: false,
    TTC: false,
    TTD: false,
    TWKL: false,
    UPS: false,
    WFW: false,
    WVL: false,
    Petite: false,
    Reg: false,
    Short: false,
    Tall: false,
    twoXL: false,
    twoXLP: false,
    twoXLT: false,
    threeXL: false,
    threeXLP: false,
    threeXLT: false,
    fourXL: false,
    fiveXL: false,
    LG: false,
    LGP: false,
    LGT: false,
    MD: false,
    MDP: false,
    MDT: false,
    SM: false,
    SMP: false,
    SMT: false,
    XL: false,
    XLP: false,
    XLT: false,
    XS: false,
    XSP: false,
    XST: false,
    XXS: false,
    XXSP: false,
  });

  useEffect(() => {
    dispatch(
      listProducts({
        programId: 6,
        GarmentType: filters.Top
          ? "Top"
          : filters.Pants
          ? "Pant"
          : filters.Jacket
          ? "Jacket"
          : "",
      })
    );
  }, [dispatch, filters.Jacket, filters.Pants, filters.Top]);

  const checkboxHandler = (e) => {
    const { name } = e.target;

    if (filters[name] === false) {
      Object.keys(filters).forEach((v) => {
        if (v === name) {
          setFilter((prevState) => ({
            ...prevState,
            [v]: true,
          }));
        } else {
          setFilter((prevState) => ({
            ...prevState,
            [v]: false,
          }));
        }
      });
    } else {
      setFilter((prevState) => ({
        ...prevState,
        [name]: !prevState[name],
      }));
    }
  };
  const [volume, setVolume] = useState({ min: 200, max: 500 });

  const [storage, setStorage] = useState(null);
  const filterStorage = (value) => {
    setStorage(value);
  };
  const [filterToggle, setToggle] = useState(false);

  return (
    <>
      <Layout>
        <div className="products-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom />
            <div className="w-full lg:flex lg:space-x-[30px]">
              {/* filter */}
              <div className="lg:w-[270px]">
                <ProductsFilter
                  filterToggle={filterToggle}
                  filterToggleHandler={() => setToggle(!filterToggle)}
                  filters={filters}
                  checkboxHandler={checkboxHandler}
                  volume={volume}
                  volumeHandler={(value) => setVolume(value)}
                  storage={storage}
                  filterstorage={filterStorage}
                  className="mb-[30px]"
                />
              </div>

              <div className="flex-1">
                <div className="products-sorting w-full bg-white md:h-[70px] flex md:flex-row flex-col md:space-y-0 space-y-5 md:justify-between md:items-center p-[30px] mb-[40px]">
                  <div>
                    <p className="font-400 text-[13px]">
                      <span className="text-qgray"> Showing</span> 1–16 of 66
                      results
                    </p>
                  </div>
                  <div className="flex space-x-3 items-center">
                    <span className="font-400 text-[13px]">Sort by:</span>
                    <div className="flex space-x-3 items-center border-b border-b-qgray">
                      <span className="font-400 text-[13px] text-qgray">
                        Default
                      </span>
                      <span>
                        <svg
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1 1L5 5L9 1" stroke="#9A9A9A" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <button
                    onClick={() => setToggle(!filterToggle)}
                    type="button"
                    className="w-10 lg:hidden h-10 rounded flex justify-center items-center border border-qyellow text-qyellow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                      />
                    </svg>
                  </button>
                </div>
                <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1  xl:gap-[30px] gap-5 mb-[40px]">
                  <>
                    {loading && <LoadingBox />}
                    {products && products.length !== 0 ? (
                      <DataIteration
                        datas={products.data.result}
                        startLength={0}
                        endLength={products.data.result.length - 1}
                      >
                        {({ datas }) => (
                          <div data-aos="fade-up" key={datas.id}>
                            <ProductCardStyleOne datas={datas} />
                          </div>
                        )}
                      </DataIteration>
                    ) : (
                      <DataIteration
                        datas={products}
                        startLength={0}
                        endLength={0}
                      >
                        {" "}
                        {/*        <-----------emplty component*/}
                        {({ datas }) => (
                          <div data-aos="fade-up" key={datas.id}>
                            <ProductCardStyleOne datas={datas} />
                          </div>
                        )}
                      </DataIteration>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
