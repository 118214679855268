import Axios from "axios";
import {
  GET_SHIPINFO_REQUEST,
  GET_SHIPINFO_SUCCESS,
  GET_SHIPINFO_FAIL,
  PATCH_DEFAULTSHIP_REQUEST,
  PATCH_DEFAULTSHIP_SUCCESS,
  PATCH_DEFAULTSHIP_FAIL,
  PATCH_DEFAULTSERVICE_REQUEST,
  PATCH_DEFAULTSERVICE_SUCCESS,
  PATCH_DEFAULTSERVICE_FAIL,
  GET_CHECKPO_REQUEST,
  GET_CHECKPO_FAIL,
  GET_CHECKPO_SUCCESS,
  GET_CHECKOUT_REQUEST,
  GET_CHECKOUT_FAIL,
  GET_CHECKOUT_SUCCESS,
  GET_CONFIRMATION_REQUEST,
  GET_CONFIRMATION_SUCCESS,
  GET_CONFIRMATION_FAIL,
} from "../constants/checkoutConstants";

const apiUrl = process.env.REACT_APP_API_URL;

// eslint-disable-next-line import/prefer-default-export
export const getShippingInfo = () => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_SHIPINFO_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(`${apiUrl}/checkout/userInfo/${tokenUserId}`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_SHIPINFO_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_SHIPINFO_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_SHIPINFO_SUCCESS, payload: data });
};

export const updateDefaultShipToAddress = (addressId) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: PATCH_DEFAULTSHIP_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.patch(
      `${apiUrl}/checkout/changeDefaultShippingInfo/${tokenUserId}/${addressId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );

    console.log(
      "PATCH_DEFAULTSHIP_REQUEST, tokenUserId : ",
      tokenUserId,
      " ,addressId : ",
      addressId
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: PATCH_DEFAULTSHIP_FAIL,
        payload: error.response.data.error,
      });
      console.log(
        "PATCH_DEFAULTSHIP_FAIL,   tokenUserId : ",
        tokenUserId,
        " ,addressId : ",
        addressId,
        " ,error : ",
        error.response.data.error
      );
    } else {
      dispatch({
        type: PATCH_DEFAULTSHIP_SUCCESS,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: PATCH_DEFAULTSHIP_SUCCESS, payload: data });
};

export const updateDefaultCarrierService =
  (erpCarrierId, erpServiceId) => async (dispatch) => {
    // if there is no token, go to login page
    const token = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;

    let tokenUserId = null;
    if (token) {
      tokenUserId = JSON.parse(token.result.user.userId);
    }

    // redirect to login page and return
    if (!token) {
      console.log("no token", token);
      return;
    }

    dispatch({ type: PATCH_DEFAULTSERVICE_REQUEST, payload: tokenUserId });

    let { data } = "";
    try {
      data = await Axios.patch(
        `${apiUrl}/checkout/changeDefaultCarrierService/${tokenUserId}/${erpCarrierId}/${erpServiceId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token.result.token}` },
        }
      );

      console.log(
        "PATCH_DEFAULTSERVICE_REQUEST, tokenUserId : ",
        tokenUserId,
        " ,erpCarrierId : ",
        erpCarrierId,
        " ,erpServiceId : ",
        erpServiceId
      );
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({
          type: PATCH_DEFAULTSERVICE_FAIL,
          payload: error.response.data.error,
        });

        console.log(
          "PATCH_DEFAULTSERVICE_FAIL, tokenUserId : ",
          tokenUserId,
          " ,erpCarrierId : ",
          erpCarrierId,
          " ,erpServiceId : ",
          erpServiceId
        );
      } else {
        dispatch({
          type: PATCH_DEFAULTSERVICE_SUCCESS,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
      return;
    }
    dispatch({ type: PATCH_DEFAULTSERVICE_SUCCESS, payload: data });

    console.log(
      "PATCH_DEFAULTSERVICE_SUCCESS, tokenUserId : ",
      tokenUserId,
      " ,erpCarrierId : ",
      erpCarrierId,
      " ,erpServiceId : ",
      erpServiceId
    );
  };

export const checkDuplicatedPoNumber =
  (poNumber, erpCustomerId) => async (dispatch) => {
    // if there is no token, go to the login page
    const token = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;

    let tokenUserId = null;
    if (token) {
      tokenUserId = JSON.parse(token.result.user.userId);
    }

    // redirect to the login page and return
    if (!token) {
      console.log("no token", token);
      return;
    }

    dispatch({ type: GET_CHECKPO_REQUEST, payload: tokenUserId });

    try {
      const response = await Axios.get(
        `${apiUrl}/checkout/verifyPo/${tokenUserId}/${erpCustomerId}/${poNumber}`,
        {
          headers: { Authorization: `Bearer ${token.result.token}` },
        }
      );

      console.log("GET_CHECKPO_REQUEST, tokenUserId : ", tokenUserId);

      dispatch({ type: GET_CHECKPO_SUCCESS, payload: response.data });

      console.log(
        "GET_CHECKPO_SUCCESS, tokenUserId : ",
        tokenUserId,
        " ,erpCarrierId : "
      );

      return response; // Return the response data
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({
          type: GET_CHECKPO_FAIL,
          payload: error.response.data.error,
        });

        console.log("GET_CHECKPO_FAIL, tokenUserId : ", tokenUserId);
      } else {
        dispatch({
          type: GET_CHECKPO_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }

      throw error; // Rethrow the error
    }
  };

export const checkout = (orderInfo) => async (dispatch) => {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to the login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_CHECKOUT_REQUEST, payload: tokenUserId });

  try {
    console.log("GET_CHECKOUT_REQUEST, tokenUserId : ", tokenUserId);
    console.log("GET_CHECKOUT_REQUEST, orderInfo : ", orderInfo);

    const response = await Axios.post(
      `${apiUrl}/checkout/${tokenUserId}`,
      orderInfo,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );

    dispatch({ type: GET_CHECKOUT_SUCCESS, payload: response.data });

    console.log(
      "GET_CHECKOUT_SUCCESS, tokenUserId : ",
      tokenUserId,
      " ,erpCarrierId : "
    );

    return response; // Return the response data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_CHECKOUT_FAIL,
        payload: error.response.data.error,
      });

      console.log("GET_CHECKOUT_FAIL, tokenUserId : ", tokenUserId);
    } else {
      dispatch({
        type: GET_CHECKOUT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    console.log("error : ", error);

    throw error; // Rethrow the error
  }
};

export const getOrderConfirmation = (poNumber) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_CONFIRMATION_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(
      `${apiUrl}/checkout/orderConfirmation/${tokenUserId}/${poNumber}`,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );
    console.log("API response data: ", data); // Add this line
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_CONFIRMATION_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_CONFIRMATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({ type: GET_CONFIRMATION_SUCCESS, payload: data });
  console.log("data.data.result  in order action ", data);
};
