import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { createContext } from "react";

import thunk from "redux-thunk";
import {
  productListReducer,
  productDetailReducer,
  getPromotionItemsReducer,
} from "./reducers/productReducers";
import {
  changePasswordReducer,
  getMyAccountReducer,
  userSigninReducer,
} from "./reducers/userAuthReducer";
import {
  addToCartReducer,
  getCartReducer,
  updateToCartReducer,
  deleteItemReducer,
  clearBasketReducer,
  updateCartQuickOrderReducer,
} from "./reducers/cartReducers";
import {
  getShippingInfoReducer,
  updateDefaultCarrierServiceReducer,
  updateDefaultShipToAddressReducer,
  checkDuplicatedPoNumberReducer,
  checkoutReducer,
  getOrderConfirmationReducer,
} from "./reducers/checkoutReducers";
import {
  getRolesReducer,
  addRolesReducer,
  editRolesReducer,
  getPermissionsReducer,
} from "./reducers/roleReducers";
import {
  getSubuserListReducer,
  changeSubuserStatusReducer,
  getSubuserReducer,
  editSubuserReducer,
} from "./reducers/subuserReducer";
import {
  getOrderDetailsReducer,
  getOrdersReducer,
  getOrderCancellationRequestReducer,
} from "./reducers/orderReducer";
import {
  getCollectionsReducer,
  getFilteredInventoryReducer,
  getInitialInventoryReducer,
  getStylesReducer,
} from "./reducers/quickorderReducer";
import {
  exportInvoicePdfReducer,
  getInvoiceHeaderReducer,
  getInvoiceLineReducer,
} from "./reducers/invoiceReducer";
import {
  postCreditCardPaymentReducer,
  postMaevnPaymentReducer,
  postPaymentConfirmReduxReducer,
  setCreditBalanceToReduxReducer,
  setSelectedInvoicesToReduxReducer,
} from "./reducers/paymentReducer";
import {
  confirmResetpasswordReducer,
  postemailrequestReducer,
} from "./reducers/passwordResetReducer";
import {
  getStatementHeaderReducer,
  getStatementLineReducer,
  postStatementRequestNumReducer,
} from "./reducers/statementReducer";
import { getAddresssListReducer } from "./reducers/userReducer";
import { postContactusReducer } from "./reducers/companyReducer";
import {
  bulkCheckoutReducer,
  productValidationReducer,
} from "./reducers/bulkorderReducer";

export const Store = createContext();

const initialState = {
  userSignin: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  },
};
const reducer = combineReducers({
  // sign-in
  userSignin: userSigninReducer,
  // password-reset
  postemailrequest: postemailrequestReducer,
  confirmResetpassword: confirmResetpasswordReducer,
  // dashboard
  getMyAccount: getMyAccountReducer,
  getAddressList: getAddresssListReducer,
  changePasswordAction: changePasswordReducer,
  postContactus: postContactusReducer,
  // product
  productList: productListReducer,
  productDetails: productDetailReducer,
  getPromotionItems: getPromotionItemsReducer,
  // shopping cart
  addToCart: addToCartReducer,
  getCart: getCartReducer,
  updateCart: updateToCartReducer,
  removeItem: deleteItemReducer,
  clearCart: clearBasketReducer,
  // quick order
  updateCartQuickOrder: updateCartQuickOrderReducer,
  getInitialInventory: getInitialInventoryReducer,
  getFilteredInventory: getFilteredInventoryReducer,
  getCollections: getCollectionsReducer,
  getStyles: getStylesReducer,
  // checkout
  getShippingInfo: getShippingInfoReducer,
  updateDefaultShipToAddress: updateDefaultShipToAddressReducer,
  updateDefaultCarrierService: updateDefaultCarrierServiceReducer,
  checkDuplicatedPoNumber: checkDuplicatedPoNumberReducer,
  checkout: checkoutReducer,
  // bulkorder
  productValidation: productValidationReducer,
  bulkCheckout: bulkCheckoutReducer,
  // role
  getRoles: getRolesReducer,
  addRole: addRolesReducer,
  editRole: editRolesReducer,
  getPermissions: getPermissionsReducer,
  // sub-user
  getSubuserList: getSubuserListReducer,
  changeSubuserStatus: changeSubuserStatusReducer,
  getSubuser: getSubuserReducer,
  editSubuser: editSubuserReducer,
  // order-confirmation
  getOrderConfirmation: getOrderConfirmationReducer,
  // orders
  getOrders: getOrdersReducer,
  // order details
  getOrderDetails: getOrderDetailsReducer,
  // cancel request
  getOrderCancellationRequest: getOrderCancellationRequestReducer,
  // invoice
  getInvoiceHeader: getInvoiceHeaderReducer,
  getInvoiceLine: getInvoiceLineReducer,
  exportInvoicePdf: exportInvoicePdfReducer,

  // statement
  getStatementHeader: getStatementHeaderReducer,
  postStatementRequestNum: postStatementRequestNumReducer,
  getStatementLine: getStatementLineReducer,
  // payment
  setCreditBalanceToRedux: setCreditBalanceToReduxReducer,
  setSelectedInvoicesToRedux: setSelectedInvoicesToReduxReducer,
  postCreditCardPayment: postCreditCardPaymentReducer,
  postMaevnPayment: postMaevnPaymentReducer,
  postPaymentConfirmRedux: postPaymentConfirmReduxReducer,
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
