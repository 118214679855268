import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyUserName, addSubUser } from "../../../actions/subuserAction";
import "../../SubAccount/subAccount.css";
import PrimaryButton from "../../Helpers/Buttons/PrimaryButton";
import SecondaryButton from "../../Helpers/Buttons/SecondaryButton";

export default function AddUserForm({ className, roleList }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");

  const [userNameNullError, setUsereNameNullError] = useState("");
  const [isUserNameVerified, setIsUserNameVerified] = useState(false);

  async function handleConfirmClick(event) {
    event.preventDefault();

    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if uername is entered
    if (!userName) {
      return alert("Please enter a username");
    }

    // Check if password is entered
    if (!password) {
      return alert("Please enter a password");
    }

    // Check if email is entered
    if (!email) {
      return alert("Please enter an email");
    }

    // Check if email is a valid email format
    if (!emailRegex.test(email)) {
      return alert("Please enter a valid email address.");
    }

    // Check if phone is entered
    if (!phone) {
      return alert("Please enter a phone");
    }

    // Check if roleId is selected
    if (!selectedRoleId) {
      return alert("Please select a role");
    }

    // Check if poNumber validation is complete or not
    if (!isUserNameVerified) {
      return alert("Please verify your username before proceeding");
    }

    // Create the object to be sent in the POST request
    const newSubuser = {
      username: userName,
      password,
      email,
      phone,
      roleId: selectedRoleId,
    };
    //console.log("newSubuser : ", newSubuser);

    // Call Post user addSubuser API
    try {
      const response = await dispatch(addSubUser(newSubuser));

      // password reset success
      if (response.isSuccess) {
        alert("New sub-user has been successfully created.");
      } else {
        // password reset fail
        alert(response.result);
      }
      // Move to home page
      navigate(`/customerusers`);
    } catch (error) {
      // Handle the error
      alert(error.message);
    }
  }

  const handleCancelClick = () => {
    navigate("/customerusers");
  };

  const handleUserNameChange = (event) => {
    const value = event.target.value;
    setUserName(value);

    // Reset the verification status when the user changes the Role name
    setIsUserNameVerified(false);
  };

  const handleVerifyUserName = async (username) => {
    // Check if username is Null or not
    if (!username) {
      setUsereNameNullError("Please verify your Username");
      return;
    }

    // Check if there is space in username or not
    if (/\s/.test(username)) {
      return alert("Please refrain from using spaces in the username");
    }

    try {
      // Call validation API for username and wait for the response
      const response = await dispatch(verifyUserName(username));
      console.log("response : ", response);

      // Check this username is verified or not
      setUserName(username);
      setIsUserNameVerified(true);
      alert("Your Username is verified.");
    } catch (error) {
      console.log("error : ", error);
      // Username is duplicated
      alert("This Username is duplicated. Please enter other Username.");
      setUserName("");
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  if (!roleList) {
    return null;
  }

  return (
    <div className={`w-full ${className || ""} mt-12 max-w-xl mx-auto`}>
      <div className="w-full max-w-md max-w-xl mx-auto">
        <form onSubmit={handleConfirmClick} className="formStyles">
          {/* Form Title */}
          <h2 className="formTitle">Add User</h2>

          <div>
            <label className="subuser-input-label" htmlFor="username">
              Username
            </label>
            <div className="flex">
              <input
                id="usernameForSubuser"
                type="usernameForSubuser"
                className={`py-2 px-3 subuser-input-box ${
                  userNameNullError ? "warning" : ""
                }`}
                value={userName}
                onChange={handleUserNameChange}
                placeholder={
                  userNameNullError
                    ? "Please enter a username"
                    : "Please enter a username"
                }
                required
              />
            </div>

            <div>
              <button
                className="check-duplicate-button"
                onClick={(event) => {
                  event.preventDefault(); // Add this line
                  handleVerifyUserName(userName);
                }}
              >
                Verify your username
              </button>
            </div>
          </div>
          <div>
            <label className="subuser-input-label" htmlFor="password">
              Password
            </label>
            <input
              className="subuser-input-box"
              id="passwordForSubuser"
              type="passwordForSubuser"
              placeholder="Enter password"
              onChange={handlePasswordChange}
              required
            />
            <p className="password-requirements">
              Must have 1 uppercase, 1 lowercase, numbers and special
              characters.
            </p>
          </div>
          <div>
            <label className="subuser-input-label" htmlFor="email">
              Email
            </label>
            <input
              className="subuser-input-box"
              id="email"
              type="email"
              placeholder="Enter email"
              onChange={handleEmailChange}
            />
          </div>
          <div>
            <label className="subuser-input-label" htmlFor="phone">
              Phone number
            </label>
            <input
              className="subuser-input-box"
              id="phone"
              type="tel"
              placeholder="Enter phone number"
              onChange={handlePhoneChange}
            />
          </div>

          <div>
            <label className="subuser-input-label">Role </label>
            <div>
              <select
                className="role-select-custom-select"
                onChange={(e) => setSelectedRoleId(e.target.value)}
              >
                <option value="">Select Role</option>
                {roleList.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.roleName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex justify-center">
            <SecondaryButton
              width="185px"
              height="40px"
              fontSize="18px"
              onClick={() => handleCancelClick()}
              style={{ marginBottom: "10px" }}
            >
              Cancel
            </SecondaryButton>
            <div style={{ width: "15px" }} />
            <PrimaryButton
              width="185px"
              height="40px"
              fontSize="18px"
              onClick={(event) => {
                event.preventDefault();
                handleConfirmClick(event);
              }}
              style={{ marginBottom: "10px" }}
            >
              Add
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
}
