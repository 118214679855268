import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import DropshipModal from "../DropshipModal";
import { updateDefaultShipToAddress } from "../../../../actions/checkoutAction";
import CountryState from "../../../../data/countryState.json";
import PrimaryButton from "../../../Helpers/Buttons/PrimaryButton";
import "../OrderReview.css";
import SecondaryButton from "../../../Helpers/Buttons/SecondaryButton";

const ShippingSection = ({
  shippingInfo,
  setShippingInfo,
  showAddressList,
  setShowAddressList,
  dropShip,
  setDropShip,
  defaultDeliveryOption,
  setTempDeliveryInfo,
  tempDeliveryInfo,
  setDeliveryInfo,
  deliveryInfo,
  shipToAddresses,
}) => {
  const dispatch = useDispatch();

  // Dropship
  const [defaultShip, setDefaultShip] = useState(false);
  const [selectedButton, setSelectedButton] = useState("delivery");
  const [modalShow, setModalShow] = useState(false);
  const [modalInputValues, setModalInputValues] = useState({
    companyName: "",
    address1: "",
    address2: "",
    zipCode: "",
    countryName: "",
    stateName: "",
    city: "",
    countryId: 0,
    stateId: 0,
  });

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // Default address
  const [selectedAddressId, setSelectedAddressId] = useState(() => {
    const defaultAddress = shipToAddresses.find(
      (address) => address.defaultTypeId
    );
    return defaultAddress ? defaultAddress.erpAddressId : null;
  });

  const [isDefaultCheckboxChecked, setIsDefaultCheckboxChecked] = useState(
    () => {
      const defaultAddress = shipToAddresses.find(
        (address) => address.defaultTypeId
      );
      return defaultAddress
        ? defaultAddress.erpAddressId === selectedAddressId
        : false;
    }
  );

  // Set state for dropship modal
  const [states, setStates] = useState(
    CountryState.find((item) => item.countryName === shippingInfo?.countryName)
      ?.states || []
  );

  // Set country for dropship modal
  const handleStateChange = (event) => {
    const selectedCountry = event.target.value;
    const selectedCountryObj = CountryState.find(
      (item) => item.countryName === selectedCountry
    );
    const selectedCountryStates = selectedCountryObj?.states || [];

    setModalInputValues({
      ...modalInputValues,
      countryName: selectedCountry,
      countryId: selectedCountryObj?.erpCountryStateId || 0,
      stateName: "",
      stateId: 0,
    });
    setStates(selectedCountryStates);
  };

  // Open dropship modal
  const handleDropShipClick = () => {
    setModalShow(true);
  };

  // Deal with 'Delivery' and 'Dropship' button
  const handleButtonClick = (buttonType) => {
    // Hide the 'section-content address-container' to enable clicking the "Optional Services" button
    // if (document.getElementsByClassName("section-content address-container")[0])
    //   document.getElementsByClassName(
    //     "section-content address-container"
    //   )[0].style.display = "none";

    setSelectedButton(buttonType);
    setShowAddressList(false);

    if (buttonType === "dropship") {
      handleDropShipClick();
    } else if (buttonType === "delivery") {
      setDropShip(0);

      const defaultAddress = shipToAddresses?.find(
        (address) => address.defaultTypeId
      );
      if (defaultAddress) {
        setShippingInfo(defaultAddress);
      } else {
        setShippingInfo(shipToAddresses[0]); // Fallback to the first address if no default is found
      }
    }
  };

  // Close dropship modal
  const handleCloseModalWithoutDropShip = () => {
    if (dropShip === 0) {
      // Edit Dropship address

      setModalShow(false);

      setSelectedButton("delivery");
      setShowAddressList(false);

      setDropShip(0);

      /* const defaultAddress = shipToAddresses?.find(
        (address) => address.defaultTypeId
      );
      if (defaultAddress) {
        setShippingInfo(defaultAddress);
      } else {
        setShippingInfo(shipToAddresses[0]);  
      } */
    } else {
      // Close without dropship
      setModalShow(false);
    }
  };

  // Save dropship address and close modal
  const handleCloseModal = () => {
    setShippingInfo(modalInputValues);
    /*
    setModalInputValues({
      companyName: "",
      address1: "",
      address2: "",
      zipCode: "",
      countryName: "",
      stateName: "",
      city: "",
      countryId: 0,
      stateId: 0,
    });
    */
    setDropShip(1); // Ensure this state is set correctly to '1' when a dropship address is used

    setShowAddressList(false); // This should ensure the address list is hidden after setting the address

    setModalShow(false); // Close the modal
  };

  // Store default address
  const handleDefaultShipConfirm = (event) => {
    // Hide the 'section-content address-container' to enable clicking the "Optional Services" button
    // if (document.getElementsByClassName("section-content address-container")[0])
    //   document.getElementsByClassName(
    //     "section-content address-container"
    //   )[0].style.display = "none";

    // Prevent the dropship address from being set as the default address
    if (dropShip === 1) {
      alert("You cannot select dropship address as a default address.");
      setDefaultShip(false);
      return;
    }

    // if default checkbox is selected
    if (selectedAddressId) {
      if (isDefaultCheckboxChecked) {
        dispatch(updateDefaultShipToAddress(selectedAddressId));
        alert("Default Shipping Address is changed.");
      }

      // Update shipToAddresses to reflect the new default address
      const newShipToAddresses = shipToAddresses.map((address) => ({
        ...address,
        defaultTypeId: address.erpAddressId === selectedAddressId,
      }));

      const selectedAddress = newShipToAddresses.find(
        (address) => address.erpAddressId === selectedAddressId
      );

      setShippingInfo(selectedAddress);
      setTempDeliveryInfo({
        ...tempDeliveryInfo,
        carrierName: defaultDeliveryOption.carrierName,
        serviceName: defaultDeliveryOption.serviceName,
        erpCarrierId: defaultDeliveryOption.erpCarrierId,
        erpServiceId: defaultDeliveryOption.erpServiceId,
      });
      setDeliveryInfo({
        ...deliveryInfo,
        carrierName: defaultDeliveryOption.carrierName,
        serviceName: defaultDeliveryOption.serviceName,
        erpCarrierId: defaultDeliveryOption.erpCarrierId,
        erpServiceId: defaultDeliveryOption.erpServiceId,
      });

      console.log("Shippingsection - newShipToAddresses", newShipToAddresses);
      console.log("Shippingsection -selectedAddress", selectedAddress);
      console.log("Shippingsection -shippingInfo", shippingInfo);
      console.log("Shippingsection -deliveryInfo", deliveryInfo);

      setDropShip(0);
      setShowAddressList(false);
    } else {
      alert("Please select an address first.");
    }
  };

  // Close the shiping address list
  const handleCancelButton = () => {
    setShowAddressList(false);
  };

  // Show user's addresses
  const toggleAddressList = () => {
    // Block the 'section-content address-container' to display user address list
    // if (
    //   document.getElementsByClassName("section-content address-container ")[0]
    // )
    //   document.getElementsByClassName(
    //     "section-content address-container "
    //   )[0].style.display = "block";

    // Control "Delivery" and "Dropship" buttons
    if (dropShip === 1) {
      setModalShow(true);
      setModalInputValues(shippingInfo); // Set modal input values to current shipping info
    } else {
      setShowAddressList(!showAddressList);
      if (!showAddressList) {
        setIsDefaultCheckboxChecked(
          shipToAddresses.some(
            (address) =>
              address.defaultTypeId &&
              address.erpAddressId === selectedAddressId
          )
        );
      }
    }
  };

  // Warning alert for empty requirement field in modal
  const isInputEmpty = () =>
    !modalInputValues.companyName ||
    !modalInputValues.address1 ||
    !modalInputValues.zipCode ||
    !modalInputValues.countryName ||
    !modalInputValues.stateName ||
    !modalInputValues.city;

  useEffect(() => {
    setButtonDisabled(isInputEmpty());
  }, [isInputEmpty, modalInputValues]);

  // Mark selected address radio button
  useEffect(() => {
    if (shipToAddresses.length > 0) {
      const defaultAddress = shipToAddresses.find(
        (address) => address.defaultTypeId
      );
      if (defaultAddress) {
        setSelectedAddressId(defaultAddress.erpAddressId);
        setIsDefaultCheckboxChecked(
          defaultAddress.erpAddressId === selectedAddressId
        );
      }
    }
  }, [shipToAddresses]);

  // Mark default address checkbox
  useEffect(() => {
    setIsDefaultCheckboxChecked(
      shipToAddresses.some(
        (address) =>
          address.defaultTypeId && address.erpAddressId === selectedAddressId
      )
    );
  }, [selectedAddressId, shipToAddresses]);

  return (
    <div style={{ marginBottom: "30px" }}>
      {/* Shipping Information Section */}
      {/* Shipping title */}
      <h2 className="section-title">1. Shipping</h2>
      <div className="section-content">
        {/* Delivery || Dropship option */}
        <div className="shipping-option-container">
          {/* Delivery button */}
          <div
            className={`button ${
              selectedButton === "delivery"
                ? "active-button"
                : "inactive-button"
            }`}
            onClick={() => handleButtonClick("delivery")}
          >
            <span
              className={`button-text ${
                selectedButton === "delivery"
                  ? "active-button-text"
                  : "inactive-button-text"
              }`}
            >
              Delivery
            </span>
          </div>

          {/* Dropship button */}
          <div
            className={`button ${
              selectedButton === "dropship"
                ? "active-button"
                : "inactive-button"
            }`}
            onClick={() => handleButtonClick("dropship")}
          >
            <span
              className={`button-text ${
                selectedButton === "dropship"
                  ? "active-button-text"
                  : "inactive-button-text"
              }`}
            >
              Dropship (+$2.00)
            </span>
          </div>
        </div>

        {/* Default Address */}
        <div className="default-address">
          <div>
            <span className="company-name">
              {shippingInfo?.companyName}{" "}
              {isDefaultCheckboxChecked &&
              selectedAddressId &&
              shippingInfo?.defaultTypeId === true &&
              shippingInfo?.erpAddressId === selectedAddressId ? (
                <span style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  (Default)
                </span>
              ) : null}
            </span>
            <br />
            <br />
            <span className="company-address">
              {shippingInfo?.address1}
            </span>{" "}
            <br />
            <span className="company-address">
              {shippingInfo?.address2 ? `${shippingInfo?.address2} \n` : null}
              {shippingInfo?.city},{shippingInfo?.stateName},
              {shippingInfo?.zipCode}
            </span>
            <br />
            <span className="company-address">
              {" "}
              {shippingInfo?.countryName}{" "}
            </span>
            <br />
            <br />
          </div>
          {/* Change || Edit button */}
          <div className="button-container">
            <button
              type="button"
              className="btn px-2 pb-2 text-sm"
              onClick={toggleAddressList}
              style={{
                color: "var(--Blue-button, #1975FF)",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                display: showAddressList ? "none" : "block", // Hide the button when address list is shown
              }}
            >
              {dropShip === 1 ? "Edit" : "Change"}
            </button>
          </div>
        </div>
      </div>
      {/* Additional Address List */}
      {dropShip === 0 && (
        <div
          className={`section-content address-container ${
            showAddressList
              ? "show-address-container"
              : "hide-address-container"
          }`}
        >
          {shipToAddresses.map((address, index) => (
            <div className="address-card" key={index}>
              <input
                type="radio"
                name="address"
                id={`address-${index}`}
                checked={selectedAddressId === address?.erpAddressId}
                onChange={() => {
                  setSelectedAddressId(address?.erpAddressId);
                  setIsDefaultCheckboxChecked(address?.defaultTypeId);
                }}
              />
              <label htmlFor={`address-${index}`}>
                <div className="custom-radio">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle cx="10" cy="10" r="9.5" stroke="#C3C3C3" />
                    {selectedAddressId === address.erpAddressId && (
                      <circle cx="10" cy="10" r="6" fill="#0B0B0B" />
                    )}
                  </svg>
                </div>

                <div className="company-info">
                  <span className="company-name"> {address.companyName} </span>
                  <br />
                  <br />
                  <span className="company-address">{address?.address1}</span>
                  <br />
                  <span className="company-address">
                    {address?.address2 ? `${address?.address2} \n` : null}
                    {address?.city},{address?.stateName},{address?.zipCode}
                  </span>
                  <br />
                  <span className="company-address">
                    {address?.countryName}
                  </span>
                  <br />
                  <br />
                </div>
              </label>
            </div>
          ))}

          {/* Set default address element */}
          <div className="no-border-address-card">
            <label htmlFor="default-address-checkbox">
              <input
                type="checkbox"
                id="default-address-checkbox"
                className="form-checkbox text-green-500"
                style={{ marginBottom: "30px" }}
                checked={isDefaultCheckboxChecked}
                onChange={(event) => {
                  const isChecked = event.target.checked;
                  setIsDefaultCheckboxChecked(isChecked);
                  if (isChecked) {
                    const defaultAddress = shipToAddresses.find(
                      (address) => address.erpAddressId === selectedAddressId
                    );
                    if (defaultAddress) {
                      defaultAddress.defaultTypeId = true;
                    }
                    shipToAddresses.forEach((address) => {
                      if (address.erpAddressId !== selectedAddressId) {
                        address.defaultTypeId = false;
                      }
                    });
                  }
                }}
              />
              <span className="ml-2 text-sm" style={{ color: "#000" }}>
                Make this address as your default
              </span>
            </label>
            <div className="delivery-button-group">
              {/* Cancelation button */}
              <SecondaryButton
                width="159px"
                height="40px"
                fontSize="16px"
                onClick={handleCancelButton}
              >
                Cancel
              </SecondaryButton>
              {/* Save & Continue button */}
              <PrimaryButton
                width="159px"
                height="40px"
                fontSize="16px"
                onClick={handleDefaultShipConfirm}
              >
                Save & Continue
              </PrimaryButton>
            </div>
          </div>
        </div>
      )}
      {/* Dropship Modal */}
      <DropshipModal
        modalShow={modalShow}
        modalInputValues={modalInputValues}
        setModalInputValues={setModalInputValues}
        handleStateChange={handleStateChange}
        handleCloseModalWithoutDropShip={handleCloseModalWithoutDropShip}
        buttonDisabled={buttonDisabled}
        setShowErrorMessage={setShowErrorMessage}
        showErrorMessage={showErrorMessage}
        handleCloseModal={handleCloseModal}
        CountryState={CountryState}
        states={states}
      />
    </div>
  );
};

export default ShippingSection;
