import BreadcrumbCom from "../../BreadcrumbCom";
import EmptyCardError from "../../EmptyCardError";
import PageTitle from "../../Helpers/PageTitle";
import Layout from "../../Partials/Layout";
import AddRoleForm from "./AddRoleForm";

export default function AddRole({ cart = true }) {
  return (
    <Layout childrenClasses={cart ? "pt-0 pb-0" : ""}>
      {cart === false ? (
        <div className="cart-page-wrapper w-full">
          <div className="container-x mx-auto">
            <BreadcrumbCom
              paths={[
                { name: "home", path: "/" },
                { name: "User Roles", path: "/customerroles" },
                { name: "Add User Roles", path: "/addrole" },
              ]}
            />
            <EmptyCardError />
          </div>
        </div>
      ) : (
        <div className="cart-page-wrapper w-full bg-white pb-[60px]">
          <div className="w-full h-25">
            <PageTitle
              title="Add Customer Role"
              breadcrumb={[
                { name: "home", path: "/" },
                { name: "User Roles", path: "/customerroles" },
                { name: "Add User Roles", path: "/addrole" },
              ]}
            />
          </div>

          <div className="w-full mt-[23px]">
            <div className="container-x mx-auto">
              <AddRoleForm className="mb-[30px]" />

              <div className="w-full mt-[30px] flex sm:justify-end"></div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
