import React from "react";
import "./Spinner.css";

export const Spinner = () => (
  <div className="spinner-overlay">
    <div className="spinner-container"></div>
    <div class="spinner-message">Processing your checkout...</div>
  </div>
);

export default Spinner;
