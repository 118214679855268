import { useNavigate } from "react-router-dom";

import "./OrderHistory.css";

export default function Orders({ className, isLoading, orders }) {
  const navigate = useNavigate();

  if (isLoading || orders === undefined) {
    // Add loading state or return null while the data is being fetched
    return <div>Loading...</div>;
  }

  function decodeHTMLEntities(text) {
    var txt = document.createElement("textarea");
    txt.innerHTML = text;
    return txt.value;
  }

  function handleViewOrderDetailsClick(orderId) {
    navigate(`/orderdetails/${orderId}`);
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  function getStatusDescription(statusCode) {
    switch (statusCode) {
      case 0:
        return "Open";
      case 10:
        return "issued";
      case 30:
        return "Processing";
      case 70:
        return "Shipped";
      case 80:
        return "Partially Shipped";
      case 90:
        return "Canceled";
      case 95:
        return "Cancellation request";
      default:
        return "Unknown Status";
    }
  }

  return (
    <div className="order-history">
      {orders.map((order, index) => (
        <div key={index} className="order">
          <div className="order-header">
            <div className="order-placed">
              Order Placed : {formatDate(order.createdDate)}
            </div>

            <div
              className="order-link"
              onClick={() => handleViewOrderDetailsClick(order.orderId)}
            >
              Order details
            </div>
          </div>
          <div className="order-item">
            <div className="order-item-details">
              <div className="item-title">
                {getStatusDescription(order.orderStatus)}
              </div>

              <div className="item-price">PO# : {order.customerPo}</div>
              <div className="item-price">Ship To : {order.shipToName}</div>
              <div className="item-price">
                Total Price : ${order.totalPrice}
              </div>
              {/*
              Shipping information
              <div className="item-price">Name : {order.shipToName}</div>
              <div className="item-price">Ship To : {order.shipToAddress}</div>
              <div className="item-price"> Contact : {order.phone} </div>  
              */}
            </div>
            <div className="order-item-username">
              <div className="username"> Ordered by : {order.loginId}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
