import {
  POST_ITEMVERIFICATION_FAIL,
  POST_ITEMVERIFICATION_REQUEST,
  POST_ITEMVERIFICATION_SUCCESS,
  POST_BULKORDER_REQUEST,
  POST_BULKORDER_FAIL,
  POST_BULKORDER_SUCCESS,
} from "../constants/bulkorderConstants";

// eslint-disable-next-line import/prefer-default-export
export const productValidationReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_ITEMVERIFICATION_REQUEST:
      return { loading: true };
    case POST_ITEMVERIFICATION_SUCCESS:
      return { loading: false };
    case POST_ITEMVERIFICATION_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const bulkCheckoutReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_BULKORDER_REQUEST:
      return { loading: true };
    case POST_BULKORDER_SUCCESS:
      return { loading: false };
    case POST_BULKORDER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
