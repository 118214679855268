import React from "react";
import InputCom from "../../../Helpers/InputCom";

export default function CreditCardInformation({
  paymentInfoTmp,
  setPaymentInfoTmp,
  warnings,
}) {
  const handleInputChange = (field, value) => {
    //  Enter only number
    if (field === "cardNumber" || field === "cvv") {
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) {
        return;
      }
    }

    setPaymentInfoTmp({
      ...paymentInfoTmp,
      [field]: value,
    });
  };

  return (
    <>
      <div
        className="flex space-x-3 items-center "
        style={{
          marginBottom: "20px",
          borderBottom: "1px solid lightgray",
        }}
      >
        {/* 
        <span className="text-2xl font-bold" style={{ color: "#001b5f" }}>
          Credit Card
        </span> 
    */}
      </div>
      <div
        className="input-item mb-4"
        style={{
          marginBottom: "20px",
          border: "1px solid lightgray",
          padding: "20px 20px 15px 20px",
        }}
      >
        <div className="input-item flex space-x-2.5 mb-4">
          <div className="w-1/2 h-full">
            <InputCom
              label="Card Holder's Name"
              name="cardHolderName"
              placeholder="Name"
              type="text"
              inputClasses="h-[30px]"
              inputHandler={(e) =>
                handleInputChange("cardHolderName", e.target.value)
              }
              value={paymentInfoTmp.cardHolderName}
              warnings={warnings}
            />
          </div>
          <div className="w-1/2 h-full">
            <InputCom
              label="Card Number"
              name="cardNumber"
              placeholder="Card Number"
              type="text"
              inputClasses="h-[30px]"
              inputHandler={(e) =>
                handleInputChange("cardNumber", e.target.value)
              }
              value={paymentInfoTmp.cardNumber}
              warnings={warnings}
            />
          </div>
        </div>
        {/* cardExpirationMonth */}
        <div className="input-item flex space-x-2.5 mb-4">
          <div className="w-1/3 h-full">
            <span className="text-ms text-qgray text-[13px] font-normal">
              Card Expiration Month
            </span>
            <div className="input-wrapper border border-qgray-border w-full h-[30px] relative overflow-hidden">
              <select
                className="absolute w-full h-full focus:ring-0 focus:outline-none placeholder:text-sm text-sm px-0 text-qgray"
                onChange={(e) =>
                  handleInputChange("cardExpirationMonth", e.target.value)
                }
                value={paymentInfoTmp.cardExpirationMonth}
              >
                <option value="" className="text-qgray" disabled>
                  Select Month
                </option>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month, index) => (
                  <option value={month} key={index}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
            {warnings && warnings["cardExpirationMonth"] && (
              <span className="text-red-500 text-xs">
                {warnings["cardExpirationMonth"]}
              </span>
            )}
          </div>

          {/* cardExpirationYear */}
          <div className="w-1/3 h-full">
            <span className="text-ms text-qgray text-[13px] font-normal">
              Card Expiration Year
            </span>
            <div className="input-wrapper border border-qgray-border w-full h-[30px] relative overflow-hidden">
              <select
                className="absolute w-full h-full focus:ring-0 focus:outline-none placeholder:text-sm text-sm px-0 text-qgray"
                onChange={(e) =>
                  handleInputChange("cardExpirationYear", e.target.value)
                }
                value={paymentInfoTmp.cardExpirationYear}
              >
                <option value="" className="text-qgray" disabled selected>
                  Select Year
                </option>
                {[...Array(16).keys()].map((_, index) => {
                  const year = new Date().getFullYear() + index;
                  return (
                    <option value={year} key={index}>
                      {year}
                    </option>
                  );
                })}
              </select>
            </div>
            {warnings && warnings["cardExpirationYear"] && (
              <span className="text-red-500 text-xs">
                {warnings["cardExpirationYear"]}
              </span>
            )}
          </div>

          {/* cvv */}
          <div className="w-1/3 h-full">
            <InputCom
              label="CVV"
              name="cvv"
              placeholder="cvv"
              type="text"
              inputClasses="h-[30px]"
              inputHandler={(e) => handleInputChange("cvv", e.target.value)}
              value={paymentInfoTmp.cvv}
              warnings={warnings}
            />
          </div>
        </div>
      </div>
    </>
  );
}
