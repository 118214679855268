import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getOrderCancellationRequest } from "../../../actions/orderAction";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import "../Orders/OrderHistory.css";
import PrimaryButton from "../../Helpers/Buttons/PrimaryButton";

function decodeHTMLEntities(text) {
  var txt = document.createElement("textarea");
  txt.innerHTML = text;
  return txt.value;
}

// Loading spinner style
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function OrderDetails({
  className,
  isLoading,
  orderId,
  loginId,
  poNumber,
  requestedShipDate,
  carrier,
  carrierService,
  note,
  signatureService,
  isDropShip,
  createdDate,
  orderStatus,
  email,
  totalPrice,
  discountedTotalPrice,
  shipToAddress,
  billToAddress,
  itemList,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // order cancellation request
  const orderCancellatonRequest = useSelector(
    (state) => state.getOrderCancellationRequest
  );
  const { loading, error, requestResult } = orderCancellatonRequest;

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  function getStatusDescription(statusCode) {
    switch (statusCode) {
      case 0:
        return "Open";
      case 10:
        return "issued";
      case 30:
        return "Processing";
      case 70:
        return "Shipped";
      case 80:
        return "Partially Shipped";
      case 90:
        return "Canceled";
      case 95:
        return "Cancellation request";
      default:
        return "Unknown Status";
    }
  }

  const handleCancelRequest = async (orderid) => {
    // call order cancellation request API
    try {
      await dispatch(getOrderCancellationRequest(orderid));
    } catch (error) {
      alert(
        "Something error has been happened. Please contact to our Customer Service Team to request cancellation."
      );
    }

    // display the result
    alert(
      "Note: Your cancellation request has been submitted. \nKindly be informed that this is a cancellation request and You will be notified If Maevn is unable to cancel."
    );

    // redirect to order list page
    navigate(`/myaccount#order`);
  };
  console.log("shipToAddress", shipToAddress);

  return (
    <div
      className={`w-full ${className || ""} `}
      style={{ fontFamily: "Arial, sans-serif" }}
    >
      <div className="flex-1  mt-8 mb-8">
        <h1 className="thank-you-message">
          <strong> Thank you for your purchase! </strong>
        </h1>
        <h1 className="confirmation-email">
          A confirmation email has been sent to
          <span className="confirmation-email-text"> {email}</span>.
        </h1>
      </div>
      <div>
        {/* Order details */}
        <div className="order-details-container">
          <h2 className="order-details-text">Order Details</h2>
        </div>
      </div>
      <div className="details-container">
        <div className="detail-item">
          <p className="detail-item-title-text">Ordered by</p>
          <p className="detail-item-detail-text">{loginId}</p>
        </div>
        <div className="detail-item">
          <p className="detail-item-title-text">PO number</p>
          <p className="detail-item-detail-text">{poNumber}</p>
        </div>
        <div className="detail-item">
          <p className="detail-item-title-text">Order placed</p>
          <p className="detail-item-detail-text">{formatDate(createdDate)}</p>
        </div>
        <div className="detail-item">
          <p className="detail-item-title-text">Order Status</p>
          <p className="detail-item-detail-text">
            {getStatusDescription(orderStatus)}
          </p>
        </div>
      </div>

      {/* delivery service */}
      <div className="delivery-service-container">
        <div className="delivery-service-block">
          <div className="Request-delivery-service-title">
            Request delivery service
          </div>
          <div className="delivery-service-detail">
            <p className="carrier-text">
              Carrier:{" "}
              <span className="carrier-service-text">
                {carrier ? carrier : " - "}
              </span>
            </p>
            <p className="carrier-text">
              Carrier Service:{" "}
              <span className="carrier-service-text">
                {carrierService ? carrierService : " - "}
              </span>
            </p>
          </div>
        </div>
        <div className="delivery-service-block">
          <div className="Request-delivery-service-title">
            Optional delivery service
          </div>
          <div className="delivery-service-detail">
            <p className="carrier-text">
              Requested Ship Date:{" "}
              <span className="carrier-service-text">
                {requestedShipDate ? requestedShipDate : " - "}
              </span>
            </p>
            <p className="carrier-text">
              Note:{" "}
              <span className="carrier-service-text">
                {note ? note : " - "}
              </span>
            </p>{" "}
            <p className="carrier-text">
              Signature Service:{" "}
              <span className="carrier-service-text">
                {signatureService === 0
                  ? " - "
                  : signatureService
                  ? "YES"
                  : " - "}
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* Addresses*/}
      <div className="flex space-x-10">
        {/* shipping */}
        <div>
          <div className="order-address-header">
            <div className="order-text-container">
              <p className="order-header-text ">
                Shipping address{" "}
                {isDropShip === 1 && (
                  <span className="order-detail-drop-ship">(DropShip)</span>
                )}{" "}
              </p>
            </div>
          </div>

          <div className="order-address-details">
            <div className="order-details-text">
              <p className="order-recipient-name">
                {" "}
                {shipToAddress?.companyName || "Not Provided"}
              </p>
              <br />
              <p className="order-recipient-address">
                {shipToAddress?.address1 || ""},{" "}
                {shipToAddress?.address2 ? shipToAddress?.address2 + "," : ""}{" "}
                <br /> <br />
                {shipToAddress?.stateName || ""}, {shipToAddress?.zipCode || ""}{" "}
                {shipToAddress?.countryName || ""}
              </p>
            </div>
          </div>
        </div>
        {/* billing */}
        <div>
          <div className="order-address-header">
            <div className="order-text-container">
              <p className="order-header-text "> Billing address </p>
            </div>
          </div>
          <div className="order-address-details">
            <div className="order-details-text">
              <p className="order-recipient-name">
                {" "}
                {billToAddress?.companyName || "Not Provided"}
              </p>
              <br />
              <p className="order-recipient-address">
                {billToAddress?.address1 || ""},{" "}
                {billToAddress?.address2 ? billToAddress?.address2 + "," : ""}{" "}
                <br /> <br />
                {billToAddress?.stateName || ""}, {billToAddress?.zipCode || ""}{" "}
                {billToAddress?.countryName || ""}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* items */}
      <div className="w-full px-10 py-[30px] border border-[#EDEDED] mt-10 mb-8">
        <div className="sub-total mb-6">
          <div className="flex justify-between mb-5">
            <p className="text-[14px] font-medium text-qblack uppercase">
              Image
            </p>
            <p className="text-[14px] font-medium text-qblack uppercase">
              Style no.
            </p>
            <p className="text-[14px] font-medium text-qblack uppercase">
              Color
            </p>
            <p className="text-[14px] font-medium text-qblack uppercase">
              Size
            </p>
            <p className="text-[14px] font-medium text-qblack uppercase">
              Unit Price
            </p>
            <p className="text-[14px] font-medium text-qblack uppercase">Qty</p>
            <p className="text-[14px] font-medium text-red-500 uppercase">
              <strong>Shipped </strong>
            </p>
            <p className="text-[14px] font-medium text-qblack uppercase">
              Sub Total
            </p>
          </div>
          <div className="w-full h-[1px] bg-[#EDEDED]"></div>
          {itemList &&
            itemList.map((item, index) => (
              <div
                key={index}
                className="flex items-center justify-between mb-5"
              >
                <a
                  href={`/${item.productLine.replace(/ /g, "-")}/${
                    item.styleNumber
                  }/${item.colorCode.toUpperCase()}/Reg`}
                >
                  <div className="flex items-center">
                    <img
                      src={`https://maevn-images.s3.us-east-2.amazonaws.com/110x110/${item.productLine.replace(
                        /\s+/g,
                        ""
                      )}/${item.styleNumber}${item.colorCode}.jpg`}
                      alt="Item"
                      className="w-[70px] h-[70px] mr-2"
                      onError={(event) => {
                        event.target.onerror = null; // prevent infinite error loop
                        event.target.src = `https://maevn-images.s3.us-east-2.amazonaws.com/swatches/NoImage.jpg`; // set fallback image
                      }}
                    />

                    <p className="text-[14px] font-medium text-qblack">
                      {item.styleNumber}
                    </p>
                  </div>
                </a>

                <p className="text-[14px] font-medium text-qblack">
                  {item.color}
                </p>
                <p className="text-[14px] font-medium text-qblack">
                  {item.size}
                </p>
                <p className="text-[14px] font-medium text-qblack">
                  ${item.itemPrice}
                </p>
                <p className="text-[14px] font-medium text-qblack">
                  {item.qty}
                </p>
                <p className="text-[14px] font-medium text-red-500">
                  <strong>{item.shippedQty} </strong>
                </p>
                <p className="text-[14px] font-medium text-qblack">
                  ${item.subTotal}
                </p>
              </div>
            ))}
          <div className="w-full h-[1px] bg-[#EDEDED]"></div>
        </div>
        <div className="mt-[30px]">
          <div className="flex justify-between mb-5">
            <p className="text-2xl font-medium text-qblack">Total</p>
            <p className="text-2xl font-medium text-qred">
              $
              {discountedTotalPrice < totalPrice
                ? discountedTotalPrice
                : totalPrice}
            </p>
          </div>
        </div>
      </div>

      {/* Shipping fee notification */}
      <div>
        <p
          className="text-[15px] text-yellow-500 "
          style={{
            textAlign: "left",
            marginTop: "25px",
            marginBottom: "50px",
            color: "#FF4444",
          }}
        >
          ** Note : You can find the detailed shipping costs and tax prices on
          your invoice. <br /> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;The actual invoice will also show the discount
          you received with your promo code.
        </p>
      </div>

      {/* order cancellatin request button */}
      <div className="flex justify-end ">
        {orderStatus === 0 || orderStatus === 10 ? (
          loading ? (
            <div>
              <ClipLoader color="#0066CC" loading={loading} size={50} />
              <span color="#0066CC">Loading...</span>
            </div>
          ) : (
            <PrimaryButton
              style={{ marginBottom: "70px" }}
              onClick={() => handleCancelRequest(orderId)}
            >
              Cancel Request
            </PrimaryButton>
          )
        ) : (
          <div
            style={{
              color: "#FFA500",
              fontStyle: "italic",
              marginBottom: "70px",
            }}
          >
            <h1>Note : Your package is currently being processed.</h1>
            <h1>
              If you need to request an urgent cancellation, Please contact your
              CSR to cancel your purchase order.
            </h1>
          </div>
        )}
      </div>
    </div>
  );
}
