import {
  SET_CREDIT_BALANCE,
  SET_SELECTEDINVOICE_REQUEST,
  SET_SELECTEDINVOICE_SUCCESS,
  SET_SELECTEDINVOICE_FAIL,
  POST_PAYMENT_REQUEST,
  POST_PAYMENT_SUCCESS,
  POST_PAYMENT_FAIL,
  POST_MAEVNPAYMENT_REQUEST,
  POST_MAEVNPAYMENT_SUCCESS,
  POST_MAEVNPAYMENT_FAIL,
  GET_PAYMENTCONFIRM_REQUEST,
  GET_PAYMENTCONFIRM_SUCCESS,
  GET_PAYMENTCONFIRM_FAIL,
} from "../constants/paymentConstants";

const initialState = {
  selectedInvoices: [],
  creditBalance: 0,
};

export const setCreditBalanceToReduxReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_CREDIT_BALANCE:
      return {
        ...state,
        creditBalance: action.payload,
      };
    default:
      return state;
  }
};

export const setSelectedInvoicesToReduxReducer = (
  state = { selectedInvoicesFromRedux: [] },
  action
) => {
  switch (action.type) {
    case SET_SELECTEDINVOICE_REQUEST:
      return { invoicesLoading: true };
    case SET_SELECTEDINVOICE_SUCCESS:
      return {
        invoicesLoading: false,
        selectedInvoices: action.payload,
      };
    case SET_SELECTEDINVOICE_FAIL:
      return { invoicesLoading: false, invoicesError: action.payload };
    default:
      return state;
  }
};

export const postCreditCardPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_PAYMENT_REQUEST:
      return { processing: true, errorMessage: null };
    case POST_PAYMENT_SUCCESS:
      return {
        processing: false,
        result: action.payload,
      };
    case POST_PAYMENT_FAIL:
      return { processing: false, errorMessage: action.payload };
    default:
      return state;
  }
};

export const postMaevnPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_MAEVNPAYMENT_REQUEST:
      return { processing: true, errorMessage: null };
    case POST_MAEVNPAYMENT_SUCCESS:
      return {
        processing: false,
        result: action.payload,
      };
    case POST_MAEVNPAYMENT_FAIL:
      return { processing: false, errorMessage: action.payload };
    default:
      return state;
  }
};

export const postPaymentConfirmReduxReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_PAYMENTCONFIRM_REQUEST:
      return { loading: true };
    case GET_PAYMENTCONFIRM_SUCCESS:
      return {
        loading: false,
        paymentResult: action.payload,
      };
    case GET_PAYMENTCONFIRM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
