import {
  PUT_PASSWORDCHANGE_FAIL,
  PUT_PASSWORDCHANGE_REQUEST,
  PUT_PASSWORDCHANGE_SUCCESS,
  GET_MYACCOUNT_FAIL,
  GET_MYACCOUNT_REQUEST,
  GET_MYACCOUNT_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNOUT,
  TOKEN_REQUEST,
  TOKEN_FAIL,
} from "../constants/userAuthConstants";

// eslint-disable-next-line import/prefer-default-export
export const changePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case PUT_PASSWORDCHANGE_REQUEST:
      return { loading: true };
    case PUT_PASSWORDCHANGE_SUCCESS:
      return { loading: false, result: action.payload };
    case PUT_PASSWORDCHANGE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getMyAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MYACCOUNT_REQUEST:
      return { loading: true };
    case GET_MYACCOUNT_SUCCESS:
      return { loading: false, myAccount: action.payload };
    case GET_MYACCOUNT_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userSigninReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
      return { loading: true };
    case USER_SIGNIN_SUCCESS:
      return { loading: false, userInfo: action.payload }; // <= coming from userAction
    case USER_SIGNIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_SIGNOUT:
      return {};
    case TOKEN_REQUEST:
      return {};
    case TOKEN_FAIL:
      return { tokenerror: action.payload };

    default:
      return state;
  }
};
