import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { editSubuser } from "../../../actions/subuserAction";
import SecondaryButton from "../../Helpers/Buttons/SecondaryButton";
import PrimaryButton from "../../Helpers/Buttons/PrimaryButton";

export default function UserEditForm({ className, subuser }) {
  const [subuserId, setSubuserId] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [roleId, setRoleId] = useState();
  const [roleList, setRoleList] = useState([]);
  const currentRoleName =
    roleList.find((role) => role.id === roleId)?.roleName || "";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = (event, stateSetter) => {
    stateSetter(event.target.value);
  };

  const handleRoleChange = (event) => {
    const selectedRole = roleList.find(
      (role) => role.roleName === event.target.value
    );
    setRoleId(selectedRole?.id);
  };

  const handleConfirmClick = async () => {
    console.log(subuserId);
    console.log(username);
    console.log(password);
    console.log(email);
    console.log(phone);
    console.log(roleId);

    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if email is in valid format
    if (!emailRegex.test(email)) {
      return alert("Please enter a valid email address");
    }

    const subuserToEdit = {
      subuserId,
      username,
      password,
      email,
      phone,
      roleId,
    };

    // call Edit subuser API
    try {
      const response = await dispatch(editSubuser(subuserToEdit));

      if (response.error) {
        throw new Error(response.error);
      }
      // show information change complete alert
      alert("Information has been successfully updated.");

      navigate(`/customerusers`);
    } catch (error) {
      // Handle the error
      alert(error.message);
    }
  };

  const handleCancelClick = () => {
    navigate("/customerusers");
  };

  useEffect(() => {
    if (subuser?.result) {
      setSubuserId(subuser.result.subuserId);
      setUsername(subuser.result.username);
      setPassword(subuser.result.password);
      setEmail(subuser.result.email);
      setPhone(subuser.result.phone);
      setRoleId(subuser.result.roleId);
      setRoleList(subuser.result.roleList);
    }
  }, [subuser]);

  return (
    <div className={`w-full ${className || ""} mt-12 max-w-xl mx-auto`}>
      <div className="w-full max-w-md max-w-xl mx-auto">
        <form className="formStyles">
          {/* Form Title */}
          <h2 className="formTitle">Edit User</h2>
          <div>
            <label className="subuser-input-label" htmlFor="username" disabled>
              Username
            </label>
            <div className="flex">
              <input
                id="usernameForSubuser"
                type="usernameForSubuser"
                className="py-2 px-3 inactive_input-box"
                value={username}
                disabled
              />
            </div>
          </div>

          <div>
            <label className="subuser-input-label" htmlFor="password" disabled>
              Password
            </label>
            <div className="flex">
              <input
                id="password"
                type="password"
                className="py-2 px-3 inactive_input-box"
                value={password}
                disabled
              />
            </div>
          </div>
          <div>
            <label className="subuser-input-label" htmlFor="email">
              Email
            </label>
            <div className="flex">
              <input
                className="edit_subuser-input-box"
                id="email"
                type="email"
                value={email}
                onChange={(event) => handleInputChange(event, setEmail)}
              />
            </div>
          </div>
          <div>
            <label className="subuser-input-label" htmlFor="phone">
              Phone
            </label>
            <div className="flex">
              <input
                className="edit_subuser-input-box"
                id="phone"
                type="tel"
                value={phone}
                onChange={(event) => handleInputChange(event, setPhone)}
              />
            </div>
          </div>
          <div>
            <label className="subuser-input-label">Roles </label>
            <div className="flex">
              <select
                className="role-select-custom-select"
                value={currentRoleName} // set the value of the select to the current role name
                onChange={handleRoleChange}
              >
                <option value="">Select Role</option>
                {roleList.map((role) => (
                  <option key={role.id} value={role.roleName}>
                    {role.roleName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex justify-center">
            <SecondaryButton
              width="185px"
              height="40px"
              fontSize="18px"
              onClick={() => handleCancelClick()}
              style={{ marginBottom: "10px" }}
            >
              Cancel
            </SecondaryButton>
            <div style={{ width: "15px" }} />
            <PrimaryButton
              width="185px"
              height="40px"
              fontSize="18px"
              onClick={(event) => {
                event.preventDefault();
                handleConfirmClick();
              }}
              style={{ marginBottom: "10px" }}
            >
              Update
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
}
