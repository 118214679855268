import { useState } from "react";
import { useLocation } from "react-router-dom";
import DiscountBanner from "../Home/DiscountBanner";
import Drawer from "../Mobile/Drawer";
import Footer from "./Footers/Footer";
import Header from "./Headers/HeaderOne";

export default function Layout({ children, childrenClasses }) {
  const [drawer, setDrawer] = useState(false);
  const location = useLocation(); // Use the hook to get the current location

  return (
    <>
      <Drawer open={drawer} action={() => setDrawer(!drawer)} />
      {/* <div className="w-full overflow-x-hidden"> */}
      <div className="w-full ">
        {/* Render the Header only if the current page is /Login */}
        {/* <Header drawerAction={() => setDrawer(!drawer)} /> */}
        {location.pathname === "/Login" || location.pathname === "/login" || (
          <Header drawerAction={() => setDrawer(!drawer)} />
        )}

        <div className={`w-full  ${childrenClasses || "pt-[10px] pb-[60px]"}`}>
          {children && children}
        </div>
        {/* <DiscountBanner /> */}
        {/* Render the Footer only if the current page is /Login */}
        {/* <Footer />*/}
        {location.pathname === "/Login" || location.pathname === "/login" || (
          <Footer />
        )}
      </div>
    </>
  );
}
