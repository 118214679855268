import { useState } from "react";

export default function InputCom({
  label,
  type,
  name,
  placeholder,
  children,
  inputHandler,
  value,
  inputClasses,
  className,
  labelClasses = "text-qgray text-[13px] font-normal",
  isReadOnly,
  warnings,
}) {
  return (
    <div className={`input-com w-full h-full`}>
      {label && (
        <label
          className={`input-label capitalize block ${labelClasses || ""}`}
          htmlFor={name}
          style={{ marginBottom: labelClasses.includes("mb-") ? "" : 0 }}
        >
          {label}
        </label>
      )}
      <div className="input-wrapper border border-qgray-border w-full h-full overflow-hidden relative ">
        <input
          placeholder={placeholder}
          value={value}
          onChange={inputHandler}
          className={`input-field placeholder:text-sm text-sm px-0 text-dark-gray w-full h-full font-normal bg-white focus:ring-0 focus:outline-none ${
            inputClasses || ""
          } ${className || ""}`}
          type={type}
          id={name}
          style={{ paddingLeft: "10px" }}
          readOnly={isReadOnly}
        />
        {children && children}
      </div>{" "}
      {warnings && name && warnings[name] && (
        <span className="text-red-500 text-xs "> {warnings[name]}</span>
      )}
    </div>
  );
}
