export const ADD_BASKET_REQUEST = "ADD_BASKET_REQUEST";
export const ADD_BASKET_SUCCESS = "ADD_BASKET_SUCCESS";
export const ADD_BASKET_FAIL = "ADD_BASKET_FAIL";

export const GET_BASKET_REQUEST = "GET_BASKET_REQUEST";
export const GET_BASKET_SUCCESS = "GET_BASKET_SUCCESS";
export const GET_BASKET_FAIL = "GET_BASKET_FAIL";

export const UPDATE_BASKET_REQUEST = "UPDATE_BASKET_REQUEST";
export const UPDATE_BASKET_SUCCESS = "UPDATE_BASKET_SUCCESS";
export const UPDATE_BASKET_FAIL = "UPDATE_BASKET_FAIL";

export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAIL = "DELETE_ITEM_FAIL";

export const CLEAR_BASKET_REQUEST = "CLEAR_BASKET_REQUEST";
export const CLEAR_BASKET_SUCCESS = "CLEAR_BASKET_SUCCESS";
export const CLEAR_BASKET_FAIL = "CLEAR_BASKET_FAIL";

export const UPDATE_QUICKORDER_REQUEST = "UPDATE_QUICKORDER_REQUEST";
export const UPDATE_QUICKORDER_SUCCESS = "UPDATE_QUICKORDER_SUCCESS";
export const UPDATE_QUICKORDER_FAIL = "UPDATE_QUICKORDER_FAIL";
