import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import PageTitle from "../../Helpers/PageTitle";
import Layout from "../../Partials/Layout";
import ProductsTable from "../Checkout/ProductsTableOrigin";
import OrderInfo from "../OrderConfirm/OrderInfo";
import { getOrderDetails } from "../../../actions/orderAction";
import OrderDetails from "./OrderDetails";

export default function Index({ cart = true }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { OrderId } = useParams();

  // validate user
  const { search } = useLocation(); // search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login";

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  // order details
  const orderDetailsInformation = useSelector((state) => state.getOrderDetails);
  const { loading, error, orderDetails } = orderDetailsInformation;

  const poNumber = orderDetails?.data.result.customerPo;
  const createdDate = orderDetails?.data.result.createdDate;

  const requestedShipDate = orderDetails?.data.result.requestedShipDate;
  const note = orderDetails?.data.result.note;
  const signatureService = orderDetails?.data.result.signatureService;
  const isDropShip = orderDetails?.data.result.isDropShip;
  const carrier = orderDetails?.data.result.carrier;
  const carrierService = orderDetails?.data.result.carrierService;

  const orderStatus = orderDetails?.data.result.orderStatus;
  const orderId = orderDetails?.data.result.orderId;
  const loginId = orderDetails?.data.result.loginId;
  const email = orderDetails?.data.result.email;
  const totalPrice = orderDetails?.data.result.totalPrice;
  const discountedTotalPrice = orderDetails?.data.result.discountedTotalPrice;
  const shipToAddress = orderDetails?.data.result.shipToAddress;
  const billToAddress = orderDetails?.data.result.billToAddress;
  const itemList = orderDetails?.data.result.itemList;

  console.log("poNumber : ", poNumber);

  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");
      navigate(redirect);
    } else {
      console.log("You are already logged in ");
      dispatch(getOrderDetails(OrderId));
    }
  }, [dispatch, navigate, redirect, userInfo]);

  // locate scrollbar at the top of page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout childrenClasses={`${cart ? "pt-0 pb-0" : ""} relative`}>
      <div className="cart-page-wrapper w-full pb-60px white-background">
        <div className="w-full mb-[90px]">
          {/* Page history */}
          <PageTitle
            title="Order Details"
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Order History", path: "/myaccount#order" },
              { name: "Order Details", path: `/orderdetails/${OrderId}` },
            ]}
          />
        </div>
        {/* product list */}
        <div className="w-full mt-[23px]">
          <div className="container-x mx-auto">
            {/* <ProductsTable cartItems={items?.data.result} /> */}
            <OrderDetails
              isLoading={loading}
              orderId={orderId}
              loginId={loginId}
              poNumber={poNumber}
              requestedShipDate={requestedShipDate}
              note={note}
              signatureService={signatureService}
              isDropShip={isDropShip}
              carrier={carrier}
              carrierService={carrierService}
              createdDate={createdDate}
              orderStatus={orderStatus}
              email={email}
              totalPrice={totalPrice}
              discountedTotalPrice={discountedTotalPrice}
              shipToAddress={shipToAddress}
              billToAddress={billToAddress}
              itemList={itemList}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
