import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../Helpers/PageTitle";
import Layout from "../../Partials/Layout";
import BulkOrderInfo from "./BulkOrderInfo";

export default function Index({ cart = true }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { CustomerPO } = useParams();
  const location = useLocation(); // Use useLocation to get the passed state

  // Retrieve orderInfo and orderDetail from location.state
  const { orderInfo, orderDetail } = location.state || {};

  console.log("Order Info in /Importorderfile/Confirmation :", orderInfo);
  console.log(
    "Order Detail (Order Line) in /Importorderfile/Confirmation  :",
    orderDetail
  );

  // validate user
  const { search } = useLocation(); // search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login";

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  // order information
  /*  
  const orderConfirmation = useSelector((state) => state.getOrderConfirmation);
  const { loading, error, orderInfo } = orderConfirmation;
  const poNumber = orderInfo?.CustomerPO;
  const dropship = orderInfo?.IsDropShip;
  const carrier = orderInfo?.CarrierName;
  const carrierService = orderInfo?.ServiceName;
  const requestedShipDate = orderInfo?.RequestedShipDate;
  const signatureService = orderInfo?.SignatureService;
  const note = orderInfo?.Note;
  const email = orderInfo?.contactEmail;
   const totalPrice = orderInfo?.totalPrice;
   const discountedTotalPrice = orderInfo?.discountedTotalPrice;
  const shipToAddress = orderInfo?.ShipToAddress1;
  const stateName = orderInfo?.StateName;
  const countryName = orderInfo?.CountryName;
  const shipToZipcode = orderInfo?.ShipToZipcode;
  const billToAddress = orderInfo?.ErpBillToAddressId;
  const itemList = orderInfo?.orderLine;
  const accountNumber = orderInfo?.accountNumber;
*/

  useEffect(() => {
    if (!userInfo) {
      alert("Please sign in to access our website. ");
      navigate(redirect);
    } else {
      console.log("You are already logged in ");
    }
  }, [dispatch, navigate, redirect, userInfo]);

  return (
    <Layout childrenClasses={`${cart ? "pt-0 pb-0" : ""} relative`}>
      <div className="cart-page-wrapper w-full pb-60px white-background">
        <div className="w-full mb-[100px]">
          {/* Page history */}
          <PageTitle
            title="Import Order Confirmation"
            breadcrumb={[{ name: "home", path: "/" }]}
          />
        </div>
        {/* product list */}
        <div className="w-full mt-[23px]">
          <div className="container-x mx-auto">
            {/* <ProductsTable cartItems={items?.data.result} /> */}
            <BulkOrderInfo
              className
              orderInfo={orderInfo}
              orderLine={orderDetail}
              // isLoading={loading}
              //loginId={accountNumber}
              //poNumber={poNumber}
              // orderStatus={orderStatus}
              // createdDate={createdDate}
              // orderId={orderId}
              //dropship={dropship}
              //carrier={carrier}
              //carrierService={carrierService}
              //requestedShipDate={requestedShipDate}
              //signatureService={signatureService}
              //note={note}
              //email={email}
              // totalPrice={totalPrice}
              // discountedTotalPrice={discountedTotalPrice}
              //shipToZipcode={shipToZipcode}
              //countryName={countryName}
              //stateName={stateName}
              //shipToAddress={shipToAddress}
              //billToAddress={billToAddress}
              //itemList={itemList}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
