import {
  GET_COLLECTIONS_REQUEST,
  GET_COLLECTIONS_SUCCESS,
  GET_COLLECTIONS_FAIL,
  GET_STYLES_REQUEST,
  GET_STYLES_SUCCESS,
  GET_STYLES_FAIL,
  GET_QUICKORDER_REQUEST,
  GET_QUICKORDER_SUCCESS,
  GET_QUICKORDER_FAIL,
  GET_QUICKORDER_RESET,
  GET_FILTEREDINVENTORY_REQUEST,
  GET_FILTEREDINVENTORY_SUCCESS,
  GET_FILTEREDINVENTORY_FAIL,
} from "../constants/quickorderConstants";

// eslint-disable-next-line import/prefer-default-export
export const getCollectionsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_COLLECTIONS_REQUEST:
      return { loading: true };
    case GET_COLLECTIONS_SUCCESS:
      return { loading: false, collections: action.payload };
    case GET_COLLECTIONS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getStylesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STYLES_REQUEST:
      return { loading: true };
    case GET_STYLES_SUCCESS:
      return { loading: false, styles: action.payload };
    case GET_STYLES_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getInitialInventoryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_QUICKORDER_REQUEST:
      return { loading: true };
    case GET_QUICKORDER_SUCCESS:
      return { loading: false, inventory: action.payload };
    case GET_QUICKORDER_FAIL:
      return { loading: false, error: action.payload };
    case GET_QUICKORDER_RESET:
      return {}; // 상태를 초기화합니다.
    default:
      return state;
  }
};

export const getFilteredInventoryReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_FILTEREDINVENTORY_REQUEST:
      return { loading: true };
    case GET_FILTEREDINVENTORY_SUCCESS:
      return { loading: false, filteredInventory: action.payload };
    case GET_FILTEREDINVENTORY_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
