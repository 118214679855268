import PageTitle from "../Helpers/PageTitle";
import Layout from "../Partials/Layout";

export default function ReturnPolicy() {
  return (
    <Layout childrenClasses="pt-0 pb-0">
      <div className="terms-condition-page w-full bg-white pb-[30px]">
        <div className="w-full mb-[30px]">
          <PageTitle
            breadcrumb={[
              { name: "home", path: "/" },
              { name: "Return Policy", path: "return-policy" },
            ]}
            title="Return Policy"
          />
        </div>
        <div className="w-full">
          <div className="container-x mx-auto">
            <div className="content-item w-full mb-15">
              <p className="text-[15px] text-qgraytwo leading-7">
                If for any reason you are not completely satisfied with your
                purchase, simply return the item to us within 30 days of receipt
                in its original condition. If the item has been worn or washed
                or has been customized in any of the following ways: with
                emblems, altered; silkscreen or embroidery please call us to
                advise us of the return and the reason for the return. Depending
                on the nature of the return a small service charge may be
                applied to your return.
                <br />
                <br />
                <br />
              </p>
              <p className="text-[15px] text-qgraytwo leading-7">
                If the packing slip is no longer available, call us to discuss
                your return so that we can be ready to receive the items
                properly. Please return the item(s) via a traceable or insured
                method to prevent the package from being lost in the mail. We
                are not responsible for returned items that are lost in transit.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
