import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "../Home/Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = () => {
  const aboutUsCarouselImages = [
    {
      key: 1,
      url: "https://maevn-images.s3.us-east-2.amazonaws.com/aboutUs/AboutUs_carourel_image_1.png",
    },
    {
      key: 2,
      url: "https://maevn-images.s3.us-east-2.amazonaws.com/aboutUs/AboutUs_carourel_image_2.png",
    },
    {
      key: 3,
      url: "https://maevn-images.s3.us-east-2.amazonaws.com/aboutUs/AboutUs_carourel_image_3.png",
    },
    {
      key: 4,
      url: "https://maevn-images.s3.us-east-2.amazonaws.com/aboutUs/AboutUs_carourel_image_4.png",
    },
    {
      key: 5,
      url: "https://maevn-images.s3.us-east-2.amazonaws.com/aboutUs/AboutUs_carourel_image_5.png",
    },
  ];

  const navigate = useNavigate();

  const sliderRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        type="button"
        //className="slick-prev slick-arrow"
        className="aboutus-slick-prev-arrow aboutus-slick-arrow"
        onClick={onClick}
      >
        <div className="aboutus-button-content">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="28" fill="rgba(255, 255, 255, 0.6)" />
            <foreignObject x="20" y="20" width="32" height="32">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 12 14"
                fill="none"
              >
                <path
                  d="M0.283406 6.56386L10.4666 0.166377C10.6365 0.0595777 10.8623 -4.74805e-07 11.097 -4.85066e-07C11.3318 -4.95327e-07 11.5575 0.0595776 11.7274 0.166377L11.7384 0.173606C11.821 0.22538 11.8869 0.2877 11.9318 0.356774C11.9768 0.425849 12 0.500235 12 0.575406C12 0.650577 11.9768 0.724963 11.9318 0.794038C11.8869 0.863113 11.821 0.925433 11.7384 0.977207L2.14902 7.00121L11.7384 13.0228C11.821 13.0746 11.8869 13.1369 11.9318 13.206C11.9768 13.275 12 13.3494 12 13.4246C12 13.4998 11.9768 13.5741 11.9318 13.6432C11.8869 13.7123 11.821 13.7746 11.7384 13.8264L11.7274 13.8336C11.5575 13.9404 11.3318 14 11.097 14C10.8623 14 10.6365 13.9404 10.4666 13.8336L0.283406 7.43614C0.193849 7.37988 0.122551 7.31221 0.0738357 7.23724C0.0251201 7.16227 3.09545e-07 7.08156 3.0598e-07 7C3.02415e-07 6.91844 0.0251201 6.83773 0.0738357 6.76276C0.122551 6.68779 0.193849 6.62012 0.283406 6.56386Z"
                  fill="#5F5F5F"
                />
              </svg>
            </foreignObject>
          </svg>
        </div>
      </button>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        type="button"
        // className="slick-next slick-arrow"
        className="aboutus-slick-next-arrow aboutus-slick-arrow"
        onClick={onClick}
      >
        <div className="aboutus-button-content">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="28" fill="rgba(255, 255, 255, 0.6)" />
            <foreignObject x="20" y="20" width="32" height="32">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 12 14"
                fill="none"
              >
                <path
                  d="M11.7166 6.56386L1.53338 0.166377C1.36346 0.0595777 1.13772 -4.74805e-07 0.902978 -4.85066e-07C0.668231 -4.95327e-07 0.442492 0.0595776 0.27258 0.166377L0.261616 0.173606C0.178961 0.22538 0.113144 0.2877 0.0681686 0.356774C0.0231933 0.425849 9.31808e-07 0.500235 9.28522e-07 0.575406C9.25237e-07 0.650577 0.0231933 0.724963 0.0681686 0.794038C0.113144 0.863113 0.178961 0.925433 0.261616 0.977207L9.85098 7.00121L0.261615 13.0228C0.17896 13.0746 0.113143 13.1369 0.0681681 13.206C0.0231928 13.275 3.70153e-07 13.3494 3.66867e-07 13.4246C3.63581e-07 13.4998 0.0231928 13.5741 0.068168 13.6432C0.113143 13.7123 0.17896 13.7746 0.261615 13.8264L0.27258 13.8336C0.442492 13.9404 0.66823 14 0.902977 14C1.13772 14 1.36346 13.9404 1.53338 13.8336L11.7166 7.43614C11.8062 7.37988 11.8774 7.31221 11.9262 7.23724C11.9749 7.16227 12 7.08156 12 7C12 6.91844 11.9749 6.83773 11.9262 6.76276C11.8774 6.68779 11.8062 6.62012 11.7166 6.56386Z"
                  fill="#525252"
                />
              </svg>
            </foreignObject>
          </svg>
        </div>
      </button>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Initial autoplay state
    autoplaySpeed: 2500,
    nextArrow: <CustomNextArrow />, // Customized arrow buttons
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      sliderRef.current.slickPause(); // Pause autoplay
    } else {
      sliderRef.current.slickPlay(); // Start autoplay
    }
    setIsPlaying(!isPlaying);
  };

  const navigateToUrl = (url) => {
    navigate(url); // Use navigate function to change the URL
  };

  return (
    <div>
      {/* Slider */}
      <div className="aboutus-carousel-container">
        <Slider ref={sliderRef} {...settings}>
          {aboutUsCarouselImages.map((image, index) => (
            <div
              key={index}
              className="aboutus-slider-image-container"
              onClick={() => navigateToUrl(image.navigateTo)}
            >
              <img
                src={image.url}
                alt={`Slide ${index}`}
                style={{
                  width: "360px",
                  height: "380px",
                  display: "block",
                  margin: "0  ",
                  cursor: "pointer",
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
