import React, { useState } from "react";

const ZoomThumbnail = ({ img, src, changeImgHandler, NoImage }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true); // set hasError state to true
  };

  return (
    !hasError && (
      <div
        onClick={() => changeImgHandler(img.basicURL, img.zoominURL)}
        key={img.id}
        className="w-[110px] h-[110px] p-[0px] border border-qgray-border cursor-pointer"
      >
        {img.thumbnailURL ? (
          <img
            src={`${img.thumbnailURL}`}
            alt=""
            className={`w-full h-full object-contain ${
              src === img.basicURL ? "opacity-100" : "opacity-50"
            } `}
            onError={handleError}
          />
        ) : (
          <img
            src={`${NoImage}`}
            alt=""
            className={`w-full h-full object-contain ${
              src !== NoImage ? "opacity-50" : ""
            } `}
          />
        )}
      </div>
    )
  );
};

export default ZoomThumbnail;
