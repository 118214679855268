import {
  GET_ADDRESSLIST_REQUEST,
  GET_ADDRESSLIST_SUCCESS,
  GET_ADDRESSLIST_FAIL,
} from "../constants/userConstants";

// eslint-disable-next-line import/prefer-default-export
export const getAddresssListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ADDRESSLIST_REQUEST:
      return { loading: true };
    case GET_ADDRESSLIST_SUCCESS:
      return { loading: false, address: action.payload };
    case GET_ADDRESSLIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
