import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../OrderReview.css";
import PrimaryButton from "../../../Helpers/Buttons/PrimaryButton";
import SecondaryButton from "../../../Helpers/Buttons/SecondaryButton";

const DeliverySection = ({
  setSectionRef,
  setDeliveryInfo,
  deliveryInfo,
  toggleShowDeliveryOptions,
  showDeliveryOptions,
  setShowDeliveryOptions,
  dropShip,
  setTempDeliveryInfo,
  tempDeliveryInfo,
  carrierServices = [],
  services = [],
  selectedCarrierServices,
  setSelectedCarrierServices,
  handleCarrierSelectionChange,
  handleDefaultServiceChange,
  handleCarrierChange,
  handleServiceChange,
  handleDateChange,
  handleMemoChange,
  handleSignatureServiceChange,
  handleConfirmDeliveryOptions,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  // Parse date form
  const parseDateString = (dateString) => {
    const [year, month, day] = dateString
      .split("-")
      .map((val) => parseInt(val, 10));
    return new Date(year, month - 1, day);
  };

  // hided datepicker issue
  const renderDatePickerPopper = (props) => {
    return ReactDOM.createPortal(
      <div {...props} />,
      document.body // Render the popper at the end of the body
    );
  };

  // Close the delvery service list
  const handleCancelButton = () => {
    setShowDeliveryOptions(false);
  };

  // Change carriderService as the selected carrier company
  useEffect(() => {
    if (tempDeliveryInfo.carrierName) {
      const selectedCarrier = carrierServices.find(
        (carrier) => carrier.name === tempDeliveryInfo.carrierName
      );
      if (selectedCarrier) {
        setSelectedCarrierServices(selectedCarrier.services);
      }
    }
  }, [tempDeliveryInfo.carrierName, carrierServices]);

  return (
    <div
      ref={setSectionRef(1)}
      style={{
        minHeight: showDeliveryOptions ? "auto" : 0,
        marginBottom: "30px",
      }}
    >
      {/* Delivery Service section */}
      <h2 className="section-title">2. Delivery Service</h2>
      <div className="section-content">
        {/* Default Delivery container */}
        <div className="default-delivery">
          <div>
            <div className="delivery-row">
              <span className="delivery-label">Carrier:&nbsp;</span>
              {/* Change Carrier Company */}
              {/*  {dropShip === 1 ? ( */}
              <select
                id="carrier"
                className="custom-select"
                value={tempDeliveryInfo.carrierName}
                onChange={handleCarrierSelectionChange}
                required
              >
                {carrierServices.map((carrier) => (
                  <option key={carrier.erpCarrierId} value={carrier.name}>
                    {carrier.name}
                  </option>
                ))}
              </select>
              {/* ) : (
                <span
                  style={{
                    color: "var(--Secondary-txt-Grey, #7C7C7C)",
                    fontFamily: "Lato",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  {deliveryInfo.carrierName || "No Carrier Selected"}
                </span>
              )} */}
            </div>
            <div className="delivery-row">
              <span className="delivery-label">Service:&nbsp;</span>
              {/* Change CarrierService */}
              {/* {dropShip === 1 ? ( */}
              <select
                id="service"
                className="custom-select"
                value={tempDeliveryInfo.serviceName}
                //onChange={handleServiceChange}
                onChange={handleDefaultServiceChange}
                required
              >
                {selectedCarrierServices.map((service) => (
                  <option
                    key={service.erpServiceId}
                    value={service.serviceName}
                  >
                    {service.serviceName}
                  </option>
                ))}
              </select>
              {/*   ) : (
                <span
                  style={{
                    color: "var(--Secondary-txt-Grey, #7C7C7C)",
                    fontFamily: "Lato",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  {deliveryInfo.serviceName || "No Service Selected"}
                </span>
              )} */}
            </div>
            {/* Note */}
            <div className="delivery-row">
              <span className="delivery-label">Note:&nbsp;</span>
              <input
                id="memo"
                type="text"
                className="delivery-memo-input"
                value={tempDeliveryInfo.memo}
                onChange={handleMemoChange}
                placeholder={"Comments or UPS/FEDEX ACCT#"}
                required
              />
            </div>

            {/* Selected optional delivery service */}
            <span className="delivery-info">
              {deliveryInfo.requestedDate ? (
                <>
                  <span className="delivery-label">Ship Date: </span>
                  {/* {deliveryInfo.requestedDate
                    ? new Date(deliveryInfo.requestedDate).toDateString()
                 : " "} */}
                  {parseDateString(deliveryInfo.requestedDate).toDateString()}{" "}
                  <br />
                </>
              ) : (
                " "
              )}
            </span>

            <span className="delivery-info">
              {deliveryInfo.signatureService ? (
                <>
                  <span className="delivery-label">
                    Signature Service:&nbsp;
                  </span>
                  Yes <br />
                </>
              ) : (
                " "
              )}
            </span>

            <span className="delivery-info">
              {deliveryInfo.memo ? (
                <>
                  <span className="delivery-label">Note:&nbsp;</span>
                  {deliveryInfo.memo || " "} <br /> <br />
                </>
              ) : (
                " "
              )}
            </span>
          </div>
          {/* Optional services */}
          <div className="button-container">
            <button
              type="button"
              className="btn toggle-delivery-options"
              onClick={toggleShowDeliveryOptions}
            >
              Optional Services
            </button>
          </div>
        </div>
      </div>
      {/* Additional Delivery Service section */}
      <div
        className={`section-content delivery-container ${
          showDeliveryOptions
            ? "show-delivery-container"
            : "hide-delivery-container"
        }`}
      >
        <div className="no-border-delivery-card">
          {/* Requested Ship Date */}
          <div className="additional-delivery-option">
            <div className="delivery-row">
              <span className="delivery-label">Ship date:&nbsp;</span>

              <div>
                <DatePicker
                  id="Shipping date"
                  className="datepicker-input "
                  selected={
                    tempDeliveryInfo.requestedDate
                      ? parseDateString(tempDeliveryInfo.requestedDate)
                      : null
                  }
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  popperPlacement="bottom-end"
                  popperContainer={renderDatePickerPopper} // Use portal for the popper
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                    },
                  }}
                />{" "}
              </div>

              {/* Signature Service */}
              <div className=" signatureService delivery-info flex items-center">
                <ToastContainer />
                <input
                  type="checkbox"
                  id="signatureService"
                  style={{
                    marginLeft: "15px",
                    width: "20px",
                    height: "20px",
                  }}
                  checked={tempDeliveryInfo.signatureService}
                  onChange={handleSignatureServiceChange}
                  className="text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded mr-1"
                />
                <label
                  htmlFor="signatureService"
                  className="text-sm font-medium text-gray-700 mr-2"
                  style={{
                    color: "var(--Primary-txt-Black, #0B0B0B)",
                    fontFamily: "Lato",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Add a signature Service
                </label>
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    fill: "rgba(4, 173, 209, 0.80)",
                    marginLeft: "1px",
                    position: "relative",
                  }}
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 6 0ZM6.6 9H5.4V5.4H6.6V9ZM6.6 4.2H5.4V3H6.6V4.2Z"
                      fill="#04ADD1"
                      fillOpacity="0.8"
                    />
                  </svg>
                  {/* Tooltip for signature service */}
                  {showTooltip && (
                    <div className="tooltip-content">
                      A value-added service that requires the recipient or a
                      responsible party to sign for the release of a package.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="delivery-button-group">
            {/* Cancelation button */}
            <SecondaryButton
              width="160px"
              height="40px"
              fontSize="16px"
              onClick={handleCancelButton}
            >
              Cancel
            </SecondaryButton>
            {/* Save & Continue button */}
            <PrimaryButton
              width="160px"
              height="40px"
              fontSize="16px"
              onClick={handleConfirmDeliveryOptions}
            >
              Save & Continue
            </PrimaryButton>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default DeliverySection;
